// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const TableNo34 = () => {
//   // Data for the table and chart
//   const ethnicityShareData = [
//     { ethnicityLabel: 'Hispanic or Latino', share2018: 18.96, share2022: 20.00, share2023: 20.52 },
//     { ethnicityLabel: 'Not Hispanic or Latino', share2018: 81.04, share2022: 80.00, share2023: 79.48 },
//   ];

//   // Chart data
//   const chartData = {
//     labels: ethnicityShareData.map(item => item.ethnicityLabel),
//     datasets: [
//       {
//         label: 'Share % (2018)',
//         data: ethnicityShareData.map(item => item.share2018),
//         backgroundColor: 'rgba(75, 192, 192, 0.6)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Share % (2022)',
//         data: ethnicityShareData.map(item => item.share2022),
//         backgroundColor: 'rgba(54, 162, 235, 0.6)',
//         borderColor: 'rgba(54, 162, 235, 1)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Share % (2023)',
//         data: ethnicityShareData.map(item => item.share2023),
//         backgroundColor: 'rgba(153, 102, 255, 0.6)',
//         borderColor: 'rgba(153, 102, 255, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Chart options
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       y: {
//         beginAtZero: true,
//         ticks: {
//           callback: (value) => `${value}%`, // Display percentage on Y-axis
//         },
//       },
//     },
//     plugins: {
//       tooltip: {
//         callbacks: {
//           label: (context) => `${context.dataset.label}: ${context.raw}%`, // Show percentage in tooltip
//         },
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h2>Ethnicity Share by Year (2018, 2022, 2023)</h2>

//       {/* Responsive Table */}
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Ethnicity Label</th>
//             <th>Share % (2018)</th>
//             <th>Share % (2022)</th>
//             <th>Share % (2023)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {ethnicityShareData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.ethnicityLabel}</td>
//               <td>{item.share2018}%</td>
//               <td>{item.share2022}%</td>
//               <td>{item.share2023}%</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Bar Chart */}
//       <div
//           style={{
//             overflowX: "auto", // Enables horizontal scroll
//             display: "flex",
//             justifyContent: "center",
//             width: "100%", // Takes full width
//           }}
//         >
//           <div
//             style={{
//               minWidth: "600px", // Minimum width to enable scroll
//               width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
//               height: "400px",
//             }}
//           >
//         <Bar data={chartData} options={options} />
//       </div>
//     </div>
//     </div>
//   );
// };

// export default TableNo34;





















// // import React from 'react';
// // import 'bootstrap/dist/css/bootstrap.min.css';
// // import { Table } from 'react-bootstrap';
// // import { Bar } from 'react-chartjs-2';
// // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// // import html2pdf from 'html2pdf.js';

// // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // const TableNo34 = () => {
// //   // Data for the table and chart
// //   const ethnicityShareData = [
// //     { ethnicityLabel: 'Hispanic or Latino', share2018: 18.96, share2022: 20.00, share2023: 20.52 },
// //     { ethnicityLabel: 'Not Hispanic or Latino', share2018: 81.04, share2022: 80.00, share2023: 79.48 },
// //   ];

// //   // Chart data
// //   const chartData = {
// //     labels: ethnicityShareData.map(item => item.ethnicityLabel),
// //     datasets: [
// //       {
// //         label: 'Share % (2018)',
// //         data: ethnicityShareData.map(item => item.share2018),
// //         backgroundColor: 'rgba(75, 192, 192, 0.6)',
// //         borderColor: 'rgba(75, 192, 192, 1)',
// //         borderWidth: 1,
// //       },
// //       {
// //         label: 'Share % (2022)',
// //         data: ethnicityShareData.map(item => item.share2022),
// //         backgroundColor: 'rgba(54, 162, 235, 0.6)',
// //         borderColor: 'rgba(54, 162, 235, 1)',
// //         borderWidth: 1,
// //       },
// //       {
// //         label: 'Share % (2023)',
// //         data: ethnicityShareData.map(item => item.share2023),
// //         backgroundColor: 'rgba(153, 102, 255, 0.6)',
// //         borderColor: 'rgba(153, 102, 255, 1)',
// //         borderWidth: 1,
// //       },
// //     ],
// //   };

// //   // Chart options
// //   const options = {
// //     responsive: true,
// //     maintainAspectRatio: false,
// //     scales: {
// //       y: {
// //         beginAtZero: true,
// //         ticks: {
// //           callback: (value) => `${value}%`, // Display percentage on Y-axis
// //         },
// //       },
// //     },
// //     plugins: {
// //       tooltip: {
// //         callbacks: {
// //           label: (context) => `${context.dataset.label}: ${context.raw}%`, // Show percentage in tooltip
// //         },
// //       },
// //     },
// //   };

// //   // Function to generate PDF
// //   const generatePDF = () => {
// //     const element = document.getElementById('pdfContent');
// //     const options = {
// //       filename: 'ethnicity_share_report.pdf',
// //       image: { type: 'jpeg', quality: 0.98 },
// //       html2canvas: { scale: 2 },
// //       jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
// //     };
// //     html2pdf().from(element).set(options).save();
// //   };

// //   return (
// //     <div className="container" id="pdfContent">
// //       <h2>Ethnicity Share by Year (2018, 2022, 2023)</h2>

// //       {/* Button to trigger PDF generation */}
// //       <button className="btn btn-primary mb-3" onClick={generatePDF}>
// //         Download PDF
// //       </button>

// //       {/* Responsive Table */}
// //       <Table striped bordered hover responsive>
// //         <thead>
// //           <tr>
// //             <th>Ethnicity Label</th>
// //             <th>Share % (2018)</th>
// //             <th>Share % (2022)</th>
// //             <th>Share % (2023)</th>
// //           </tr>
// //         </thead>
// //         <tbody>
// //           {ethnicityShareData.map((item, index) => (
// //             <tr key={index}>
// //               <td>{item.ethnicityLabel}</td>
// //               <td>{item.share2018}%</td>
// //               <td>{item.share2022}%</td>
// //               <td>{item.share2023}%</td>
// //             </tr>
// //           ))}
// //         </tbody>
// //       </Table>

// //       {/* Bar Chart */}
// //       <div
// //         style={{
// //           overflowX: "auto", // Enables horizontal scroll
// //           display: "flex",
// //           justifyContent: "center",
// //           width: "100%", // Takes full width
// //         }}
// //       >
// //         <div
// //           style={{
// //             minWidth: "600px", // Minimum width to enable scroll
// //             width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
// //             height: "400px",
// //           }}
// //         >
// //           <Bar data={chartData} options={options} />
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default TableNo34;














import React, { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";

const TableNo34 = () => {
  const pdfRef = useRef(); // Ref for capturing PDF content

  // Data for the table and chart
  const ethnicityShareData = [
    { ethnicityLabel: 'Hispanic or Latino', share2018: 18.96, share2022: 20.00, share2023: 20.52 },
    { ethnicityLabel: 'Not Hispanic or Latino', share2018: 81.04, share2022: 80.00, share2023: 79.48 },
  ];

  // Chart data
  const chartData = {
    labels: ethnicityShareData.map(item => item.ethnicityLabel),
    datasets: [
      {
        label: 'Share % (2018)',
        data: ethnicityShareData.map(item => item.share2018),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Share % (2022)',
        data: ethnicityShareData.map(item => item.share2022),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Share % (2023)',
        data: ethnicityShareData.map(item => item.share2023),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${value}%`, // Display percentage on Y-axis
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}%`, // Show percentage in tooltip
        },
      },
    },
  };

  // PDF Generation function
  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px"; // Temporarily increase width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header and footer
      // Header
      pdf.addImage(utahData, 'PNG', 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text('UTahData.org', 95, 22);
      pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 200, 35); // Line after header

      // Add main content
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260); // Line before footer
      const footerStartY = 265;
      pdf.addImage(utahData, 'PNG', 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text('Built by', 55, 275);
      pdf.addImage(bodex, 'PNG', 68, 269, 44, 10);
      pdf.text('using', 112, 275);
      pdf.addImage(kpi, 'PNG', 125, 265, 20, 20);
      pdf.text('20241121.v1.1', 148, 275);
      pdf.line(10, 35, 200, 35);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

      pdf.save("ethnicity-share-by-year.pdf");
      input.style.width = originalWidth; // Reset width
    });
  };

  return (
    <div className="container">
      <h2>Ethnicity Share by Year (2018, 2022, 2023)</h2>

      {/* PDF Content */}
      <div ref={pdfRef}>
        {/* Responsive Table */}
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Ethnicity Label</th>
              <th>Share % (2018)</th>
              <th>Share % (2022)</th>
              <th>Share % (2023)</th>
            </tr>
          </thead>
          <tbody>
            {ethnicityShareData.map((item, index) => (
              <tr key={index}>
                <td>{item.ethnicityLabel}</td>
                <td>{item.share2018}%</td>
                <td>{item.share2022}%</td>
                <td>{item.share2023}%</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Bar Chart */}
        <div
          style={{
            overflowX: "auto", // Enables horizontal scroll
            display: "flex",
            justifyContent: "center",
            width: "100%", // Takes full width
          }}
        >
          <div
            style={{
              minWidth: "600px", // Minimum width to enable scroll
              width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
              height: "400px",
            }}
          >
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
        </button>
      </div>
    </div>
  );
};

export default TableNo34;
