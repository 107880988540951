import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const betsy = "/img/BETSY.png";

export default function ModalFunction(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="flex justify-center">
        <Modal.Title id="contained-modal-title-vcenter" className='flex justify-center items-center text-center' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
         {/* <img src={betsy} alt='Betsy AI chat bot' className='w-[5vw] h-auto flex justify-center items-center text-center'></img> */}
         <div className="flex justify-center w-full">
        <img
          src={betsy}
          alt="Betsy AI chatbot"
          className="sm:w-[12vw] md:w-[4vw] w-[14vw] h-auto mx-auto"
        />
      </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <h4>Betsy: We are still working on it, Hang tight!!</h4>
        {/* <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p> */}
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
