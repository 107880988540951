





// import React, { useEffect, useRef } from 'react';
// import { Chart } from 'chart.js';

// const ShareChart3 = () => {
//     const canvasRef = useRef(null);

//     useEffect(() => {
//         const ctx = canvasRef.current.getContext('2d');
//         const educationProfileMenChart = new Chart(ctx, {
//             type: 'doughnut',
//             data: {
//                 labels: ['High School', 'Associate Degree', "Bachelor's Degree", 'Graduate Degree'],
//                 datasets: [{
//                     label: 'Education Profile of Men',
//                     data: [50, 20, 20, 10],
//                     backgroundColor: ['#36a2eb', '#ff6384', '#4bc0c0', '#ffcd56'],
//                     hoverOffset: 4
//                 }]
//             },
//             options: {
//                 responsive: true,
//                 maintainAspectRatio: false,  // Allows chart to scale based on container size
//                 plugins: {
//                     legend: {
//                         position: 'top',
//                     },
//                     title: {
//                         display: true,
//                         text: 'Education Profile of Men in Manufacturing Employment'
//                     }
//                 }
//             }
//         });

//         return () => {
//             // Clean up chart instance
//             educationProfileMenChart.destroy();
//         };
//     }, []);

//     return (
//         <div
//         style={{
//           overflowX: "auto", // Enables horizontal scroll
//           display: "flex",
//           justifyContent: "center",
//           width: "100%", // Takes full width
//         }}
//       >
//         <div
//           style={{
//             minWidth: "600px", // Minimum width to enable scroll
//             width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
//             height: "400px",
//           }}
//         >  {/* Outer container for centering */}
//              <div
//           style={{
//             overflowX: "auto", // Enables horizontal scroll
//             display: "flex",
//             justifyContent: "center",
//             width: "100%", // Takes full width
//           }}
//         >
//           <div
//             style={{
//               minWidth: "400px", // Minimum width to enable scroll
//               width: "400px", // Chart width, ensuring horizontal scroll on smaller screens
//               height: "400px",
//             }}
//           >
//                 <canvas ref={canvasRef} id="educationProfileMenChart" className="w-full h-full" />
//             </div>
//         </div>
//         </div>
//         </div>
//     );
// };

// export default ShareChart3;














import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ShareChart3 = () => {
    const canvasRef = useRef(null);
    const pdfRef = useRef(); // Ref for capturing PDF content

    useEffect(() => {
        const ctx = canvasRef.current.getContext("2d");
        const educationProfileMenChart = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: [
                    "High School",
                    "Associate Degree",
                    "Bachelor's Degree",
                    "Graduate Degree",
                ],
                datasets: [
                    {
                        label: "Education Profile of Men",
                        data: [50, 20, 20, 10],
                        backgroundColor: [
                            "#36a2eb",
                            "#ff6384",
                            "#4bc0c0",
                            "#ffcd56",
                        ],
                        hoverOffset: 4,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false, // Allows chart to scale based on container size
                plugins: {
                    legend: {
                        position: "top",
                    },
                    title: {
                        display: true,
                        text: "Education Profile of Men in Manufacturing Employment",
                    },
                },
            },
        });

        return () => {
            // Cleanup chart instance
            educationProfileMenChart.destroy();
        };
    }, []);

    // PDF Generation function
    const downloadPDF = () => {
        const input = pdfRef.current;
        const originalWidth = input.style.width;
        input.style.width = "1100px"; // Temporarily increase width for better PDF capture

        html2canvas(input, { scale: 3 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");

            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Add header and footer
            pdf.addImage("/img/1.png", "PNG", 60, 4, 30, 30);
            pdf.setFontSize(26);
            pdf.text("UTahData.org", 95, 22);
            pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

            pdf.setFontSize(12);
            pdf.setLineWidth(0.5);
            pdf.line(10, 35, 200, 35); // Line after header

            // Add main content
            const headerHeight = 38;
            pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

            // Footer
            pdf.line(10, 260, 200, 260); // Line before footer
            const footerStartY = 265;
            pdf.addImage("/img/1.png", "PNG", 33, 265, 20, 20);
            pdf.setFontSize(10);
            pdf.setTextColor(0, 122, 255);
            pdf.text("Built by", 55, 275);
            pdf.addImage("/img/bodexLogo.png", "PNG", 68, 269, 44, 10);
            pdf.text("using", 112, 275);
            pdf.addImage("/img/KPI-DASH 2.png", "PNG", 125, 265, 20, 20);
            pdf.text("20241121.v1.1", 148, 275);

            const currentURL = window.location.href;
            const currentDate = new Date().toLocaleDateString();
            pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
            pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

            pdf.save("education-profile-men.pdf");
            input.style.width = originalWidth; // Reset width
        });
    };

    return (
        <div className="container">
            <h2>Education Profile of Men</h2>

            {/* PDF Content */}
            <div ref={pdfRef}>
                <div
                    style={{
                        overflowX: "auto", // Enables horizontal scroll
                        display: "flex",
                        justifyContent: "center",
                        width: "100%", // Takes full width
                    }}
                >
                    <div
                        style={{
                            minWidth: "400px", // Minimum width to enable scroll
                            width: "400px", // Chart width, ensuring horizontal scroll on smaller screens
                            height: "400px",
                        }}
                    >
                        <canvas ref={canvasRef} id="educationProfileMenChart" className="w-full h-full"></canvas>
                    </div>
                </div>
            </div>

            {/* Download PDF Button */}
            <div className="flex justify-center">
                <button
                    onClick={downloadPDF}
                    className="download-btn bg-red-800 hover:bg-red-950  p-2 text-white rounded-lg flex items-center justify-between"
                >
                    <span>Download as PDF</span>
                </button>
            </div>
        </div>
    );
};

export default ShareChart3;
