import React, { useRef } from "react";
import Table from "react-bootstrap/Table";
import { Bar } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

const AeroTable7 = ({ data }) => {
  const pdfRef = useRef();

  if (!data || data.length === 0) {
    return <p>No data available for Employment Trends.</p>;
  }

  // Extract unique years dynamically from the data
  const years = ["2018", "2019", "2020", "2021", "2022", "2023"];

  // Prepare chart data for all age groups
  const chartData = {
    labels: data.map((item) => item["Age Group Label"]),
    datasets: years.flatMap((year) => [
      {
        label: `Female-${year}`,
        data: data.map((item) => parseFloat(item[`Female-${year}`])),
        backgroundColor: `rgba(255, 99, 132, 0.6)`,
        borderColor: `rgba(255, 99, 132, 1)`,
        borderWidth: 1,
        stack: year, // Group stacks by year
      },
      {
        label: `Male-${year}`,
        data: data.map((item) => parseFloat(item[`Male-${year}`])),
        backgroundColor: `rgba(54, 162, 235, 0.6)`,
        borderColor: `rgba(54, 162, 235, 1)`,
        borderWidth: 1,
        stack: year,
      },
    ]),
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: { stacked: true },
      y: {
        stacked: true,
        beginAtZero: true,
        title: { display: true, text: "Employment Percentage (%)" },
      },
    },
  };

  // PDF Generation function
  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px"; // Temporarily increase width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });
      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 200, 35);

      // Add main content
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260);
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);
      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
      pdf.text("20241121.v1.1", 148, footerStartY + 10);
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("employment-age-group.pdf");
      input.style.width = originalWidth; // Reset width
    });
  };

  return (
    <div className="w-full p-4">
      <div ref={pdfRef} className="content-to-export">
        {/* Table Section */}
        <div className="overflow-x-auto">
          <Table striped bordered hover responsive className="mb-4">
            <thead>
              <tr>
                <th>Age Group</th>
                {years.flatMap((year) => [
                  <th key={`female-${year}`}>Female-{year}</th>,
                  <th key={`male-${year}`}>Male-{year}</th>,
                ])}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>{row["Age Group Label"]}</td>
                  {years.flatMap((year) => [
                    <td key={`female-${year}-${index}`}>
                      {row[`Female-${year}`]}%
                    </td>,
                    <td key={`male-${year}-${index}`}>
                      {row[`Male-${year}`]}%
                    </td>,
                  ])}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* Chart Section */}
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ minWidth: "800px", width: "100%", height: "500px" }}>
            <Bar data={chartData} options={chartOptions} />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
          <span>
            <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AeroTable7;
