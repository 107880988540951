import React, { useRef, useState, useEffect } from "react";

import Form from "./Form";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import "../styles/Aerospace.css";
import ModalFunction from "./Modal";


const kpi = "/img/KPI-DASH 2.png";
const logo = "/img/1.png";

export default function ContactUs() {
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);
  const [modalShow, setModalShow] = React.useState(false);

  // Close dropdown when clicking outside the navbar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar
        ref={navbarRef} // Add ref here
        collapseOnSelect
        expand="lg"
        className="fixed top-0 left-0 w-full z-10 flex justify-center items-center"
        style={{
          height: "13vh",
          backgroundColor: "#1e59be",
          zIndex: 5,
          transition: "background-color 0.3s ease",
          position: "fixed",
        }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <div className="flex items-center">
              <img
                className="h-[8vh] md:h-[10vh]"
                src={logo}
                alt="UTahData Logo"
              />
              <h1 className="text-white ml-2 text-lg md:text-4xl">
                UTahData.org
                <span className="beta-label text-xs bg-yellow-500 text-white px-1 py-0.5 rounded-lg">
                  Beta
                </span>
              </h1>
            </div>
          </Navbar.Brand>

          {/* Toggle icon for responsive menu */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            {/* Boxed styling for mobile */}
            <Nav className="d-lg-none bg-white border rounded-lg p-4 shadow-lg flex flex-col items-center">
              <Nav.Link
                as={Link}
                to="/"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/betsy"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Ask Betsy AI
              </Nav.Link>

              {/* <Nav.Link
                as={Link}
                to="/faq"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/about"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
            </Nav>

            {/* Inline styling for larger screens */}
            <Nav className="d-none d-lg-flex ml-auto">
              <Nav.Link
                as={Link}
                to="/"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/betsy"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Ask Betsy AI
              </Nav.Link>
              <ModalFunction
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
              <ModalFunction
                show={modalShow}
                onHide={() => setModalShow(false)}
              />

              {/* <Nav.Link
                as={Link}
                to="/faq"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Form Section */}
      <div className="flex-grow mt-16 bg-[#d2eaff]">
        {" "}
        {/* Adds spacing to avoid content going under fixed navbar */}
        <Form />
      </div>

      {/* Footer */}
     
    </div>
  );
}
