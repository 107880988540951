// // // // // // // // // import React from 'react';
// // // // // // // // // import 'bootstrap/dist/css/bootstrap.min.css';
// // // // // // // // // import { Table } from 'react-bootstrap';
// // // // // // // // // import { Bar } from 'react-chartjs-2';
// // // // // // // // // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// // // // // // // // // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // // // // // // // // const TableNo37 = () => {
// // // // // // // // //   // Data for the table and chart
// // // // // // // // //   const industryData = [
// // // // // // // // //     { sector: 'Aerospace Product and Parts Manufacturing', employment: 10564 },
// // // // // // // // //     { sector: 'Agriculture, Construction, and Mining Machinery Manufacturing', employment: 859 },
// // // // // // // // //     { sector: 'Alumina and Aluminum Production and Processing', employment: 584 },
// // // // // // // // //     { sector: 'Animal Food Manufacturing', employment: 1279 },
// // // // // // // // //     { sector: 'Animal Slaughtering and Processing', employment: 4116 },
// // // // // // // // //     { sector: 'Apparel Accessories and Other Apparel Manufacturing', employment: 157 },
// // // // // // // // //     { sector: 'Apparel Knitting Mills', employment: 41 },
// // // // // // // // //     { sector: 'Architectural and Structural Metals Manufacturing', employment: 7098 },
// // // // // // // // //     { sector: 'Audio and Video Equipment Manufacturing', employment: 276 },
// // // // // // // // //     { sector: 'Bakeries and Tortilla Manufacturing', employment: 4002 },
// // // // // // // // //     { sector: 'Basic Chemical Manufacturing', employment: 1249 },
// // // // // // // // //     { sector: 'Beverage Manufacturing', employment: 1359 },
// // // // // // // // //     { sector: 'Boiler, Tank, and Shipping Container Manufacturing', employment: 662 },
// // // // // // // // //     { sector: 'Cement and Concrete Product Manufacturing', employment: 3012 },
// // // // // // // // //     { sector: 'Clay Product and Refractory Manufacturing', employment: 196 },
// // // // // // // // //     { sector: 'Coating, Engraving, Heat Treating, and Allied Activities', employment: 882 },
// // // // // // // // //     { sector: 'Commercial and Service Industry Machinery Manufacturing', employment: 967 },
// // // // // // // // //     { sector: 'Communications Equipment Manufacturing', employment: 486 },
// // // // // // // // //     { sector: 'Computer and Peripheral Equipment Manufacturing', employment: 434 },
// // // // // // // // //     { sector: 'Converted Paper Product Manufacturing', employment: 2464 },
// // // // // // // // //     { sector: 'Cut and Sew Apparel Manufacturing', employment: 306 },
// // // // // // // // //     { sector: 'Cutlery and Handtool Manufacturing', employment: 157 },
// // // // // // // // //     { sector: 'Dairy Product Manufacturing', employment: 4220 },
// // // // // // // // //     { sector: 'Electric Lighting Equipment Manufacturing', employment: 87 },
// // // // // // // // //     { sector: 'Electrical Equipment Manufacturing', employment: 282 },
// // // // // // // // //     { sector: 'Engine, Turbine, and Power Transmission Equipment Manufacturing', employment: 382 },
// // // // // // // // //     { sector: 'Fabric Mills', employment: 57 },
// // // // // // // // //     { sector: 'Footwear Manufacturing', employment: 13 },
// // // // // // // // //     { sector: 'Forging and Stamping', employment: 147 },
// // // // // // // // //     { sector: 'Foundries', employment: 887 },
// // // // // // // // //     { sector: 'Fruit and Vegetable Preserving and Specialty Food Manufacturing', employment: 1878 },
// // // // // // // // //     { sector: 'Glass and Glass Product Manufacturing', employment: 286 },
// // // // // // // // //     { sector: 'Grain and Oilseed Milling', employment: 572 },
// // // // // // // // //     { sector: 'Hardware Manufacturing', employment: 65 },
// // // // // // // // //     { sector: 'Household and Institutional Furniture and Kitchen Cabinet Manufacturing', employment: 4298 },
// // // // // // // // //     { sector: 'Household Appliance Manufacturing', employment: 257 },
// // // // // // // // //     { sector: 'Industrial Machinery Manufacturing', employment: 549 },
// // // // // // // // //     { sector: 'Iron and Steel Mills and Ferroalloy Manufacturing', employment: 647 },
// // // // // // // // //     { sector: 'Lime and Gypsum Product Manufacturing', employment: 235 },
// // // // // // // // //     { sector: 'Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing', employment: 2959 },
// // // // // // // // //     { sector: 'Manufacturing and Reproducing Magnetic and Optical Media', employment: 189 },
// // // // // // // // //     { sector: 'Medical Equipment and Supplies Manufacturing', employment: 12902 },
// // // // // // // // //     { sector: 'Metalworking Machinery Manufacturing', employment: 710 },
// // // // // // // // //     { sector: 'Motor Vehicle Body and Trailer Manufacturing', employment: 1738 },
// // // // // // // // //     { sector: 'Motor Vehicle Parts Manufacturing', employment: 4380 },
// // // // // // // // //     { sector: 'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing', employment: 9084 },
// // // // // // // // //     { sector: 'Nonferrous Metal (except Aluminum) Production and Processing', employment: 1587 },
// // // // // // // // //     { sector: 'Office Furniture (including Fixtures) Manufacturing', employment: 840 },
// // // // // // // // //     { sector: 'Other Chemical Product and Preparation Manufacturing', employment: 168 },
// // // // // // // // //     { sector: 'Other Electrical Equipment and Component Manufacturing', employment: 1204 },
// // // // // // // // //     { sector: 'Other Fabricated Metal Product Manufacturing', employment: 3160 },
// // // // // // // // //     { sector: 'Other Food Manufacturing', employment: 2685 },
// // // // // // // // //     { sector: 'Other Furniture Related Product Manufacturing', employment: 2444 },
// // // // // // // // //     { sector: 'Other General Purpose Machinery Manufacturing', employment: 2529 },
// // // // // // // // //     { sector: 'Other Leather and Allied Product Manufacturing', employment: 197 },
// // // // // // // // //     { sector: 'Other Miscellaneous Manufacturing', employment: 9181 },
// // // // // // // // //     { sector: 'Other Nonmetallic Mineral Product Manufacturing', employment: 1810 },
// // // // // // // // //     { sector: 'Other Textile Product Mills', employment: 628 },
// // // // // // // // //     { sector: 'Other Transportation Equipment Manufacturing', employment: 410 },
// // // // // // // // //     { sector: 'Other Wood Product Manufacturing', employment: 1959 },
// // // // // // // // //     { sector: 'Paint, Coating, and Adhesive Manufacturing', employment: 198 },
// // // // // // // // //     { sector: 'Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing', employment: 67 },
// // // // // // // // //     { sector: 'Petroleum and Coal Products Manufacturing', employment: 1806 },
// // // // // // // // //     { sector: 'Pharmaceutical and Medicine Manufacturing', employment: 8005 },
// // // // // // // // //     { sector: 'Plastics Product Manufacturing', employment: 7519 },
// // // // // // // // //     { sector: 'Printing and Related Support Activities', employment: 5120 },
// // // // // // // // //     { sector: 'Railroad Rolling Stock Manufacturing', employment: 378 },
// // // // // // // // //     { sector: 'Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing', employment: 235 },
// // // // // // // // //     { sector: 'Rubber Product Manufacturing', employment: 625 },
// // // // // // // // //     { sector: 'Sawmills and Wood Preservation', employment: 153 },
// // // // // // // // //     { sector: 'Seafood Product Preparation and Packaging', employment: 8 },
// // // // // // // // //     { sector: 'Semiconductor and Other Electronic Component Manufacturing', employment: 3173 },
// // // // // // // // //     { sector: 'Ship and Boat Building', employment: 122 },
// // // // // // // // //     { sector: 'Soap, Cleaning Compound, and Toilet Preparation Manufacturing', employment: 1032 },
// // // // // // // // //     { sector: 'Spring and Wire Product Manufacturing', employment: 115 },
// // // // // // // // //     { sector: 'Steel Product Manufacturing from Purchased Steel', employment: 184 },
// // // // // // // // //     { sector: 'Sugar and Confectionery Product Manufacturing', employment: 1006 },
// // // // // // // // //     { sector: 'Textile and Fabric Finishing and Fabric Coating Mills', employment: 349 },
// // // // // // // // //     { sector: 'Textile Furnishings Mills', employment: 228 },
// // // // // // // // //     { sector: 'Veneer, Plywood, and Engineered Wood Product Manufacturing', employment: 925 },
// // // // // // // // //     { sector: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing', employment: 573 }
// // // // // // // // //   ];

// // // // // // // // //   // Chart data
// // // // // // // // //   const chartData = {
// // // // // // // // //     labels: industryData.map(item => item.sector),
// // // // // // // // //     datasets: [
// // // // // // // // //       {
// // // // // // // // //         label: 'Employment Count',
// // // // // // // // //         data: industryData.map(item => item.employment),
// // // // // // // // //         backgroundColor: 'rgba(75, 192, 192, 0.6)',
// // // // // // // // //         borderColor: 'rgba(75, 192, 192, 1)',
// // // // // // // // //         borderWidth: 1,
// // // // // // // // //       },
// // // // // // // // //     ],
// // // // // // // // //   };

// // // // // // // // //   // Chart options
// // // // // // // // //   const options = {
// // // // // // // // //     responsive: true,
// // // // // // // // //     maintainAspectRatio: false,
// // // // // // // // //     scales: {
// // // // // // // // //       y: {
// // // // // // // // //         beginAtZero: true,
// // // // // // // // //       },
// // // // // // // // //     },
// // // // // // // // //   };

// // // // // // // // //   return (
// // // // // // // // //     <div className="container">
// // // // // // // // //       <h2>Employment by Industry Sector in 2021</h2>

// // // // // // // // //       {/* Responsive Table */}
// // // // // // // // //       <Table striped bordered hover responsive>
// // // // // // // // //         <thead>
// // // // // // // // //           <tr>
// // // // // // // // //             <th>Industry Sector</th>
// // // // // // // // //             <th>Employment Count</th>
// // // // // // // // //           </tr>
// // // // // // // // //         </thead>
// // // // // // // // //         <tbody>
// // // // // // // // //           {industryData.map((item, index) => (
// // // // // // // // //             <tr key={index}>
// // // // // // // // //               <td>{item.sector}</td>
// // // // // // // // //               <td>{item.employment.toLocaleString()}</td>
// // // // // // // // //             </tr>
// // // // // // // // //           ))}
// // // // // // // // //         </tbody>
// // // // // // // // //       </Table>

// // // // // // // // //       {/* Bar Chart */}
// // // // // // // // //       <div style={{ height: '500px', width: '100%' }}>
// // // // // // // // //         <Bar data={chartData} options={options} />
// // // // // // // // //       </div>
// // // // // // // // //     </div>
// // // // // // // // //   );
// // // // // // // // // };

// // // // // // // // // export default TableNo37;








// // // // // // // import React from 'react';
// // // // // // // import 'bootstrap/dist/css/bootstrap.min.css';
// // // // // // // import { Table } from 'react-bootstrap';
// // // // // // // import { Bar } from 'react-chartjs-2';
// // // // // // // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// // // // // // // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // // // // // // const TableNo37 = () => {
// // // // // // //   // Data for the table and chart
// // // // // // //   const industryData = [
// // // // // // //     { sector: 'Aerospace Product and Parts Manufacturing', employment: 10564 },
// // // // // // //     { sector: 'Agriculture, Construction, and Mining Machinery Manufacturing', employment: 859 },
// // // // // // //     { sector: 'Alumina and Aluminum Production and Processing', employment: 584 },
// // // // // // //     { sector: 'Animal Food Manufacturing', employment: 1279 },
// // // // // // //     { sector: 'Animal Slaughtering and Processing', employment: 4116 },
// // // // // // //     { sector: 'Apparel Accessories and Other Apparel Manufacturing', employment: 157 },
// // // // // // //     { sector: 'Apparel Knitting Mills', employment: 41 },
// // // // // // //     { sector: 'Architectural and Structural Metals Manufacturing', employment: 7098 },
// // // // // // //     { sector: 'Audio and Video Equipment Manufacturing', employment: 276 },
// // // // // // //     { sector: 'Bakeries and Tortilla Manufacturing', employment: 4002 },
// // // // // // //     { sector: 'Basic Chemical Manufacturing', employment: 1249 },
// // // // // // //     { sector: 'Beverage Manufacturing', employment: 1359 },
// // // // // // //     { sector: 'Medical Equipment and Supplies Manufacturing', employment: 12902 },
// // // // // // //     { sector: 'Motor Vehicle Parts Manufacturing', employment: 4380 },
// // // // // // //     { sector: 'Pharmaceutical and Medicine Manufacturing', employment: 8005 },
// // // // // // //     { sector: 'Plastics Product Manufacturing', employment: 7519 },
// // // // // // //     { sector: 'Printing and Related Support Activities', employment: 5120 },
// // // // // // //     { sector: 'Semiconductor and Other Electronic Component Manufacturing', employment: 3173 },
// // // // // // //     { sector: 'Textile and Fabric Finishing and Fabric Coating Mills', employment: 349 },
// // // // // // //     { sector: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing', employment: 573 },
// // // // // // //   ];

// // // // // // //   // Chart data
// // // // // // //   const chartData = {
// // // // // // //     labels: industryData.map(item => item.sector),
// // // // // // //     datasets: [
// // // // // // //       {
// // // // // // //         label: 'Employment Count',
// // // // // // //         data: industryData.map(item => item.employment),
// // // // // // //         backgroundColor: 'rgba(75, 192, 192, 0.6)',
// // // // // // //         borderColor: 'rgba(75, 192, 192, 1)',
// // // // // // //         borderWidth: 1,
// // // // // // //       },
// // // // // // //     ],
// // // // // // //   };

// // // // // // //   // Chart options
// // // // // // //   const options = {
// // // // // // //     responsive: true,
// // // // // // //     maintainAspectRatio: false,
// // // // // // //     scales: {
// // // // // // //       y: {
// // // // // // //         beginAtZero: true,
// // // // // // //         ticks: {
// // // // // // //           color: '#4B5563',
// // // // // // //           font: {
// // // // // // //             size: 12,
// // // // // // //           },
// // // // // // //         },
// // // // // // //         title: {
// // // // // // //           display: true,
// // // // // // //           text: 'Employment Count',
// // // // // // //           color: '#4B5563',
// // // // // // //           font: {
// // // // // // //             size: 14,
// // // // // // //           },
// // // // // // //         },
// // // // // // //       },
// // // // // // //       x: {
// // // // // // //         ticks: {
// // // // // // //           color: '#4B5563',
// // // // // // //           font: {
// // // // // // //             size: 12,
// // // // // // //           },
// // // // // // //           autoSkip: false, // Ensure all labels are shown
// // // // // // //           maxRotation: 90,
// // // // // // //           minRotation: 45,
// // // // // // //         },
// // // // // // //       },
// // // // // // //     },
// // // // // // //     plugins: {
// // // // // // //       legend: {
// // // // // // //         position: 'top',
// // // // // // //         labels: {
// // // // // // //           font: {
// // // // // // //             size: 12,
// // // // // // //           },
// // // // // // //         },
// // // // // // //       },
// // // // // // //       tooltip: {
// // // // // // //         callbacks: {
// // // // // // //           label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`, // Add commas to large numbers
// // // // // // //         },
// // // // // // //         bodyFont: {
// // // // // // //           size: 12,
// // // // // // //         },
// // // // // // //       },
// // // // // // //     },
// // // // // // //   };

// // // // // // //   return (
// // // // // // //     <div className="container my-5">
// // // // // // //       <h2 className="text-center mb-4">Employment by Industry Sector in 2021</h2>

// // // // // // //       {/* Responsive Table with Horizontal Scroll */}
// // // // // // //       <div className="table-responsive mb-4">
// // // // // // //         <Table striped bordered hover>
// // // // // // //           <thead>
// // // // // // //             <tr>
// // // // // // //               <th>Industry Sector</th>
// // // // // // //               <th>Employment Count</th>
// // // // // // //             </tr>
// // // // // // //           </thead>
// // // // // // //           <tbody>
// // // // // // //             {industryData.map((item, index) => (
// // // // // // //               <tr key={index}>
// // // // // // //                 <td>{item.sector}</td>
// // // // // // //                 <td>{item.employment.toLocaleString()}</td>
// // // // // // //               </tr>
// // // // // // //             ))}
// // // // // // //           </tbody>
// // // // // // //         </Table>
// // // // // // //       </div>

// // // // // // //       {/* Bar Chart with Horizontal Scroll */}
// // // // // // //       <div className="overflow-auto">
// // // // // // //         <div style={{ minWidth: '800px', height: '500px' }}>
// // // // // // //           <Bar data={chartData} options={options} />
// // // // // // //         </div>
// // // // // // //       </div>
// // // // // // //     </div>
// // // // // // //   );
// // // // // // // };

// // // // // // // export default TableNo37;















// // // // // // // // import React, { useRef } from 'react';
// // // // // // // // import 'bootstrap/dist/css/bootstrap.min.css';
// // // // // // // // import { Table } from 'react-bootstrap';
// // // // // // // // import { Bar } from 'react-chartjs-2';
// // // // // // // // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// // // // // // // // import html2canvas from 'html2canvas';
// // // // // // // // import jsPDF from 'jspdf';

// // // // // // // // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // // // // // // // const utahData = "/img/1.png";
// // // // // // // // const bodex = "/img/bodexLogo.png";
// // // // // // // // const kpi = "/img/KPI-DASH 2.png";

// // // // // // // // const TableNo37 = () => {
// // // // // // // //   const pdfRef = useRef(); // Ref for capturing PDF content

// // // // // // // //   const industryData = [
// // // // // // // //     { sector: 'Aerospace Product and Parts Manufacturing', employment: 10564 },
// // // // // // // //     { sector: 'Agriculture, Construction, and Mining Machinery Manufacturing', employment: 859 },
// // // // // // // //     { sector: 'Alumina and Aluminum Production and Processing', employment: 584 },
// // // // // // // //     { sector: 'Animal Food Manufacturing', employment: 1279 },
// // // // // // // //     { sector: 'Animal Slaughtering and Processing', employment: 4116 },
// // // // // // // //     { sector: 'Apparel Accessories and Other Apparel Manufacturing', employment: 157 },
// // // // // // // //     { sector: 'Apparel Knitting Mills', employment: 41 },
// // // // // // // //     { sector: 'Architectural and Structural Metals Manufacturing', employment: 7098 },
// // // // // // // //     { sector: 'Audio and Video Equipment Manufacturing', employment: 276 },
// // // // // // // //     { sector: 'Bakeries and Tortilla Manufacturing', employment: 4002 },
// // // // // // // //     { sector: 'Basic Chemical Manufacturing', employment: 1249 },
// // // // // // // //     { sector: 'Beverage Manufacturing', employment: 1359 },
// // // // // // // //     { sector: 'Medical Equipment and Supplies Manufacturing', employment: 12902 },
// // // // // // // //     { sector: 'Motor Vehicle Parts Manufacturing', employment: 4380 },
// // // // // // // //     { sector: 'Pharmaceutical and Medicine Manufacturing', employment: 8005 },
// // // // // // // //     { sector: 'Plastics Product Manufacturing', employment: 7519 },
// // // // // // // //     { sector: 'Printing and Related Support Activities', employment: 5120 },
// // // // // // // //     { sector: 'Semiconductor and Other Electronic Component Manufacturing', employment: 3173 },
// // // // // // // //     { sector: 'Textile and Fabric Finishing and Fabric Coating Mills', employment: 349 },
// // // // // // // //     { sector: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing', employment: 573 },
// // // // // // // //   ];

// // // // // // // //   const chartData = {
// // // // // // // //     labels: industryData.map(item => item.sector),
// // // // // // // //     datasets: [
// // // // // // // //       {
// // // // // // // //         label: 'Employment Count',
// // // // // // // //         data: industryData.map(item => item.employment),
// // // // // // // //         backgroundColor: 'rgba(75, 192, 192, 0.6)',
// // // // // // // //         borderColor: 'rgba(75, 192, 192, 1)',
// // // // // // // //         borderWidth: 1,
// // // // // // // //       },
// // // // // // // //     ],
// // // // // // // //   };

// // // // // // // //   const options = {
// // // // // // // //     responsive: true,
// // // // // // // //     maintainAspectRatio: false,
// // // // // // // //     scales: {
// // // // // // // //       y: {
// // // // // // // //         beginAtZero: true,
// // // // // // // //       },
// // // // // // // //     },
// // // // // // // //     plugins: {
// // // // // // // //       tooltip: {
// // // // // // // //         callbacks: {
// // // // // // // //           label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`,
// // // // // // // //         },
// // // // // // // //       },
// // // // // // // //     },
// // // // // // // //   };

// // // // // // // //   const downloadPDF = () => {
// // // // // // // //     const input = pdfRef.current;
// // // // // // // //     const originalWidth = input.style.width;
// // // // // // // //     input.style.width = "1100px";

// // // // // // // //     html2canvas(input, { scale: 3 }).then((canvas) => {
// // // // // // // //       const imgData = canvas.toDataURL("image/png");
// // // // // // // //       const pdf = new jsPDF("p", "mm", "a4");

// // // // // // // //       const imgWidth = 210;
// // // // // // // //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

// // // // // // // //       pdf.addImage(utahData, 'PNG', 60, 4, 30, 30);
// // // // // // // //       pdf.setFontSize(26);
// // // // // // // //       pdf.text('UTahData.org', 95, 22);
// // // // // // // //       pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

// // // // // // // //       pdf.setFontSize(12);
// // // // // // // //       pdf.setLineWidth(0.5);
// // // // // // // //       pdf.line(10, 35, 200, 35);

// // // // // // // //       const headerHeight = 38;
// // // // // // // //       pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

// // // // // // // //       pdf.line(10, 260, 200, 260);
// // // // // // // //       const footerStartY = 265;
// // // // // // // //       pdf.addImage(utahData, 'PNG', 33, 265, 20, 20);
// // // // // // // //       pdf.setFontSize(10);
// // // // // // // //       pdf.setTextColor(0, 122, 255);
// // // // // // // //       pdf.text('Built by', 55, 275);
// // // // // // // //       pdf.addImage(bodex, 'PNG', 68, 269, 44, 10);
// // // // // // // //       pdf.text('using', 112, 275);
// // // // // // // //       pdf.addImage(kpi, 'PNG', 125, 265, 20, 20);
// // // // // // // //       pdf.text('20241121.v1.1', 148, 275);

// // // // // // // //       const currentURL = window.location.href;
// // // // // // // //       const currentDate = new Date().toLocaleDateString();
// // // // // // // //       pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
// // // // // // // //       pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

// // // // // // // //       pdf.save("industry-employment-data.pdf");
// // // // // // // //       input.style.width = originalWidth;
// // // // // // // //     });
// // // // // // // //   };

// // // // // // // //   return (
// // // // // // // //     <div className="container">
// // // // // // // //       <h2>Employment by Industry Sector in 2021</h2>
// // // // // // // //       <div ref={pdfRef}>
// // // // // // // //         <Table striped bordered hover responsive>
// // // // // // // //           <thead>
// // // // // // // //             <tr>
// // // // // // // //               <th>Industry Sector</th>
// // // // // // // //               <th>Employment Count</th>
// // // // // // // //             </tr>
// // // // // // // //           </thead>
// // // // // // // //           <tbody>
// // // // // // // //             {industryData.map((item, index) => (
// // // // // // // //               <tr key={index}>
// // // // // // // //                 <td>{item.sector}</td>
// // // // // // // //                 <td>{item.employment.toLocaleString()}</td>
// // // // // // // //               </tr>
// // // // // // // //             ))}
// // // // // // // //           </tbody>
// // // // // // // //         </Table>
// // // // // // // //         <div style={{ overflowX: "auto", display: "flex", justifyContent: "center", width: "100%" }}>
// // // // // // // //           <div style={{ minWidth: "800px", height: "500px" }}>
// // // // // // // //             <Bar data={chartData} options={options} />
// // // // // // // //           </div>
// // // // // // // //         </div>
// // // // // // // //       </div>
// // // // // // // //       <div className="flex justify-center mt-3">
// // // // // // // //         <button
// // // // // // // //           onClick={downloadPDF}
// // // // // // // //           className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
// // // // // // // //         >
// // // // // // // //           <span>Download as PDF</span>
// // // // // // // //         </button>
// // // // // // // //       </div>
// // // // // // // //     </div>
// // // // // // // //   );
// // // // // // // // };

// // // // // // // // export default TableNo37;
















// // // // // // import React, { useRef } from 'react';
// // // // // // import 'bootstrap/dist/css/bootstrap.min.css';
// // // // // // import { Table } from 'react-bootstrap';
// // // // // // import { Bar } from 'react-chartjs-2';
// // // // // // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// // // // // // import html2canvas from 'html2canvas';
// // // // // // import jsPDF from 'jspdf';

// // // // // // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // // // // // const utahData = "/img/1.png";
// // // // // // const bodex = "/img/bodexLogo.png";
// // // // // // const kpi = "/img/KPI-DASH 2.png";

// // // // // // const TableNo37 = () => {
// // // // // //   const pdfRef = useRef();

// // // // // //   const industryData = [
// // // // // //     { sector: 'Aerospace Product and Parts Manufacturing', employment: 10564 },
// // // // // //     { sector: 'Agriculture, Construction, and Mining Machinery Manufacturing', employment: 859 },
// // // // // //     { sector: 'Alumina and Aluminum Production and Processing', employment: 584 },
// // // // // //     { sector: 'Animal Food Manufacturing', employment: 1279 },
// // // // // //     { sector: 'Animal Slaughtering and Processing', employment: 4116 },
// // // // // //     { sector: 'Apparel Accessories and Other Apparel Manufacturing', employment: 157 },
// // // // // //     { sector: 'Apparel Knitting Mills', employment: 41 },
// // // // // //     { sector: 'Architectural and Structural Metals Manufacturing', employment: 7098 },
// // // // // //     { sector: 'Audio and Video Equipment Manufacturing', employment: 276 },
// // // // // //     { sector: 'Bakeries and Tortilla Manufacturing', employment: 4002 },
// // // // // //     { sector: 'Basic Chemical Manufacturing', employment: 1249 },
// // // // // //     { sector: 'Beverage Manufacturing', employment: 1359 },
// // // // // //     { sector: 'Medical Equipment and Supplies Manufacturing', employment: 12902 },
// // // // // //     { sector: 'Motor Vehicle Parts Manufacturing', employment: 4380 },
// // // // // //     { sector: 'Pharmaceutical and Medicine Manufacturing', employment: 8005 },
// // // // // //     { sector: 'Plastics Product Manufacturing', employment: 7519 },
// // // // // //     { sector: 'Printing and Related Support Activities', employment: 5120 },
// // // // // //     { sector: 'Semiconductor and Other Electronic Component Manufacturing', employment: 3173 },
// // // // // //     { sector: 'Textile and Fabric Finishing and Fabric Coating Mills', employment: 349 },
// // // // // //     { sector: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing', employment: 573 },
// // // // // //   ];

// // // // // //   const chartData = {
// // // // // //     labels: industryData.map(item => item.sector),
// // // // // //     datasets: [
// // // // // //       {
// // // // // //         label: 'Employment Count',
// // // // // //         data: industryData.map(item => item.employment),
// // // // // //         backgroundColor: 'rgba(75, 192, 192, 0.6)',
// // // // // //         borderColor: 'rgba(75, 192, 192, 1)',
// // // // // //         borderWidth: 1,
// // // // // //       },
// // // // // //     ],
// // // // // //   };

// // // // // //   const options = {
// // // // // //     responsive: true,
// // // // // //     maintainAspectRatio: false,
// // // // // //     scales: {
// // // // // //       y: {
// // // // // //         beginAtZero: true,
// // // // // //         ticks: {
// // // // // //           color: '#4B5563',
// // // // // //           font: { size: 12 },
// // // // // //         },
// // // // // //         title: {
// // // // // //           display: true,
// // // // // //           text: 'Employment Count',
// // // // // //           color: '#4B5563',
// // // // // //           font: { size: 14 },
// // // // // //         },
// // // // // //       },
// // // // // //       x: {
// // // // // //         ticks: {
// // // // // //           color: '#4B5563',
// // // // // //           font: { size: 12 },
// // // // // //           autoSkip: false, // Ensure all labels are shown
// // // // // //           maxRotation: 90,
// // // // // //           minRotation: 45,
// // // // // //         },
// // // // // //       },
// // // // // //     },
// // // // // //     plugins: {
// // // // // //       legend: {
// // // // // //         position: 'top',
// // // // // //         labels: { font: { size: 12 } },
// // // // // //       },
// // // // // //       tooltip: {
// // // // // //         callbacks: {
// // // // // //           label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`,
// // // // // //         },
// // // // // //         bodyFont: { size: 12 },
// // // // // //       },
// // // // // //     },
// // // // // //   };

// // // // // //   const downloadPDF = () => {
// // // // // //     const input = pdfRef.current;
// // // // // //     const originalWidth = input.style.width;
// // // // // //     input.style.width = "1500px"; // Temporarily widen the container for better rendering
  
// // // // // //     html2canvas(input, { scale: 3 }).then((canvas) => {
// // // // // //         const imgData = canvas.toDataURL("image/png");
// // // // // //         const pdf = new jsPDF("landscape", "mm", "a4");

// // // // // //         const imgWidth = 297; // A4 width in mm
// // // // // //         const imgHeight = (canvas.height * imgWidth) / canvas.width;

// // // // // //         // First page content - Header, Table, Footer
// // // // // //         pdf.addImage(utahData, 'PNG', 90, 4, 30, 30); // Header image
// // // // // //         pdf.setFontSize(26);
// // // // // //         pdf.text('UTahData.org', 135, 22);
// // // // // //         pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

// // // // // //         pdf.setFontSize(12);
// // // // // //         pdf.setLineWidth(0.5);
// // // // // //         pdf.line(10, 35, 287, 35); // Line after the header

// // // // // //         const headerHeight = 38;
// // // // // //         pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight); // Table image

// // // // // //         // Add first page footer
// // // // // //         pdf.line(10, 190, 287, 190);
// // // // // //         pdf.addImage(utahData, 'PNG', 33, 195, 20, 20); // Footer image
// // // // // //         pdf.setFontSize(10);
// // // // // //         pdf.text('Built by', 55, 205);
// // // // // //         pdf.addImage(bodex, 'PNG', 68, 200, 44, 10); // Footer image
// // // // // //         pdf.text('using', 112, 205);
// // // // // //         pdf.addImage(kpi, 'PNG', 125, 195, 20, 20); // Footer image
// // // // // //         pdf.text('20241121.v1.1', 148, 205);
      
// // // // // //         const currentURL = window.location.href;
// // // // // //         const currentDate = new Date().toLocaleDateString();
// // // // // //         pdf.text(`Downloaded from: ${currentURL}`, 10, 210);
// // // // // //         pdf.text(`Downloaded on: ${currentDate}`, 230, 210);

// // // // // //         // Add a new page for the chart
// // // // // //         pdf.addPage();
// // // // // //         pdf.addImage(utahData, 'PNG', 90, 4, 30, 30); // Header image for second page
// // // // // //         pdf.setFontSize(26);
// // // // // //         pdf.text('UTahData.org', 135, 22);
// // // // // //         pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

// // // // // //         pdf.setFontSize(12);
// // // // // //         pdf.setLineWidth(0.5);
// // // // // //         pdf.line(10, 35, 287, 35); // Line after header on second page

// // // // // //         // Add chart on second page
// // // // // //         pdf.addImage(imgData, "PNG", 5, 45, imgWidth, imgHeight); // Chart image

// // // // // //         // Add second page footer
// // // // // //         pdf.line(10, 190, 287, 190);
// // // // // //         pdf.addImage(utahData, 'PNG', 33, 195, 20, 20); // Footer image
// // // // // //         pdf.setFontSize(10);
// // // // // //         pdf.text('Built by', 55, 205);
// // // // // //         pdf.addImage(bodex, 'PNG', 68, 200, 44, 10); // Footer image
// // // // // //         pdf.text('using', 112, 205);
// // // // // //         pdf.addImage(kpi, 'PNG', 125, 195, 20, 20); // Footer image
// // // // // //         pdf.text('20241121.v1.1', 148, 205);

// // // // // //         pdf.text(`Downloaded from: ${currentURL}`, 10, 210);
// // // // // //         pdf.text(`Downloaded on: ${currentDate}`, 230, 210);

// // // // // //         pdf.save("industry-employment-data.pdf");

// // // // // //         input.style.width = originalWidth;
// // // // // //     });
// // // // // // };

  
// // // // // //   return (
// // // // // //     <div className="container my-5">
// // // // // //       <h2 className="text-center mb-4">Employment by Industry Sector in 2021</h2>
// // // // // //       <div ref={pdfRef}>
// // // // // //         {/* Responsive Table */}
// // // // // //         <div className="table-responsive mb-4">
// // // // // //           <Table striped bordered hover>
// // // // // //             <thead>
// // // // // //               <tr>
// // // // // //                 <th>Industry Sector</th>
// // // // // //                 <th>Employment Count</th>
// // // // // //               </tr>
// // // // // //             </thead>
// // // // // //             <tbody>
// // // // // //               {industryData.map((item, index) => (
// // // // // //                 <tr key={index}>
// // // // // //                   <td>{item.sector}</td>
// // // // // //                   <td>{item.employment.toLocaleString()}</td>
// // // // // //                 </tr>
// // // // // //               ))}
// // // // // //             </tbody>
// // // // // //           </Table>
// // // // // //         </div>

// // // // // //         {/* Bar Chart */}
// // // // // //         <div className="overflow-auto">
// // // // // //           <div style={{ minWidth: '1200px', height: '600px' }}>
// // // // // //             <Bar data={chartData} options={options} />
// // // // // //           </div>
// // // // // //         </div>
// // // // // //       </div>
// // // // // //       <div className="text-center mt-4">
// // // // // //         <button
// // // // // //           onClick={downloadPDF}
// // // // // //           className="btn btn-danger"
// // // // // //         >
// // // // // //           Download as PDF
// // // // // //         </button>
// // // // // //       </div>
// // // // // //     </div>
// // // // // //   );
// // // // // // };

// // // // // // export default TableNo37;


















// // // // // import React, { useRef } from 'react';
// // // // // import 'bootstrap/dist/css/bootstrap.min.css';
// // // // // import { Table } from 'react-bootstrap';
// // // // // import { Bar } from 'react-chartjs-2';
// // // // // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// // // // // import html2canvas from 'html2canvas';
// // // // // import jsPDF from 'jspdf';

// // // // // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // // // // const utahData = "/img/1.png";
// // // // // const bodex = "/img/bodexLogo.png";
// // // // // const kpi = "/img/KPI-DASH 2.png";

// // // // // const TableNo37 = () => {
// // // // //   const pdfRef = useRef();

// // // // //   const industryData = [
// // // // //     { sector: 'Aerospace Product and Parts Manufacturing', employment: 10564 },
// // // // //     { sector: 'Agriculture, Construction, and Mining Machinery Manufacturing', employment: 859 },
// // // // //     { sector: 'Alumina and Aluminum Production and Processing', employment: 584 },
// // // // //     { sector: 'Animal Food Manufacturing', employment: 1279 },
// // // // //     { sector: 'Animal Slaughtering and Processing', employment: 4116 },
// // // // //     { sector: 'Apparel Accessories and Other Apparel Manufacturing', employment: 157 },
// // // // //     { sector: 'Apparel Knitting Mills', employment: 41 },
// // // // //     { sector: 'Architectural and Structural Metals Manufacturing', employment: 7098 },
// // // // //     { sector: 'Audio and Video Equipment Manufacturing', employment: 276 },
// // // // //     { sector: 'Bakeries and Tortilla Manufacturing', employment: 4002 },
// // // // //     { sector: 'Basic Chemical Manufacturing', employment: 1249 },
// // // // //     { sector: 'Beverage Manufacturing', employment: 1359 },
// // // // //     { sector: 'Medical Equipment and Supplies Manufacturing', employment: 12902 },
// // // // //     { sector: 'Motor Vehicle Parts Manufacturing', employment: 4380 },
// // // // //     { sector: 'Pharmaceutical and Medicine Manufacturing', employment: 8005 },
// // // // //     { sector: 'Plastics Product Manufacturing', employment: 7519 },
// // // // //     { sector: 'Printing and Related Support Activities', employment: 5120 },
// // // // //     { sector: 'Semiconductor and Other Electronic Component Manufacturing', employment: 3173 },
// // // // //     { sector: 'Textile and Fabric Finishing and Fabric Coating Mills', employment: 349 },
// // // // //     { sector: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing', employment: 573 },
// // // // //   ];

// // // // //   const chartData = {
// // // // //     labels: industryData.map(item => item.sector),
// // // // //     datasets: [
// // // // //       {
// // // // //         label: 'Employment Count',
// // // // //         data: industryData.map(item => item.employment),
// // // // //         backgroundColor: 'rgba(75, 192, 192, 0.6)',
// // // // //         borderColor: 'rgba(75, 192, 192, 1)',
// // // // //         borderWidth: 1,
// // // // //       },
// // // // //     ],
// // // // //   };

// // // // //   const options = {
// // // // //     responsive: true,
// // // // //     maintainAspectRatio: false,
// // // // //     scales: {
// // // // //       y: {
// // // // //         beginAtZero: true,
// // // // //         ticks: {
// // // // //           color: '#4B5563',
// // // // //           font: { size: 12 },
// // // // //         },
// // // // //         title: {
// // // // //           display: true,
// // // // //           text: 'Employment Count',
// // // // //           color: '#4B5563',
// // // // //           font: { size: 14 },
// // // // //         },
// // // // //       },
// // // // //       x: {
// // // // //         ticks: {
// // // // //           color: '#4B5563',
// // // // //           font: { size: 12 },
// // // // //           autoSkip: false, // Ensure all labels are shown
// // // // //           maxRotation: 90,
// // // // //           minRotation: 45,
// // // // //         },
// // // // //       },
// // // // //     },
// // // // //     plugins: {
// // // // //       legend: {
// // // // //         position: 'top',
// // // // //         labels: { font: { size: 12 } },
// // // // //       },
// // // // //       tooltip: {
// // // // //         callbacks: {
// // // // //           label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`,
// // // // //         },
// // // // //         bodyFont: { size: 12 },
// // // // //       },
// // // // //     },
// // // // //   };

// // // // //   const downloadPDF = () => {
// // // // //     const input = pdfRef.current;
// // // // //     const originalWidth = input.style.width;
// // // // //     input.style.width = "1500px"; // Temporarily widen the container for better rendering

// // // // //     html2canvas(input, { scale: 3 }).then((canvas) => {
// // // // //       const imgData = canvas.toDataURL("image/png");
// // // // //       const pdf = new jsPDF("landscape", "mm", "a4");

// // // // //       const imgWidth = 297; // A4 width in mm
// // // // //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

// // // // //       // First page content (table)
// // // // //       pdf.addImage(utahData, 'PNG', 90, 4, 30, 30);
// // // // //       pdf.setFontSize(26);
// // // // //       pdf.text('UTahData.org', 135, 22);
// // // // //       pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

// // // // //       pdf.setFontSize(12);
// // // // //       pdf.setLineWidth(0.5);
// // // // //       pdf.line(10, 35, 287, 35);

// // // // //       const headerHeight = 38;
// // // // //       pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

// // // // //       // Footer for first page
// // // // //       pdf.line(10, 190, 287, 190);
// // // // //       pdf.addImage(utahData, 'PNG', 33, 195, 20, 20);
// // // // //       pdf.setFontSize(10);
// // // // //       pdf.text('Built by', 55, 205);
// // // // //       pdf.addImage(bodex, 'PNG', 68, 200, 44, 10);
// // // // //       pdf.text('using', 112, 205);
// // // // //       pdf.addImage(kpi, 'PNG', 125, 195, 20, 20);
// // // // //       pdf.text('20241121.v1.1', 148, 205);

// // // // //       const currentURL = window.location.href;
// // // // //       const currentDate = new Date().toLocaleDateString();
// // // // //       pdf.text(`Downloaded from: ${currentURL}`, 10, 210);
// // // // //       pdf.text(`Downloaded on: ${currentDate}`, 230, 210);

// // // // //       // Check if the content is too long and needs a second page
// // // // //       const contentHeight = imgHeight + headerHeight + 20; // Adjust this to check how much space the content takes
// // // // //       if (contentHeight > 180) { // If content exceeds one page, add a second page for the chart
// // // // //         pdf.addPage();

// // // // //         // Add chart to second page
// // // // //         pdf.text('Employment by Industry (Chart)', 135, 20);
// // // // //         pdf.addImage(imgData, "PNG", 5, 40, imgWidth, imgHeight);
// // // // //         pdf.save("industry-employment-data.pdf");
// // // // //       } else {
// // // // //         pdf.save("industry-employment-data.pdf");
// // // // //       }
// // // // //       input.style.width = originalWidth;
// // // // //     });
// // // // //   };

// // // // //   return (
// // // // //     <div className="container my-5">
// // // // //       <h2 className="text-center mb-4">Employment by Industry Sector in 2021</h2>
// // // // //       <div ref={pdfRef}>
// // // // //         {/* Responsive Table */}
// // // // //         <div className="table-responsive mb-4">
// // // // //           <Table striped bordered hover>
// // // // //             <thead>
// // // // //               <tr>
// // // // //                 <th>Industry Sector</th>
// // // // //                 <th>Employment Count</th>
// // // // //               </tr>
// // // // //             </thead>
// // // // //             <tbody>
// // // // //               {industryData.map((item, index) => (
// // // // //                 <tr key={index}>
// // // // //                   <td>{item.sector}</td>
// // // // //                   <td>{item.employment.toLocaleString()}</td>
// // // // //                 </tr>
// // // // //               ))}
// // // // //             </tbody>
// // // // //           </Table>
// // // // //         </div>

// // // // //         {/* Bar Chart */}
// // // // //         <div className="overflow-auto mb-4" style={{ minWidth: '1000px', height: '500px' }}>
// // // // //           <div style={{ minWidth: '1200px', height: '600px' }}>
// // // // //             <Bar data={chartData} options={options} />
// // // // //           </div>
// // // // //         </div>
// // // // //       </div>
// // // // //       <div className="text-center mt-4">
// // // // //         <button onClick={downloadPDF} className="btn btn-danger">
// // // // //           Download as PDF
// // // // //         </button>
// // // // //       </div>
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default TableNo37;

















// // // // import React, { useRef } from 'react';
// // // // import 'bootstrap/dist/css/bootstrap.min.css';
// // // // import { Table } from 'react-bootstrap';
// // // // import { Bar } from 'react-chartjs-2';
// // // // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// // // // import html2canvas from 'html2canvas';
// // // // import jsPDF from 'jspdf';

// // // // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // // // const utahData = "/img/1.png";
// // // // const bodex = "/img/bodexLogo.png";
// // // // const kpi = "/img/KPI-DASH 2.png";

// // // // const TableNo37 = () => {
// // // //   const pdfRef = useRef();

// // // //   const industryData = [
// // // //     { sector: 'Aerospace Product and Parts Manufacturing', employment: 10564 },
// // // //     { sector: 'Agriculture, Construction, and Mining Machinery Manufacturing', employment: 859 },
// // // //     { sector: 'Alumina and Aluminum Production and Processing', employment: 584 },
// // // //     { sector: 'Animal Food Manufacturing', employment: 1279 },
// // // //     { sector: 'Animal Slaughtering and Processing', employment: 4116 },
// // // //     { sector: 'Apparel Accessories and Other Apparel Manufacturing', employment: 157 },
// // // //     { sector: 'Apparel Knitting Mills', employment: 41 },
// // // //     { sector: 'Architectural and Structural Metals Manufacturing', employment: 7098 },
// // // //     { sector: 'Audio and Video Equipment Manufacturing', employment: 276 },
// // // //     { sector: 'Bakeries and Tortilla Manufacturing', employment: 4002 },
// // // //     { sector: 'Basic Chemical Manufacturing', employment: 1249 },
// // // //     { sector: 'Beverage Manufacturing', employment: 1359 },
// // // //     { sector: 'Medical Equipment and Supplies Manufacturing', employment: 12902 },
// // // //     { sector: 'Motor Vehicle Parts Manufacturing', employment: 4380 },
// // // //     { sector: 'Pharmaceutical and Medicine Manufacturing', employment: 8005 },
// // // //     { sector: 'Plastics Product Manufacturing', employment: 7519 },
// // // //     { sector: 'Printing and Related Support Activities', employment: 5120 },
// // // //     { sector: 'Semiconductor and Other Electronic Component Manufacturing', employment: 3173 },
// // // //     { sector: 'Textile and Fabric Finishing and Fabric Coating Mills', employment: 349 },
// // // //     { sector: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing', employment: 573 },
// // // //   ];

// // // //   const chartData = {
// // // //     labels: industryData.map(item => item.sector),
// // // //     datasets: [
// // // //       {
// // // //         label: 'Employment Count',
// // // //         data: industryData.map(item => item.employment),
// // // //         backgroundColor: 'rgba(75, 192, 192, 0.6)',
// // // //         borderColor: 'rgba(75, 192, 192, 1)',
// // // //         borderWidth: 1,
// // // //       },
// // // //     ],
// // // //   };

// // // //   const options = {
// // // //     responsive: true,
// // // //     maintainAspectRatio: false,
// // // //     scales: {
// // // //       y: {
// // // //         beginAtZero: true,
// // // //         ticks: {
// // // //           color: '#4B5563',
// // // //           font: { size: 12 },
// // // //         },
// // // //         title: {
// // // //           display: true,
// // // //           text: 'Employment Count',
// // // //           color: '#4B5563',
// // // //           font: { size: 14 },
// // // //         },
// // // //       },
// // // //       x: {
// // // //         ticks: {
// // // //           color: '#4B5563',
// // // //           font: { size: 12 },
// // // //           autoSkip: false, // Ensure all labels are shown
// // // //           maxRotation: 90,
// // // //           minRotation: 45,
// // // //         },
// // // //       },
// // // //     },
// // // //     plugins: {
// // // //       legend: {
// // // //         position: 'top',
// // // //         labels: { font: { size: 12 } },
// // // //       },
// // // //       tooltip: {
// // // //         callbacks: {
// // // //           label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`,
// // // //         },
// // // //         bodyFont: { size: 12 },
// // // //       },
// // // //     },
// // // //   };

// // // //   const downloadPDF = () => {
// // // //     const input = pdfRef.current;
// // // //     const originalWidth = input.style.width;
// // // //     input.style.width = "1500px"; // Temporarily widen the container for better rendering

// // // //     html2canvas(input, { scale: 2 }).then((canvas) => {
// // // //       const imgData = canvas.toDataURL("image/png");
// // // //       const pdf = new jsPDF("landscape", "mm", "a4");

// // // //       const imgWidth = 297; // A4 width in mm
// // // //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

// // // //       // First page content (table)
// // // //       pdf.addImage(utahData, 'PNG', 90, 4, 30, 30);
// // // //       pdf.setFontSize(26);
// // // //       pdf.text('UTahData.org', 135, 22);
// // // //       pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

// // // //       pdf.setFontSize(12);
// // // //       pdf.setLineWidth(0.5);
// // // //       pdf.line(10, 35, 287, 35);

// // // //       const headerHeight = 38;
// // // //       pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

// // // //       // Footer for first page
// // // //       pdf.line(10, 190, 287, 190);
// // // //       pdf.addImage(utahData, 'PNG', 33, 195, 20, 20);
// // // //       pdf.setFontSize(10);
// // // //       pdf.text('Built by', 55, 205);
// // // //       pdf.addImage(bodex, 'PNG', 68, 200, 44, 10);
// // // //       pdf.text('using', 112, 205);
// // // //       pdf.addImage(kpi, 'PNG', 125, 195, 20, 20);
// // // //       pdf.text('20241121.v1.1', 148, 205);

// // // //       const currentURL = window.location.href;
// // // //       const currentDate = new Date().toLocaleDateString();
// // // //       pdf.text(`Downloaded from: ${currentURL}`, 10, 210);
// // // //       pdf.text(`Downloaded on: ${currentDate}`, 230, 210);

// // // //       const contentHeight = imgHeight + headerHeight + 20; // Adjust this to check how much space the content takes
// // // //       if (contentHeight > 180) { // If content exceeds one page, add a second page for the chart
// // // //         pdf.addPage();

// // // //         // Add chart to second page
// // // //         pdf.text('Employment by Industry (Chart)', 135, 20);
// // // //         pdf.addImage(imgData, "PNG", 5, 40, imgWidth, imgHeight);
// // // //         pdf.save("industry-employment-data.pdf");
// // // //       } else {
// // // //         pdf.save("industry-employment-data.pdf");
// // // //       }
// // // //       input.style.width = originalWidth;
// // // //     });
// // // //   };

// // // //   return (
// // // //     <div className="container my-5">
// // // //       <h2 className="text-center mb-4">Employment by Industry Sector in 2021</h2>
// // // //       <div ref={pdfRef}>
// // // //         {/* Responsive Table */}
// // // //         <div className="table-responsive mb-4">
// // // //           <Table striped bordered hover>
// // // //             <thead>
// // // //               <tr>
// // // //                 <th>Industry Sector</th>
// // // //                 <th>Employment Count</th>
// // // //               </tr>
// // // //             </thead>
// // // //             <tbody>
// // // //               {industryData.map((item, index) => (
// // // //                 <tr key={index}>
// // // //                   <td>{item.sector}</td>
// // // //                   <td>{item.employment.toLocaleString()}</td>
// // // //                 </tr>
// // // //               ))}
// // // //             </tbody>
// // // //           </Table>
// // // //         </div>

// // // //         {/* Bar Chart */}
// // // //         <div className="overflow-auto mb-4" style={{ minWidth: '1000px', height: '500px' }}>
// // // //           <div style={{ minWidth: '1200px', height: '600px' }}>
// // // //             <Bar data={chartData} options={options} />
// // // //           </div>
// // // //         </div>
// // // //       </div>
// // // //       <div className="text-center mt-4">
// // // //         <button onClick={downloadPDF} className="btn btn-danger">
// // // //           Download as PDF
// // // //         </button>
// // // //       </div>
// // // //     </div>
// // // //   );
// // // // };

// // // // export default TableNo37;
























// // // import React, { useRef } from 'react';
// // // import 'bootstrap/dist/css/bootstrap.min.css';
// // // import { Table } from 'react-bootstrap';
// // // import { Bar } from 'react-chartjs-2';
// // // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// // // import html2canvas from 'html2canvas';
// // // import jsPDF from 'jspdf';

// // // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // // const utahData = "/img/1.png";
// // // const bodex = "/img/bodexLogo.png";
// // // const kpi = "/img/KPI-DASH 2.png";

// // // const TableNo37 = () => {
// // //   const pdfRef = useRef();

// // //   const industryData = [
// // //     { sector: 'Aerospace Product and Parts Manufacturing', employment: 10564 },
// // //     { sector: 'Agriculture, Construction, and Mining Machinery Manufacturing', employment: 859 },
// // //     { sector: 'Alumina and Aluminum Production and Processing', employment: 584 },
// // //     { sector: 'Animal Food Manufacturing', employment: 1279 },
// // //     { sector: 'Animal Slaughtering and Processing', employment: 4116 },
// // //     { sector: 'Apparel Accessories and Other Apparel Manufacturing', employment: 157 },
// // //     { sector: 'Apparel Knitting Mills', employment: 41 },
// // //     { sector: 'Architectural and Structural Metals Manufacturing', employment: 7098 },
// // //     { sector: 'Audio and Video Equipment Manufacturing', employment: 276 },
// // //     { sector: 'Bakeries and Tortilla Manufacturing', employment: 4002 },
// // //     { sector: 'Basic Chemical Manufacturing', employment: 1249 },
// // //     { sector: 'Beverage Manufacturing', employment: 1359 },
// // //     { sector: 'Medical Equipment and Supplies Manufacturing', employment: 12902 },
// // //     { sector: 'Motor Vehicle Parts Manufacturing', employment: 4380 },
// // //     { sector: 'Pharmaceutical and Medicine Manufacturing', employment: 8005 },
// // //     { sector: 'Plastics Product Manufacturing', employment: 7519 },
// // //     { sector: 'Printing and Related Support Activities', employment: 5120 },
// // //     { sector: 'Semiconductor and Other Electronic Component Manufacturing', employment: 3173 },
// // //     { sector: 'Textile and Fabric Finishing and Fabric Coating Mills', employment: 349 },
// // //     { sector: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing', employment: 573 },
// // //   ];

// // //   const chartData = {
// // //     labels: industryData.map(item => item.sector),
// // //     datasets: [
// // //       {
// // //         label: 'Employment Count',
// // //         data: industryData.map(item => item.employment),
// // //         backgroundColor: 'rgba(75, 192, 192, 0.6)',
// // //         borderColor: 'rgba(75, 192, 192, 1)',
// // //         borderWidth: 1,
// // //       },
// // //     ],
// // //   };

// // //   const options = {
// // //     responsive: true,
// // //     maintainAspectRatio: false,
// // //     scales: {
// // //       y: {
// // //         beginAtZero: true,
// // //         ticks: {
// // //           color: '#4B5563',
// // //           font: { size: 12 },
// // //         },
// // //         title: {
// // //           display: true,
// // //           text: 'Employment Count',
// // //           color: '#4B5563',
// // //           font: { size: 14 },
// // //         },
// // //       },
// // //       x: {
// // //         ticks: {
// // //           color: '#4B5563',
// // //           font: { size: 12 },
// // //           autoSkip: false, // Ensure all labels are shown
// // //           maxRotation: 90,
// // //           minRotation: 45,
// // //         },
// // //       },
// // //     },
// // //     plugins: {
// // //       legend: {
// // //         position: 'top',
// // //         labels: { font: { size: 12 } },
// // //       },
// // //       tooltip: {
// // //         callbacks: {
// // //           label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`,
// // //         },
// // //         bodyFont: { size: 12 },
// // //       },
// // //     },
// // //   };

// // //   const downloadPDF = () => {
// // //     const input = pdfRef.current;
// // //     const originalWidth = input.style.width;
// // //     input.style.width = "1500px"; // Temporarily widen the container for better rendering

// // //     html2canvas(input, { scale: 2 }).then((canvas) => {
// // //       const imgData = canvas.toDataURL("image/png");
// // //       const pdf = new jsPDF("landscape", "mm", "a4");

// // //       const imgWidth = 297; // A4 width in mm
// // //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

// // //       // First page content (table)
// // //       pdf.addImage(utahData, 'PNG', 90, 4, 30, 30);
// // //       pdf.setFontSize(26);
// // //       pdf.text('UTahData.org', 135, 22);
// // //       pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

// // //       pdf.setFontSize(12);
// // //       pdf.setLineWidth(0.5);
// // //       pdf.line(10, 35, 287, 35);

// // //       const headerHeight = 38;
// // //       pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

// // //       // Footer for first page
// // //       pdf.line(10, 190, 287, 190);
// // //       pdf.addImage(utahData, 'PNG', 33, 195, 20, 20);
// // //       pdf.setFontSize(10);
// // //       pdf.text('Built by', 55, 205);
// // //       pdf.addImage(bodex, 'PNG', 68, 200, 44, 10);
// // //       pdf.text('using', 112, 205);
// // //       pdf.addImage(kpi, 'PNG', 125, 195, 20, 20);
// // //       pdf.text('20241121.v1.1', 148, 205);

// // //       const currentURL = window.location.href;
// // //       const currentDate = new Date().toLocaleDateString();
// // //       pdf.text(`Downloaded from: ${currentURL}`, 10, 210);
// // //       pdf.text(`Downloaded on: ${currentDate}`, 230, 210);

// // //       // Add a new page for the chart
// // //       pdf.addPage();

// // //       // Add chart to second page
// // //       pdf.text('Employment by Industry (Chart)', 135, 20);
// // //       pdf.addImage(imgData, "PNG", 5, 40, imgWidth, imgHeight);
// // //       pdf.save("industry-employment-data.pdf");
// // //       input.style.width = originalWidth;
// // //     });
// // //   };

// // //   return (
// // //     <div className="container my-5">
// // //       <h2 className="text-center mb-4">Employment by Industry Sector in 2021</h2>
// // //       <div ref={pdfRef}>
// // //         {/* Responsive Table */}
// // //         <div className="table-responsive mb-4">
// // //           <Table striped bordered hover>
// // //             <thead>
// // //               <tr>
// // //                 <th>Industry Sector</th>
// // //                 <th>Employment Count</th>
// // //               </tr>
// // //             </thead>
// // //             <tbody>
// // //               {industryData.map((item, index) => (
// // //                 <tr key={index}>
// // //                   <td>{item.sector}</td>
// // //                   <td>{item.employment.toLocaleString()}</td>
// // //                 </tr>
// // //               ))}
// // //             </tbody>
// // //           </Table>
// // //         </div>

// // //         {/* Bar Chart */}
// // //         <div className="overflow-auto mb-4" style={{ minWidth: '1000px', height: '500px' }}>
// // //           <div style={{ minWidth: '1200px', height: '600px' }}>
// // //             <Bar data={chartData} options={options} />
// // //           </div>
// // //         </div>
// // //       </div>
// // //       <div className="text-center mt-4">
// // //         <button onClick={downloadPDF} className="btn btn-danger">
// // //           Download as PDF
// // //         </button>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default TableNo37;













// // import React, { useRef } from 'react';
// // import 'bootstrap/dist/css/bootstrap.min.css';
// // import { Table } from 'react-bootstrap';
// // import { Bar } from 'react-chartjs-2';
// // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// // import html2canvas from 'html2canvas';
// // import jsPDF from 'jspdf';

// // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // const utahData = "/img/1.png";
// // const bodex = "/img/bodexLogo.png";
// // const kpi = "/img/KPI-DASH 2.png";

// // const TableNo37 = () => {
// //   const pdfRef = useRef();

// //   const industryData = [
// //     { sector: 'Aerospace Product and Parts Manufacturing', employment: 10564 },
// //     { sector: 'Agriculture, Construction, and Mining Machinery Manufacturing', employment: 859 },
// //     { sector: 'Alumina and Aluminum Production and Processing', employment: 584 },
// //     { sector: 'Animal Food Manufacturing', employment: 1279 },
// //     { sector: 'Animal Slaughtering and Processing', employment: 4116 },
// //     { sector: 'Apparel Accessories and Other Apparel Manufacturing', employment: 157 },
// //     { sector: 'Apparel Knitting Mills', employment: 41 },
// //     { sector: 'Architectural and Structural Metals Manufacturing', employment: 7098 },
// //     { sector: 'Audio and Video Equipment Manufacturing', employment: 276 },
// //     { sector: 'Bakeries and Tortilla Manufacturing', employment: 4002 },
// //     { sector: 'Basic Chemical Manufacturing', employment: 1249 },
// //     { sector: 'Beverage Manufacturing', employment: 1359 },
// //     { sector: 'Medical Equipment and Supplies Manufacturing', employment: 12902 },
// //     { sector: 'Motor Vehicle Parts Manufacturing', employment: 4380 },
// //     { sector: 'Pharmaceutical and Medicine Manufacturing', employment: 8005 },
// //     { sector: 'Plastics Product Manufacturing', employment: 7519 },
// //     { sector: 'Printing and Related Support Activities', employment: 5120 },
// //     { sector: 'Semiconductor and Other Electronic Component Manufacturing', employment: 3173 },
// //     { sector: 'Textile and Fabric Finishing and Fabric Coating Mills', employment: 349 },
// //     { sector: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing', employment: 573 },
// //   ];

// //   const chartData = {
// //     labels: industryData.map(item => item.sector),
// //     datasets: [
// //       {
// //         label: 'Employment Count',
// //         data: industryData.map(item => item.employment),
// //         backgroundColor: 'rgba(75, 192, 192, 0.6)',
// //         borderColor: 'rgba(75, 192, 192, 1)',
// //         borderWidth: 1,
// //       },
// //     ],
// //   };

// //   const options = {
// //     responsive: true,
// //     maintainAspectRatio: false,
// //     scales: {
// //       y: {
// //         beginAtZero: true,
// //         ticks: {
// //           color: '#4B5563',
// //           font: { size: 12 },
// //         },
// //         title: {
// //           display: true,
// //           text: 'Employment Count',
// //           color: '#4B5563',
// //           font: { size: 14 },
// //         },
// //       },
// //       x: {
// //         ticks: {
// //           color: '#4B5563',
// //           font: { size: 12 },
// //           autoSkip: false, // Ensure all labels are shown
// //           maxRotation: 90,
// //           minRotation: 45,
// //         },
// //       },
// //     },
// //     plugins: {
// //       legend: {
// //         position: 'top',
// //         labels: { font: { size: 12 } },
// //       },
// //       tooltip: {
// //         callbacks: {
// //           label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`,
// //         },
// //         bodyFont: { size: 12 },
// //       },
// //     },
// //   };

// //   const downloadPDF = () => {
// //     const input = pdfRef.current;
// //     const originalWidth = input.style.width;
// //     input.style.width = "1500px"; // Temporarily widen the container for better rendering

// //     html2canvas(input, { scale: 2 }).then((canvas) => {
// //       const imgData = canvas.toDataURL("image/png");
// //       const pdf = new jsPDF("landscape", "mm", "a4");

// //       const imgWidth = 297; // A4 width in mm
// //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

// //       // First page content (table with first 16 elements)
// //       pdf.addImage(utahData, 'PNG', 90, 4, 30, 30);
// //       pdf.setFontSize(26);
// //       pdf.text('UTahData.org', 135, 22);
// //       pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

// //       pdf.setFontSize(12);
// //       pdf.setLineWidth(0.5);
// //       pdf.line(10, 35, 287, 35);

// //       const headerHeight = 38;
// //       const firstPageData = industryData.slice(0, 16); // Get first 16 elements for the first page
// //       pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

// //       // Add the table for the first 16 elements
// //       pdf.text('Employment by Industry (Table)', 135, 45);
// //       pdf.table(10, 50, firstPageData, ['Industry Sector', 'Employment Count'], { autoSize: true });

// //       // Footer for first page
// //       pdf.line(10, 190, 287, 190);
// //       pdf.addImage(utahData, 'PNG', 33, 195, 20, 20);
// //       pdf.setFontSize(10);
// //       pdf.text('Built by', 55, 205);
// //       pdf.addImage(bodex, 'PNG', 68, 200, 44, 10);
// //       pdf.text('using', 112, 205);
// //       pdf.addImage(kpi, 'PNG', 125, 195, 20, 20);
// //       pdf.text('20241121.v1.1', 148, 205);

// //       const currentURL = window.location.href;
// //       const currentDate = new Date().toLocaleDateString();
// //       pdf.text(`Downloaded from: ${currentURL}`, 10, 210);
// //       pdf.text(`Downloaded on: ${currentDate}`, 230, 210);

// //       // Add a new page for the second table and chart
// //       pdf.addPage();

// //       // Add the header for second page
// //       pdf.addImage(utahData, 'PNG', 90, 4, 30, 30);
// //       pdf.setFontSize(26);
// //       pdf.text('UTahData.org', 135, 22);
// //       pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

// //       pdf.setFontSize(12);
// //       pdf.setLineWidth(0.5);
// //       pdf.line(10, 35, 287, 35);

// //       // Second page content (remaining 4 elements)
// //       const secondPageData = industryData.slice(16, 20); // Get remaining 4 elements for the second page
// //       pdf.text('Employment by Industry (Table)', 135, 45);
// //       pdf.table(10, 50, secondPageData, ['Industry Sector', 'Employment Count'], { autoSize: true });

// //       // Add the chart to the second page
// //       pdf.text('Employment by Industry (Chart)', 135, 100);
// //       pdf.addImage(imgData, "PNG", 5, 110, imgWidth, imgHeight);

// //       // Footer for second page
// //       pdf.line(10, 190, 287, 190);
// //       pdf.addImage(utahData, 'PNG', 33, 195, 20, 20);
// //       pdf.setFontSize(10);
// //       pdf.text('Built by', 55, 205);
// //       pdf.addImage(bodex, 'PNG', 68, 200, 44, 10);
// //       pdf.text('using', 112, 205);
// //       pdf.addImage(kpi, 'PNG', 125, 195, 20, 20);
// //       pdf.text('20241121.v1.1', 148, 205);

// //       const currentDate2 = new Date().toLocaleDateString();
// //       pdf.text(`Downloaded from: ${currentURL}`, 10, 210);
// //       pdf.text(`Downloaded on: ${currentDate2}`, 230, 210);

// //       pdf.save("industry-employment-data.pdf");
// //       input.style.width = originalWidth;
// //     });
// //   };

// //   return (
// //     <div className="container my-5">
// //       <h2 className="text-center mb-4">Employment by Industry Sector in 2021</h2>
// //       <div ref={pdfRef}>
// //         {/* Responsive Table */}
// //         <div className="table-responsive mb-4">
// //           <Table striped bordered hover>
// //             <thead>
// //               <tr>
// //                 <th>Industry Sector</th>
// //                 <th>Employment Count</th>
// //               </tr>
// //             </thead>
// //             <tbody>
// //               {industryData.map((item, index) => (
// //                 <tr key={index}>
// //                   <td>{item.sector}</td>
// //                   <td>{item.employment.toLocaleString()}</td>
// //                 </tr>
// //               ))}
// //             </tbody>
// //           </Table>
// //         </div>

// //         {/* Bar Chart */}
// //         <div className="overflow-auto mb-4" style={{ minWidth: '1000px', height: '500px' }}>
// //           <div style={{ minWidth: '1200px', height: '600px' }}>
// //             <Bar data={chartData} options={options} />
// //           </div>
// //         </div>
// //       </div>
// //       <div className="text-center mt-4">
// //         <button onClick={downloadPDF} className="btn btn-danger">
// //           Download as PDF
// //         </button>
// //       </div>
// //     </div>
// //   );
// // };

// // export default TableNo37;














// import React, { useRef } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const utahData = "/img/1.png";
// const bodex = "/img/bodexLogo.png";
// const kpi = "/img/KPI-DASH 2.png";

// const TableNo37 = () => {
//   const pdfRef = useRef();

//   const industryData = [
//     { sector: 'Aerospace Product and Parts Manufacturing', employment: 10564 },
//     { sector: 'Agriculture, Construction, and Mining Machinery Manufacturing', employment: 859 },
//     { sector: 'Alumina and Aluminum Production and Processing', employment: 584 },
//     { sector: 'Animal Food Manufacturing', employment: 1279 },
//     { sector: 'Animal Slaughtering and Processing', employment: 4116 },
//     { sector: 'Apparel Accessories and Other Apparel Manufacturing', employment: 157 },
//     { sector: 'Apparel Knitting Mills', employment: 41 },
//     { sector: 'Architectural and Structural Metals Manufacturing', employment: 7098 },
//     { sector: 'Audio and Video Equipment Manufacturing', employment: 276 },
//     { sector: 'Bakeries and Tortilla Manufacturing', employment: 4002 },
//     { sector: 'Basic Chemical Manufacturing', employment: 1249 },
//     { sector: 'Beverage Manufacturing', employment: 1359 },
//     { sector: 'Medical Equipment and Supplies Manufacturing', employment: 12902 },
//     { sector: 'Motor Vehicle Parts Manufacturing', employment: 4380 },
//     { sector: 'Pharmaceutical and Medicine Manufacturing', employment: 8005 },
//     { sector: 'Plastics Product Manufacturing', employment: 7519 },
//     { sector: 'Printing and Related Support Activities', employment: 5120 },
//     { sector: 'Semiconductor and Other Electronic Component Manufacturing', employment: 3173 },
//     { sector: 'Textile and Fabric Finishing and Fabric Coating Mills', employment: 349 },
//     { sector: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing', employment: 573 },
//   ];

//   const chartData = {
//     labels: industryData.map(item => item.sector),
//     datasets: [
//       {
//         label: 'Employment Count',
//         data: industryData.map(item => item.employment),
//         backgroundColor: 'rgba(75, 192, 192, 0.6)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       y: {
//         beginAtZero: true,
//         ticks: {
//           color: '#4B5563',
//           font: { size: 12 },
//         },
//         title: {
//           display: true,
//           text: 'Employment Count',
//           color: '#4B5563',
//           font: { size: 14 },
//         },
//       },
//       x: {
//         ticks: {
//           color: '#4B5563',
//           font: { size: 12 },
//           autoSkip: false, // Ensure all labels are shown
//           maxRotation: 90,
//           minRotation: 45,
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         position: 'top',
//         labels: { font: { size: 12 } },
//       },
//       tooltip: {
//         callbacks: {
//           label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`,
//         },
//         bodyFont: { size: 12 },
//       },
//     },
//   };

//   const downloadPDF = () => {
//     const input = pdfRef.current;
//     const originalWidth = input.style.width;
//     input.style.width = "1500px"; // Temporarily widen the container for better rendering

//     html2canvas(input, { scale: 2 }).then((canvas) => {
//       const imgData = canvas.toDataURL("image/png");
//       const pdf = new jsPDF("landscape", "mm", "a4");

//       const imgWidth = 297; // A4 width in mm
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;

//       // First page content (table with first 16 elements)
//       pdf.addImage(utahData, 'PNG', 90, 4, 30, 30);
//       pdf.setFontSize(26);
//       pdf.text('UTahData.org', 135, 22);
//       pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

//       pdf.setFontSize(12);
//       pdf.setLineWidth(0.5);
//       pdf.line(10, 35, 287, 35);

//       const headerHeight = 38;
//       const firstPageData = industryData.slice(0, 16); // Get first 16 elements for the first page
//       pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

//       // Create table data in the correct format for jsPDF
//       const firstPageTableData = firstPageData.map(item => [item.sector, item.employment]);

//       // Add the table for the first 16 elements
//       pdf.text('Employment by Industry (Table)', 135, 45);
//       pdf.autoTable({
//         startY: 50,
//         head: [['Industry Sector', 'Employment Count']],
//         body: firstPageTableData,
//       });

//       // Footer for first page
//       pdf.line(10, 190, 287, 190);
//       pdf.addImage(utahData, 'PNG', 33, 195, 20, 20);
//       pdf.setFontSize(10);
//       pdf.text('Built by', 55, 205);
//       pdf.addImage(bodex, 'PNG', 68, 200, 44, 10);
//       pdf.text('using', 112, 205);
//       pdf.addImage(kpi, 'PNG', 125, 195, 20, 20);
//       pdf.text('20241121.v1.1', 148, 205);

//       const currentURL = window.location.href;
//       const currentDate = new Date().toLocaleDateString();
//       pdf.text(`Downloaded from: ${currentURL}`, 10, 210);
//       pdf.text(`Downloaded on: ${currentDate}`, 230, 210);

//       // Add a new page for the second table and chart
//       pdf.addPage();

//       // Add the header for second page
//       pdf.addImage(utahData, 'PNG', 90, 4, 30, 30);
//       pdf.setFontSize(26);
//       pdf.text('UTahData.org', 135, 22);
//       pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

//       pdf.setFontSize(12);
//       pdf.setLineWidth(0.5);
//       pdf.line(10, 35, 287, 35);

//       // Second page content (remaining 4 elements)
//       const secondPageData = industryData.slice(16, 20); // Get remaining 4 elements for the second page
//       pdf.text('Employment by Industry (Table)', 135, 45);

//       // Create table data in the correct format for jsPDF
//       const secondPageTableData = secondPageData.map(item => [item.sector, item.employment]);

//       // Add the table for the remaining 4 elements
//       pdf.autoTable({
//         startY: 50,
//         head: [['Industry Sector', 'Employment Count']],
//         body: secondPageTableData,
//       });

//       // Add the chart to the second page
//       pdf.text('Employment by Industry (Chart)', 135, 100);
//       pdf.addImage(imgData, "PNG", 5, 110, imgWidth, imgHeight);

//       // Footer for second page
//       pdf.line(10, 190, 287, 190);
//       pdf.addImage(utahData, 'PNG', 33, 195, 20, 20);
//       pdf.setFontSize(10);
//       pdf.text('Built by', 55, 205);
//       pdf.addImage(bodex, 'PNG', 68, 200, 44, 10);
//       pdf.text('using', 112, 205);
//       pdf.addImage(kpi, 'PNG', 125, 195, 20, 20);
//       pdf.text('20241121.v1.1', 148, 205);

//       const currentDate2 = new Date().toLocaleDateString();
//       pdf.text(`Downloaded from: ${currentURL}`, 10, 210);
//       pdf.text(`Downloaded on: ${currentDate2}`, 230, 210);

//       pdf.save("industry-employment-data.pdf");
//       input.style.width = originalWidth;
//     });
//   };

//   return (
//     <div className="container my-5">
//       <h2 className="text-center mb-4">Employment by Industry Sector in 2021</h2>
//       <div ref={pdfRef}>
//         {/* Responsive Table */}
//         <div className="table-responsive mb-4">
//           <Table striped bordered hover>
//             <thead>
//               <tr>
//                 <th>Industry Sector</th>
//                 <th>Employment Count</th>
//               </tr>
//             </thead>
//             <tbody>
//               {industryData.map((item, index) => (
//                 <tr key={index}>
//                   <td>{item.sector}</td>
//                   <td>{item.employment}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </div>

//         {/* Chart */}
//         <div style={{ height: '400px' }}>
//           <Bar data={chartData} options={options} />
//         </div>
//       </div>

//       <div className="text-center mt-4">
//         <button className="btn btn-primary" onClick={downloadPDF}>Download PDF</button>
//       </div>
//     </div>
//   );
// };

// export default TableNo37;













import React, { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';  // Import the autoTable plugin
const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableNo37 = () => {
  const pdfRef = useRef();

  const industryData = [
    { sector: 'Aerospace Product and Parts Manufacturing', employment: 10564 },
    { sector: 'Agriculture, Construction, and Mining Machinery Manufacturing', employment: 859 },
    { sector: 'Alumina and Aluminum Production and Processing', employment: 584 },
    { sector: 'Animal Food Manufacturing', employment: 1279 },
    { sector: 'Animal Slaughtering and Processing', employment: 4116 },
    { sector: 'Apparel Accessories and Other Apparel Manufacturing', employment: 157 },
    { sector: 'Apparel Knitting Mills', employment: 41 },
    { sector: 'Architectural and Structural Metals Manufacturing', employment: 7098 },
    { sector: 'Audio and Video Equipment Manufacturing', employment: 276 },
    { sector: 'Bakeries and Tortilla Manufacturing', employment: 4002 },
    { sector: 'Basic Chemical Manufacturing', employment: 1249 },
    { sector: 'Beverage Manufacturing', employment: 1359 },
    { sector: 'Medical Equipment and Supplies Manufacturing', employment: 12902 },
    { sector: 'Motor Vehicle Parts Manufacturing', employment: 4380 },
    { sector: 'Pharmaceutical and Medicine Manufacturing', employment: 8005 },
    { sector: 'Plastics Product Manufacturing', employment: 7519 },
    { sector: 'Printing and Related Support Activities', employment: 5120 },
    { sector: 'Semiconductor and Other Electronic Component Manufacturing', employment: 3173 },
    { sector: 'Textile and Fabric Finishing and Fabric Coating Mills', employment: 349 },
    { sector: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing', employment: 573 },
  ];

  const chartData = {
    labels: industryData.map(item => item.sector),
    datasets: [
      {
        label: 'Employment Count',
        data: industryData.map(item => item.employment),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: '#4B5563',
          font: { size: 12 },
        },
        title: {
          display: true,
          text: 'Employment Count',
          color: '#4B5563',
          font: { size: 14 },
        },
      },
      x: {
        ticks: {
          color: '#4B5563',
          font: { size: 12 },
          autoSkip: false, // Ensure all labels are shown
          maxRotation: 90,
          minRotation: 45,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: { font: { size: 12 } },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`,
        },
        bodyFont: { size: 12 },
      },
    },
  };

  // const downloadPDF = () => {
  //   const input = pdfRef.current;
  //   const originalWidth = input.style.width;
  //   input.style.width = "1500px"; // Temporarily widen the container for better rendering

  //   html2canvas(input, { scale: 2 }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("landscape", "mm", "a4");

  //     const imgWidth = 297; // A4 width in mm
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     pdf.addImage(utahData, 'PNG', 90, 4, 30, 30);
  //     pdf.setFontSize(26);
  //     pdf.text('UTahData.org', 125, 22);
  //     pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

  //     pdf.setFontSize(12);
  //     pdf.setLineWidth(0.5);
  //     pdf.line(10, 35, 285, 35); // Line after header


  //     // First page content (table with first 16 elements)
  //   //  pdf.addImage(imgData, "PNG", 5, 5, imgWidth, imgHeight);

  //     // Table data for first page (first 16 items)
  //     const firstPageData = industryData.slice(0, 16);
  //     const firstPageTableData = firstPageData.map(item => [item.sector, item.employment]);

  //     // Add table to the first page
  //     pdf.text('Employment by Industry (Table)', 135, 45);
  //     pdf.autoTable({
  //       startY: 50,
  //       head: [['Industry Sector', 'Employment Count']],
  //       body: firstPageTableData,
  //     });

  //     // Footer for the first page
  //     pdf.line(10, 260, 200, 260); // Line before footer
  //     const footerStartY = 265;
  //     pdf.addImage(utahData, 'PNG', 33, 265, 20, 20);
  //     pdf.setFontSize(10);
  //     pdf.setTextColor(0, 122, 255);
  //     pdf.text('Built by', 55, 275);
  //     pdf.addImage(bodex, 'PNG', 68, 269, 44, 10);
  //     pdf.text('using', 112, 275);
  //     pdf.addImage(kpi, 'PNG', 125, 265, 20, 20);
  //     pdf.text('20241121.v1.1', 148, 275);
  //     pdf.line(10, 35, 200, 35);

  //     const currentURL = window.location.href;
  //     const currentDate = new Date().toLocaleDateString();
  //     pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
  //     pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

  //     // Second page content (remaining 4 items)
  //     pdf.addPage();
  //     const secondPageData = industryData.slice(16, 20);
  //     const secondPageTableData = secondPageData.map(item => [item.sector, item.employment]);

  //     pdf.text('Employment by Industry (Table)', 95, 45);
  //     pdf.autoTable({
  //       startY: 15,
  //       head: [['Industry Sector', 'Employment Count']],
  //       body: secondPageTableData,
  //     });

  //     // Add chart to second page
  //     pdf.text('Employment by Industry (Chart)', 135, 100);
  //    // pdf.addImage(imgData, "PNG", 5, 110, imgWidth, imgHeight);
      

  //     pdf.save("industry-employment-data.pdf");
  //     input.style.width = originalWidth;
  //   });
  // };


  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1500px"; // Temporarily widen the container for better rendering
  
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png"); // This is the image of the whole content (table + chart)
      const pdf = new jsPDF("landscape", "mm", "a4");
  
      const imgWidth = 297; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      // Add header and first page content
      pdf.addImage(imgData, "PNG", 5, 35, imgWidth, imgHeight); // Adds the table and chart image to the first page
      pdf.setFontSize(12);
     // pdf.text('Employment by Industry (Table)', 135, 45);
  
      // Table data for first page (first 16 items)
      const firstPageData = industryData.slice(0, 16);
      const firstPageTableData = firstPageData.map(item => [item.sector, item.employment]);
  
      // Add table to the first page
      // pdf.autoTable({
      //   startY: 50,
      //   head: [['Industry Sector', 'Employment Count']],
      //   body: firstPageTableData,
      // });
      pdf.addImage(utahData, 'PNG', 90, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text('UTahData.org', 125, 22);
      pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 285, 35); // Line after header
      // Footer for the first page
      pdf.line(10, 260, 200, 260); // Line before footer
     // const footerStartY = 265;
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text('Built by', 55, 275);
      pdf.text('using', 112, 275);
      pdf.text('20241121.v1.1', 148, 275);
      pdf.line(10, 35, 200, 35);
  
     // const currentURL = window.location.href;
     // const currentDate = new Date().toLocaleDateString();
    //  pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
     // pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
  
      // Add second page content (remaining 4 items)
      pdf.addPage();
      const secondPageData = industryData.slice(16, 20);
      const secondPageTableData = secondPageData.map(item => [item.sector, item.employment]);
      pdf.addImage(imgData, "PNG", 5, -185, imgWidth, imgHeight+20);
      pdf.line(10, 170, 280, 170); // Line before footer
      const footerStartY = 265;
      pdf.addImage(utahData, 'PNG', 73, 175, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text('Built by', 97, 185);
      pdf.addImage(bodex, 'PNG', 108, 179, 44, 10);
      pdf.text('using', 155, 185);
      pdf.addImage(kpi, 'PNG', 165, 175, 20, 20);
      pdf.text('20241121.v1.1', 187, 185);
     // pdf.line(10, 35, 200, 35);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 208);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 208);

  
     // pdf.text('Employment by Industry (Table)', 95, 45);
      // pdf.autoTable({
      //   startY: 15,
      //   head: [['Industry Sector', 'Employment Count']],
      //   body: secondPageTableData,
      // });
  
      // Add chart to second page
     // pdf.text('Employment by Industry (Chart)', 135, 100);
     // pdf.addImage(imgData, "PNG", 5, 110, imgWidth, imgHeight); // Adds the chart image to the second page
  
      // Save the PDF
      pdf.save("industry-employment-data.pdf");
  
      // Restore the original width of the container
      input.style.width = originalWidth;
    });
  };
  
  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Employment by Industry Sector in 2021</h2>
      <div ref={pdfRef}>
        {/* Responsive Table */}
        <div className="table-responsive mb-4">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Industry Sector</th>
                <th>Employment Count</th>
              </tr>
            </thead>
            <tbody>
              {industryData.map((item, index) => (
                <tr key={index}>
                  <td>{item.sector}</td>
                  <td>{item.employment}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* Chart */}
        <div
          style={{
            overflowX: "auto", // Enables horizontal scroll
            display: "flex",
            justifyContent: "center",
            width: "100%", // Takes full width
          }}
        >
          <div
            style={{
              minWidth: "600px", // Minimum width to enable scroll
              width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
              height: "400px",
            }}
          >
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
        </button>
      </div>
    </div>
  );
};

export default TableNo37;

