


import React, { useRef } from 'react';
import Table from 'react-bootstrap/Table';
import { Bar } from 'react-chartjs-2';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


// Chart.js components
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

const AeroSpace6 = ({ data }) => {
  const pdfRef = useRef(); // Ref for capturing PDF content

  // Ensure employment data is an array
  const shareData = Array.isArray(data) ? data : [];
  const years = shareData.map(item => item.year);
  const womenSharePercent = shareData.map(item => parseFloat(item['Women Share %']));
  const menSharePercent = shareData.map(item => parseFloat(item['Men Share %']));

  // Chart.js data
  const chartData = {
    labels: years,
    datasets: [
      {
        label: 'Women Share (%)',
        data: womenSharePercent,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: 'Men Share (%)',
        data: menSharePercent,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: 'Year' } },
      y: { beginAtZero: true, max: 100, title: { display: true, text: 'Share (%)' } },
    },
  };



  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    
    // Adjust the width based on screen size
    const screenWidth = window.innerWidth;
    input.style.width = screenWidth < 600 ? '800px' : '1200px'; // Adjust for mobile vs. desktop
  
    html2canvas(input, { scale: screenWidth < 600 ? 2 : 3 }).then((canvas) => { // Lower scale for mobile
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
  
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      // Add header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30); // Logo
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });
  
      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 200, 35); // Line after header
  
      // Add main content
      const headerHeight = 38;
      pdf.addImage(imgData, 'PNG', 5, headerHeight, imgWidth, imgHeight);
  
      // Add footer
      pdf.line(10, 260, 200, 260); // Line before footer
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);
      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
      pdf.text("20241121.v1.1", 148, footerStartY + 10);
  
      // Date and URL
      const currentDate = new Date().toLocaleDateString();
      pdf.setFontSize(10);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
  
      pdf.save('female-employment-trend.pdf');
      input.style.width = originalWidth; // Reset width
    });
  };
  

  return (
    <div className="w-full p-4">
      <div ref={pdfRef}>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Year</th>
              <th>All Sexes</th>
              <th>Female Employment</th>
              <th>Male Employment</th>
              <th>Women Share (%)</th>
              <th>Men Share (%)</th>
            </tr>
          </thead>
          <tbody>
            {shareData.length > 0 ? shareData.map((data, index) => (
              <tr key={index}>
                <td>{data.year}</td>
                <td>{data['All Sexes']}</td>
                <td>{data['Female']}</td>
                <td>{data['Male']}</td>
                <td>{data['Women Share %']}</td>
                <td>{data['Men Share %']}</td>
              </tr>
            )) : (
              <tr>
                <td colSpan="6" className="text-center">No data available</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <div style={{ minWidth: '600px', height: '400px' }}>
            <Bar data={chartData} options={chartOptions} />
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-4">
        <button onClick={downloadPDF} className="bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center">
          <span>Download as PDF</span>
          <img className="w-[2rem] ml-2" src={pdf} alt="Download PDF" />
        </button>
      </div>
    </div>
  );
};

export default AeroSpace6;
