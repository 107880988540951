// import React from "react";
// import { Bar } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";

// // Register chart.js components
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export default function TableNo8() {
//   // Data for the table and chart
//   const data = [
//     { year: 2018, female: 39659, male: 94477 },
//     { year: 2019, female: 41893, male: 96113 },
//     { year: 2020, female: 42644, male: 96190 },
//     { year: 2021, female: 46774, male: 101827 },
//     { year: 2022, female: 49196, male: 104344 },
//     { year: 2023, female: 49347, male: 104888 },
//   ];

//   // Chart data
//   const chartData = {
//     labels: data.map((item) => item.year),
//     datasets: [
//       {
//         label: "Female",
//         data: data.map((item) => item.female),
//         backgroundColor: "rgba(255, 99, 132, 0.5)",
//       },
//       {
//         label: "Male",
//         data: data.map((item) => item.male),
//         backgroundColor: "rgba(54, 162, 235, 0.5)",
//       },
//     ],
//   };

//   // Chart options
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: {
//         position: "top",
//       },
//       title: {
//         display: true,
//         text: "Employment Trend (2018-2023)",
//       },
//     },
//     scales: {
//       y: {
//         beginAtZero: true,
//       },
//     },
//   };

//   return (
//     <div className="w-full mx-auto my-6 p-4">
//       <h2 className="text-center text-2xl font-bold mb-4">
//         Employment Data (2018-2023)
//       </h2>

//       {/* Responsive Table */}
//       <div className="overflow-x-auto mb-8">
//         <table className="min-w-full border border-gray-300 text-center">
//           <thead className="bg-gray-200">
//             <tr>
//               <th className="px-4 py-2">Year</th>
//               <th className="px-4 py-2">Female</th>
//               <th className="px-4 py-2">Male</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data.map((row) => (
//               <tr key={row.year} className="border-b">
//                 <td className="px-4 py-2">{row.year}</td>
//                 <td className="px-4 py-2">{row.female.toLocaleString()}</td>
//                 <td className="px-4 py-2">{row.male.toLocaleString()}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {/* Responsive Bar Chart */}
//       <div
//           style={{
//             overflowX: "auto", // Enables horizontal scroll
//             display: "flex",
//             justifyContent: "center",
//             width: "100%", // Takes full width
//           }}
//         >
//           <div
//             style={{
//               minWidth: "600px", // Minimum width to enable scroll
//               width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
//               height: "400px",
//             }}
//           >
//         <Bar data={chartData} options={options} />
//       </div>
//     </div>
//     </div>
//   );
// }
















import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdfIcon = "/img/pdf1.png";

// Register chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function TableNo8() {
  const pdfRef = useRef();

  // Data for the table and chart
  const data = [
    { year: 2018, female: 39659, male: 94477 },
    { year: 2019, female: 41893, male: 96113 },
    { year: 2020, female: 42644, male: 96190 },
    { year: 2021, female: 46774, male: 101827 },
    { year: 2022, female: 49196, male: 104344 },
    { year: 2023, female: 49347, male: 104888 },
  ];

  // Chart data
  const chartData = {
    labels: data.map((item) => item.year),
    datasets: [
      {
        label: "Female",
        data: data.map((item) => item.female),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Male",
        data: data.map((item) => item.male),
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Employment Trend (2018-2023)",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px";

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      // Add content
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      // Add footer
      pdf.setLineWidth(0.5);
      pdf.line(10, 260, 200, 260);
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
      pdf.text("20241121.v1.1", 148, 275);
      pdf.line(10, 35, 200, 35);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

      pdf.save("employment_trend.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="w-full mx-auto my-6 p-4">
      <div ref={pdfRef}>
        <h2 className="text-center text-2xl font-bold mb-4">
          Employment Data (2018-2023)
        </h2>

        {/* Responsive Table */}
        <div className="overflow-x-auto mb-8">
          <table className="min-w-full border border-gray-300 text-center">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2">Year</th>
                <th className="px-4 py-2">Female</th>
                <th className="px-4 py-2">Male</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr key={row.year} className="border-b">
                  <td className="px-4 py-2">{row.year}</td>
                  <td className="px-4 py-2">{row.female.toLocaleString()}</td>
                  <td className="px-4 py-2">{row.male.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Responsive Bar Chart */}
        <div
          style={{
            overflowX: "auto", // Enables horizontal scroll
            display: "flex",
            justifyContent: "center",
            width: "100%", // Takes full width
          }}
        >
          <div
            style={{
              minWidth: "600px", // Minimum width to enable scroll
              width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
              height: "400px",
            }}
          >
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>

      {/* Download Button */}
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <img className="w-[2rem]" src={pdfIcon} alt="PDF Icon" />
        </button>
      </div>
    </div>
  );
}
