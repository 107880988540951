
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import ModalFunction from "./Modal";

const map = "/img/utah1.webp";
const county = "/img/counties1.png";
const city = "/img/city2.png";
const industries = "/img/industries1.png";
const jobs = "/img/jobs.png";
const population = "/img/population.png";
const unemployment = "/img/unemployment.webp";
const university = "/img/university.png"

const kpi = "/img/KPI-DASH 2.png";
const logo = "/img/1.png";
const utahCounty = "/img/utah-counties1.webp"
const utahCities = "/img/utah-city.webp"
const utahPopulation = "/img/utah-population.jpg"
const utahJobs = "/img/utah-jobs.jpg"
const utahUniversity = "/img/utah-universities.jpg"
const utahUnemployment = "/img/unemployment-rate.jpeg"
const utahIndustries = "/img/utah-industries.jpg"


export default function UtahAtGlance() {
  // const data = [
  //   { label: "Total Counties in Utah", value: "29", icon: <img src={county} alt="Counties" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahCounty},
  //   { label: "Total Cities in Utah", value: "329 (2024)", icon: <img src={city} alt="city" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahCities},
  //   { label: "Total Population of Utah", value: "3.45 Million (2024)", icon: <img src={population} alt="population" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahPopulation },
  //   { label: "Total Jobs In Utah", value: "1,769,700 (2024)" , icon: <img src={jobs} alt="jobs" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahJobs},
  //   { label: "Total Universities in Utah", value: "36 (2024)", icon: <img src={university} alt="university" className="h-20 w-18 filter brightness-0 invert" />  , backgroundImage: utahUniversity},
  //   { label: "Total Unemployment Rate in Utah", value: "3.50% (2024)" , icon: <img src={unemployment} alt="unemployment" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahUnemployment},
  //   { label: "Total Industries in Utah", value: "645 (2024)" , icon: <img src={industries} alt="industries" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahIndustries},
  // ];

  // const data = [
  //   { label: "Total Counties in Utah", value: "29", icon: <img src={county} alt="Counties" className="h-20 w-18 filter " />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  //   { label: "Total Cities in Utah", value: "329 (2024)", icon: <img src={city} alt="City" className="h-20 w-18 filter" />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]"},
  //   { label: "Total Population of Utah", value: "3.45 Million (2024)", icon: <img src={population} alt="Population" className="h-20 w-18 filter" />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]"},
  //   { label: "Total Jobs In Utah", value: "1,769,700 (2024)", icon: <img src={jobs} alt="Jobs" className="h-20 w-18 filter " />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  //   { label: "Total Universities in Utah", value: "36 (2024)", icon: <img src={university} alt="University" className="h-24 w-18 filter " />,gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  //   { label: "Total Unemployment Rate in Utah", value: "3.50% (2024)", icon: <img src={unemployment} alt="Unemployment" className="h-20 w-18 filter" />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  //   { label: "Total Industries in Utah", value: "645 (2024)", icon: <img src={industries} alt="Industries" className="h-20 w-18 filter " />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  // ];

  const data = [
    { label: "Counties in Utah", value: "29", icon: <img src={county} alt="Counties" className="h-20 w-18 filter " />, gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]" },
    { label: "Cities in Utah", value: "329 (2024)", icon: <img src={city} alt="City" className="h-20 w-18 filter" />, gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]"},
    { label: "Total Population of Utah", value: "3.45 Million (2024)", icon: <img src={population} alt="Population" className="h-20 w-18 filter" />, gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]"},
    { label: "Jobs In Utah", value: "1,769,700 (2024)", icon: <img src={jobs} alt="Jobs" className="h-20 w-18 filter " />,gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]"},
    { label: "Universities in Utah", value: "36 (2024)", icon: <img src={university} alt="University" className="h-24 w-18 filter " />,gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]" },
    { label: "Unemployment Rate in Utah", value: "3.50% (2024)", icon: <img src={unemployment} alt="Unemployment" className="h-20 w-18 filter" />, gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]" },
    { label: "Industries in Utah", value: "645 (2024)", icon: <img src={industries} alt="Industries" className="h-20 w-18 filter " />,gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]" },
  ];
  

  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar Section */}
      <Navbar
        ref={navbarRef} // Add ref here
        collapseOnSelect
        expand="lg"
        className="fixed top-0 left-0 w-full z-10 flex justify-center items-center"
        style={{
          height: "13vh",
          backgroundColor: "#1e59be",
          zIndex: 5,
          transition: "background-color 0.3s ease",
          position: "fixed",
        }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <div className="flex items-center">
              <img
                className="h-[8vh] md:h-[10vh]"
                src={logo}
                alt="UTahData Logo"
              />
              <h1 className="text-white ml-2 text-lg md:text-4xl">
                UTahData.org
                <span className="beta-label text-xs bg-yellow-500 text-white px-1 py-0.5 rounded-lg">
                  Beta
                </span>
              </h1>
            </div>
          </Navbar.Brand>

          {/* Toggle icon for responsive menu */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            {/* Boxed styling for mobile */}
            <Nav className="d-lg-none bg-white border rounded-lg p-4 shadow-lg flex flex-col items-center">
              <Nav.Link
                as={Link}
                to="/"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/betsy"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Ask Betsy AI
              </Nav.Link>

              {/* <Nav.Link
                as={Link}
                to="/faq"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/about"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
            </Nav>

            {/* Inline styling for larger screens */}
            <Nav className="d-none d-lg-flex ml-auto">
              <Nav.Link
                as={Link}
                to="/"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/betsy"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Ask Betsy AI
              </Nav.Link>
              <ModalFunction
                show={modalShow}
                onHide={() => setModalShow(false)}
              />

           
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    

<div
  className="relative flex-grow flex flex-col items-center justify-center mt-20 px-4 bg-cover bg-center bg-no-repeat "
  style={{ backgroundImage: `url(${map})`  }}
>
  {/* Overlay */}
  {/* Dark overlay */}
  <div className="absolute inset-0 bg-black opacity-50"></div>

  {/* Content with relative positioning to sit above the overlay */}
  <div className="relative text-center mt-5 p-1 rounded-md  inline-block px-6 py-2">
    <h1 className="text-3xl md:text-6xl font-bold text-[#ffc107] shadow-lg">
      Utah at Glance
    </h1>
  </div>

 

<div className="relative flex flex-wrap justify-center items-stretch gap-8 p-6 rounded-lg">
  {data.map((item, index) => (
    <div
      key={index}
      className={`relative p-4 w-full sm:w-1/2 md:w-1/4 flex flex-col justify-center items-center text-center
      overflow-hidden rounded-lg shadow-lg transform transition-transform duration-100 ease-in-out
      hover:scale-105 hover:shadow-2xl group  ${item.gradientClass}`}
    >
      {/* Background Image with Scaling Effect */}
      <div
        className="absolute inset-0 bg-cover bg-center transform transition-transform duration-100 ease-in-out group-hover:scale-150 "
        style={{ backgroundImage: `url(${item.backgroundImage})` }}
      ></div>

      {/* Overlay for Dark Effect */}
      {/* <div className="absolute inset-0 bg-blue-800 opacity-30"></div> */}

      {/* Content on top of the background */}
      <div className="relative z-10 flex flex-col items-center">
        <div className="mb-3">{item.icon}</div>
        <strong className="block text-blue-800 text-xl">{item.label}</strong>
        <span className="block text-blue-800 text-lg">{item.value}</span>
      </div>
    </div>
  ))}
</div>




</div>



      {/* Footer */}
    
    </div>
  );
}
