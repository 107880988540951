

// import React, { useEffect, useRef } from 'react';
// import { Chart } from 'chart.js';

// const ShareChart5 = () => {
//     const canvasRef = useRef(null);

//     useEffect(() => {
//         const ctx = canvasRef.current.getContext('2d');
//         const ageProfileChart = new Chart(ctx, {
//             type: 'line',
//             data: {
//                 labels: ['14-18', '19-21', '22-24', '25-34', '35-44', '45-54', '55-64', '65+'],
//                 datasets: [
//                     {
//                         label: 'Women',
//                         data: [5, 10, 15, 20, 25, 20, 15, 10],
//                         borderColor: 'rgba(255, 99, 132, 1)',
//                         backgroundColor: 'rgba(255, 99, 132, 0.2)',
//                         fill: true
//                     },
//                     {
//                         label: 'Men',
//                         data: [8, 12, 18, 22, 28, 22, 18, 12],
//                         borderColor: 'rgba(54, 162, 235, 1)',
//                         backgroundColor: 'rgba(54, 162, 235, 0.2)',
//                         fill: true
//                     }
//                 ]
//             },
//             options: {
//                 responsive: true,
//                 maintainAspectRatio: false,
//                 scales: {
//                     y: {
//                         beginAtZero: true,
//                         title: {
//                             display: true,
//                             text: 'Count'
//                         }
//                     }
//                 }
//             }
//         });

//         return () => {
//             // Clean up chart instance
//             ageProfileChart.destroy();
//         };
//     }, []);

//     return (
//         <div
//         style={{
//           overflowX: "auto", // Enables horizontal scroll
//           display: "flex",
//           justifyContent: "center",
//           width: "100%", // Takes full width
//         }}
//       >
//         <div
//           style={{
//             minWidth: "600px", // Minimum width to enable scroll
//             width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
//             height: "400px",
//           }}
//         >
//                 <canvas ref={canvasRef} id="ageProfileChart" className="w-full h-full" />
//             </div>
//         </div>
//     );
// };

// export default ShareChart5;













import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Images for PDF branding
const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";
const pdfIcon = "/img/pdf1.png";

const ShareChart5 = () => {
    const canvasRef = useRef(null);
    const pdfRef = useRef();

    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d');
        const ageProfileChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: ['14-18', '19-21', '22-24', '25-34', '35-44', '45-54', '55-64', '65+'],
                datasets: [
                    {
                        label: 'Women',
                        data: [5, 10, 15, 20, 25, 20, 15, 10],
                        borderColor: 'rgba(255, 99, 132, 1)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        fill: true
                    },
                    {
                        label: 'Men',
                        data: [8, 12, 18, 22, 28, 22, 18, 12],
                        borderColor: 'rgba(54, 162, 235, 1)',
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        fill: true
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Count'
                        }
                    }
                }
            }
        });

        return () => {
            // Clean up chart instance
            ageProfileChart.destroy();
        };
    }, []);

    // PDF generation logic
    const downloadPDF = () => {
        const input = pdfRef.current;
        const originalWidth = input.style.width;
        input.style.width = "1100px";

        html2canvas(input, { scale: 3 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");

            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Add header branding
            pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
            pdf.setFontSize(26);
            pdf.text("UTahData.org", 95, 22);
            pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

            // Add chart content
            const headerHeight = 38;
            pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

            // Footer branding and metadata
            pdf.setLineWidth(0.5);
            pdf.line(10, 260, 200, 260);
            pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
            pdf.setFontSize(10);
            pdf.setTextColor(0, 122, 255);
            pdf.text("Built by", 55, 275);
            pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
            pdf.text("using", 112, 275);
            pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
            pdf.text("20241121.v1.1", 148, 275);
            pdf.line(10, 35, 200, 35);

            const currentURL = window.location.href;
            const currentDate = new Date().toLocaleDateString();
            pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
            pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

            // Save PDF
            pdf.save("age_profile_chart.pdf");
            input.style.width = originalWidth;
        });
    };

    return (
        <div className="w-full mx-auto my-6 p-4">
            <div ref={pdfRef}>
                <h2 className="text-center text-2xl font-bold mb-4">
                    Age Profile Chart (Women vs Men)
                </h2>

                {/* Chart */}
                <div
                    style={{
                        overflowX: "auto", // Enables horizontal scroll
                        display: "flex",
                        justifyContent: "center",
                        width: "100%", // Takes full width
                    }}
                >
                    <div
                        style={{
                            minWidth: "600px", // Minimum width to enable scroll
                            width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
                            height: "400px",
                        }}
                    >
                        <canvas ref={canvasRef} id="ageProfileChart" className="w-full h-full" />
                    </div>
                </div>
            </div>

            {/* PDF Download Button */}
            <div className="flex justify-center mt-4">
                <button
                    onClick={downloadPDF}
                    className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
                >
                    <span>Download as PDF</span>
                    <img className="w-[2rem]" src={pdfIcon} alt="PDF Icon" />
                </button>
            </div>
        </div>
    );
};

export default ShareChart5;
