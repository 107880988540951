import React, { useEffect } from "react";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Legend from "@arcgis/core/widgets/Legend";
import Zoom from "@arcgis/core/widgets/Zoom";
import "@arcgis/core/assets/esri/themes/light/main.css";

const DisplayMap = () => {
  // useEffect(() => {
  //   // Initialize the map
  //   const map = new Map({
  //     basemap: {
  //       portalItem: {
  //         id: "5d1578ee98d846babb039c564ee7ecd5",
  //       },
  //     },
  //   });

  //   // Initialize the view
  //   const view = new MapView({
  //     container: "map-container", // DOM node
  //     map: map,
  //     center: [-118.805, 34.027], // Longitude, latitude
  //     zoom: 13, // Initial zoom level
  //   });

  //   // // Add the zoom widget
  //   // const zoom = new Zoom({
  //   //   view: view,
  //   //   position: "top-left",
  //   // });
  //   // view.ui.add(zoom);

  //   // // Add the legend widget
  //   // const legend = new Legend({
  //   //   view: view,
  //   //   position: "bottom-right",
  //   // });
  //   // view.ui.add(legend);

  //   // Cleanup when component unmounts
  //   return () => {
  //     if (view) {
  //       view.destroy();
  //     }
  //   };
  // }, []);

  return (
    // <div id="map-container" style={{ height: "71vh", width: "100vw" }}> </div>
    <div>
      {" "}
      <iframe
        src="https://utahdata.org/example.html"
        style={{ height: "71.3vh", width: "100vw" }}
      />
    </div>
  );
};

export default DisplayMap;
