import React from 'react'
import { Link } from 'react-router-dom'
const logo = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";


export default function Footer() {
  return (
    <div>
        <footer className="bg-[#0d2969] py-6 md:py-8 flex items-center justify-center my-auto">
        <div className="flex flex-col md:flex-row items-center text-center md:text-left space-y-4 md:space-y-0 md:space-x-4">
          {/* Logo Link */}
          <a href="/" className="flex items-center">
            <img className="h-12 md:h-16 mx-2" src={logo} alt="UTahData Logo" />
          </a>
          {/* Build by Text */}
          <span className="text-blue-300 text-sm md:text-base">Built by</span>
          {/* Bodex Link */}
          <a href="https://bodex.io/" className="flex items-center">
            <img
              src="https://bodex.io/wp-content/uploads/2020/11/BODEX-LLC-Logo_00fa00371_3580.png"
              alt="Bodex LLC Logo"
              className="h-8 md:h-10 mx-2"
            />
          </a>
          {/* Using Text */}
          <span className="text-blue-300 text-sm md:text-base">using</span>
          {/* KPI Dashboard Link */}
          <a
            href="https://bodex.io/products/kpi-dash/"
            className="flex items-center"
          >
            <img
              src={kpi}
              alt="KPI Dashboard Logo"
              className="h-12 md:h-16 lg:h-20 mx-2"
            />
          </a>
          {/* Version Link */}
          <Link
            to="/version"
            className="text-blue-300 text-sm md:text-base no-underline"
          >
            20241121.v1.1
          </Link>
        </div>
      </footer>
    </div>
  )
}
