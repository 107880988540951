// // import React from 'react'
// // //import DataTable from 'react-data-table-component'
// // import DataTable from 'react-data-table-component'
// // export default function DataSet() {

// //     const columns = [
// //         { name: "ID", selector: (row) => row.id, sortable: true },
// //         { name: "Title", selector: (row) => row.title, sortable: true, wrap: true },
// //         { name: "Body", selector: (row) => row.body, sortable: false, wrap: true },
// //       ];
      
// //       const DataTableExample = () => {
// //         const [data, setData] = useState([]);
// //         const [loading, setLoading] = useState(true);
      
// //         // Fetch data from JSONPlaceholder API
// //         useEffect(() => {
// //           const fetchData = async () => {
// //             try {
// //               const response = await fetch("https://jsonplaceholder.typicode.com/posts");
// //               const result = await response.json();
// //               setData(result);
// //             } catch (error) {
// //               console.error("Error fetching data:", error);
// //             } finally {
// //               setLoading(false);
// //             }
// //           };
      
// //           fetchData();
// //         }, []);

        

// //   return (
   
      

// //       <div style={{ padding: "20px" }}>
// //       <h1>Posts Data Table</h1>
// //       <DataTable
// //         title="Posts List"
// //         columns={columns}
// //         data={data}
// //         pagination
// //         progressPending={loading}
// //         highlightOnHover
// //         responsive
// //         striped
// //       />
// //     </div>

 
// //   )
// // }











// import React, { useEffect, useState } from "react";
// import DataTable from "react-data-table-component";
// import { createTheme } from "react-data-table-component";

// // Define the table columns
// const columns = [
//   { name: "ID", selector: (row) => row.id, sortable: true },
//   { name: "Title", selector: (row) => row.title, sortable: true, wrap: true },
//   { name: "Body", selector: (row) => row.body, sortable: false, wrap: true },
//   {name: "data", selector: (row) => row.body, sortable: false, wrap: true }
// ];

// const customStyles = {
//     header: {
//       style: {
//         fontSize: '22px',
//         fontWeight: 'bold',
//         color: '#FFFFFF',
//         backgroundColor: '#4CAF50',
//       },
//     },
//     rows: {
//       style: {
//         fontSize: '16px',
//         color: '#333333',
//       },
//       highlightOnHoverStyle: {
//         backgroundColor: '#F3F3F3',
//         borderBottomColor: '#FFFFFF',
//         borderRadius: '25px',
//         outline: '1px solid #FFFFFF',
//       },
//     },
//     pagination: {
//       style: {
//         fontSize: '16px',
//         color: '#FFFFFF',
//         backgroundColor: '#4CAF50',
//       },
//     },
//   };



// const customTheme = createTheme('custom', {
//     text: {
//       primary: '#4CAF50',
//       secondary: '#2aa198',
//     },
//     background: {
//       default: '#f5f5f5',
//     },
//     context: {
//       background: '#e91e63',
//       text: '#FFFFFF',
//     },
//     divider: {
//       default: '#D3D3D3',
//     },
//   }, 'light');
  

// const DataTableExample = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   // Fetch data from JSONPlaceholder API
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch("https://jsonplaceholder.typicode.com/posts");
//         const result = await response.json();
//         setData(result);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const [filterText, setFilterText] = useState("");

// const filteredData = data.filter(
//   item =>
//     item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
// );


//   return (
//     <div style={{ padding: "20px" }}>
//      <div style={{ padding: "20px" }}>
//     <h1>Posts Data Table</h1>
//     <input
//       type="text"
//       placeholder="Search..."
//       className="my-[5vh]"
//       style={{ marginBottom: "10px", padding: "5px" }}
//       onChange={(e) => setFilterText(e.target.value)}
//     />
//       <DataTable
//         title="Posts List"
//         columns={columns}
//         data={filteredData}
//         pagination
//         progressPending={loading}
//         highlightOnHover
//         responsive
//         striped
//         theme="custom"
//         customStyles={customStyles}
//       />
//     </div>
//     </div>
//   );
// };

// export default DataTableExample;












import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { createTheme } from "react-data-table-component";

// Define the table columns
const columns = [
  { name: "ID", selector: (row) => row.id, sortable: true },
  { name: "Title", selector: (row) => row.title, sortable: true, wrap: true },
  { name: "Body", selector: (row) => row.body, sortable: false, wrap: true },
  { name: "Data", selector: (row) => row.body, sortable: false, wrap: true }
];

const customStyles = {
  header: {
    style: {
      fontSize: '22px',
      fontWeight: 'bold',
      color: '#FFFFFF',
      backgroundColor: '#4CAF50',
    },
  },
  rows: {
    style: {
      fontSize: '16px',
      color: '#333333',
    },
    highlightOnHoverStyle: {
      backgroundColor: '#F3F3F3',
      borderBottomColor: '#FFFFFF',
      borderRadius: '25px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      fontSize: '16px',
      color: '#FFFFFF',
      backgroundColor: '#4CAF50',
    },
  },
};

const customTheme = createTheme('custom', {
  text: {
    primary: '#4CAF50',
    secondary: '#2aa198',
  },
  background: {
    default: '#f5f5f5',
  },
  context: {
    background: '#e91e63',
    text: '#FFFFFF',
  },
  divider: {
    default: '#D3D3D3',
  },
}, 'light');

const DataTableExample = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from JSONPlaceholder API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://jsonplaceholder.typicode.com/posts");
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [filterText, setFilterText] = useState("");

  const filteredData = data.filter(
    (item) =>
      item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div style={{ padding: "20px" }}>
      <h1>Posts Data Table</h1>
      <input
        type="text"
        placeholder="Search..."
        className="my-[5vh]"
        style={{ marginBottom: "10px", padding: "5px" }}
        onChange={(e) => setFilterText(e.target.value)}
      />

      <DataTable
        title="Posts List"
        columns={columns}
        data={filteredData}
        pagination
        progressPending={loading}
        highlightOnHover
        responsive
        striped
        theme="custom" // Use the custom theme here
        customStyles={customStyles}
      />
    </div>
  );
};

export default DataTableExample;
