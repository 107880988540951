import React, { useRef, useState, useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import "../styles/Faq.css";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const logo = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";

export default function Version() {
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}></Tooltip>
  );

  // Close dropdown when clicking outside the navbar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar
        ref={navbarRef}
        collapseOnSelect
        expand="lg"
        expanded={expanded}
        onToggle={() => setExpanded(!expanded)}
        className="fixed top-0 left-0 w-full z-10"
        style={{
          height: "13vh",
          backgroundColor: "#1e59be",
          zIndex: 5,
          transition: "background-color 0.3s ease",
        }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <div className="flex items-center">
              <img
                className="h-[8vh] md:h-[10vh]"
                src={logo}
                alt="UTahData Logo"
              />
              <h1 className="text-white ml-2 text-lg md:text-4xl">
                UTahData.org
                <span className="beta-label text-xs bg-yellow-500 text-white px-1 py-0.5 rounded-lg">
                  Beta
                </span>
              </h1>
            </div>
          </Navbar.Brand>

          {/* Toggle icon for responsive menu */}
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="d-lg-none bg-white border rounded-lg p-4 shadow-lg flex flex-col items-center">
              <Nav.Link
                as={Link}
                to="/"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
                onClick={() => setExpanded(false)}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/verticals"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
                onClick={() => setExpanded(false)}
              >
                Verticals
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
                onClick={() => setExpanded(false)}
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
                onClick={() => setExpanded(false)}
              >
                About Us
              </Nav.Link>
            </Nav>

            <Nav className="d-none d-lg-flex ml-auto">
              <Nav.Link
                as={Link}
                to="/"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/verticals"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main Content */}
      <div className="flex-grow bg-[#d2eaff] py-[2rem]">
        <div className="container">
          {/* Add your main content here */}
          <div className="flex-grow bg-[#d2eaff] py-[6rem] mt-[1vh]">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-bold mb-6 text-center text-[#0d2969]">
                <i className="fas fa-info-circle"></i>Latest Updates
              </h2>

              {/* Table */}
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-lg">
                  <thead>
                    <tr className="bg-[#1e59be] text-white">
                      <th className="py-3 px-6 text-left">Verticals</th>
                      <th className="py-3 px-6 text-left">Latest Update On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Example Row 1 */}
                    <tr className="hover:bg-gray-100 transition-colors duration-200">
                      <td className="py-4 px-6 border-b border-gray-300">
                        Aerospace and Defense
                      </td>
                      <td className="py-4 px-6 border-b border-gray-300">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <span> 22-Nov-2024</span>
                        </OverlayTrigger>
                      </td>
                    </tr>

                    {/* Example Row 2 */}
                    <tr className="hover:bg-gray-100 transition-colors duration-200">
                      <td className="py-4 px-6 border-b border-gray-300">
                        Manufacturing
                      </td>
                      <td className="py-4 px-6 border-b border-gray-300">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <span> 22-Nov-2024</span>
                        </OverlayTrigger>
                      </td>
                    </tr>

                    {/* Example Row 3 */}
                    {/* <tr className="hover:bg-gray-100 transition-colors duration-200">
            <td className="py-4 px-6 border-b border-gray-300">Agriculture, Food, and Natural Resources</td>
            <td className="py-4 px-6 border-b border-gray-300">2024-11-22</td>
          </tr>

          <tr className="hover:bg-gray-100 transition-colors duration-200">
            <td className="py-4 px-6 border-b border-gray-300">Business, Finance, and Marketing</td>
            <td className="py-4 px-6 border-b border-gray-300">2024-11-22</td>
          </tr>

          <tr className="hover:bg-gray-100 transition-colors duration-200">
            <td className="py-4 px-6 border-b border-gray-300">Engineering and Technology</td>
            <td className="py-4 px-6 border-b border-gray-300">2024-11-22</td>
          </tr>

          <tr className="hover:bg-gray-100 transition-colors duration-200">
            <td className="py-4 px-6 border-b border-gray-300">Human Services</td>
            <td className="py-4 px-6 border-b border-gray-300">2024-11-22</td>
          </tr>

          <tr className="hover:bg-gray-100 transition-colors duration-200">
            <td className="py-4 px-6 border-b border-gray-300">Transportation, Distribution, and Logistics</td>
            <td className="py-4 px-6 border-b border-gray-300">2024-11-22</td>
          </tr>

          <tr className="hover:bg-gray-100 transition-colors duration-200">
            <td className="py-4 px-6 border-b border-gray-300">Architecture and Construction</td>
            <td className="py-4 px-6 border-b border-gray-300">2024-11-22</td>
          </tr>

          <tr className="hover:bg-gray-100 transition-colors duration-200">
            <td className="py-4 px-6 border-b border-gray-300">Computer Science and IT</td>
            <td className="py-4 px-6 border-b border-gray-300">2024-11-22</td>
          </tr>

          <tr className="hover:bg-gray-100 transition-colors duration-200">
            <td className="py-4 px-6 border-b border-gray-300">Health Science</td>
            <td className="py-4 px-6 border-b border-gray-300">2024-11-22</td>
          </tr>

          <tr className="hover:bg-gray-100 transition-colors duration-200">
            <td className="py-4 px-6 border-b border-gray-300">Law, Public Safety, Corrections, and Security</td>
            <td className="py-4 px-6 border-b border-gray-300">2024-11-22</td>
          </tr>

          <tr className="hover:bg-gray-100 transition-colors duration-200">
            <td className="py-4 px-6 border-b border-gray-300">Arts, Audio/Visual Technology, and Communication</td>
            <td className="py-4 px-6 border-b border-gray-300">2024-11-22</td>
          </tr>

          <tr className="hover:bg-gray-100 transition-colors duration-200">
            <td className="py-4 px-6 border-b border-gray-300">Education and Training</td>
            <td className="py-4 px-6 border-b border-gray-300">2024-11-22</td>
          </tr>

          <tr className="hover:bg-gray-100 transition-colors duration-200">
            <td className="py-4 px-6 border-b border-gray-300">Hospitality and Tourism</td>
            <td className="py-4 px-6 border-b border-gray-300">2024-11-22</td>
          </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
     
    </div>
  );
}
