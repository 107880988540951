

import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import html2canvas from 'html2canvas';

import jsPDF from 'jspdf';
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";

const ShareChart1 = () => {
  const canvasRef = useRef(null);
  const pdfRef = useRef();

  useEffect(() => {
    Chart.register(...registerables);

    const ctx = canvasRef.current.getContext('2d');

    // Destroy previous chart instance if it exists to prevent duplication
    if (ctx.chart) {
      ctx.chart.destroy();
    }

    // Create the bar chart
    const groupedShareChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['2018', '2019', '2020', '2021', '2022'],
        datasets: [
          {
            label: 'Women % Share',
            data: [30, 32, 33, 35, 37],
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
          {
            label: 'Men % Share',
            data: [70, 68, 67, 65, 63],
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            labels: {
              color: '#4B5563',
              font: { size: 12 },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#4B5563',
              font: { size: 12 },
            },
            title: {
              display: true,
              text: 'Year',
              color: '#4B5563',
              font: { size: 14, weight: 'bold' },
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: '#4B5563',
              font: { size: 12 },
            },
            title: {
              display: true,
              text: 'Percentage (%)',
              color: '#4B5563',
              font: { size: 14, weight: 'bold' },
            },
          },
        },
      },
    });

    return () => {
      groupedShareChart.destroy();
    };
  }, []);

  const downloadPDF = () => {
    const input = pdfRef.current;

    const originalWidth = input.style.width;
    input.style.width = '1200px'; // Ensures layout consistency for PDF
    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      const imgWidth = 310;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(utahData, 'PNG', 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text('UTahData.org', 95, 22);
      pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

      const currentDate = new Date().toLocaleDateString();
      pdf.setFontSize(12);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 200, 35);

      pdf.addImage(imgData, 'PNG', 0, 38, imgWidth, imgHeight);

      pdf.line(10, 260, 200, 260);
      pdf.addImage(utahData, 'PNG', 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text('Built by', 55, 275);
      pdf.addImage(bodex, 'PNG', 68, 270, 44, 10);
      pdf.text('using', 112, 275);
      pdf.addImage(kpi, 'PNG', 125, 265, 20, 20);
      pdf.text('20241121.v1.1', 148, 275);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
     
      pdf.save('grouped_share_chart.pdf');
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="dashboard-container">
      <div ref={pdfRef} className="content-to-export">
        <div style={{ padding: '20px' }}>
          <h2>Grouped Share Chart (2018-2022)</h2>
          <div
            style={{
              overflowX: 'auto',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                minWidth: '600px',
                width: '1000px',
                height: '400px',
              }}
            >
              <canvas ref={canvasRef}></canvas>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
        </button>
      </div>
    </div>
  );
};

export default ShareChart1;
