import Accordion from "react-bootstrap/Accordion";
import "../styles/FaqSection.css";
import { useEffect } from "react";
import React, { useState } from "react";
import ShareChart1 from "./ShareChart1";
import ShareChart2 from "./ShareChart2";
import ShareChart3 from "./ShareChart3";
import ShareChart4 from "./ShareChart4";
import ShareChart5 from "./ShareChart5";
import Table1 from "./Table1";
import Table2 from "./Table2";
import Table3 from "./Table3";
import Table4 from "./Table4";
import Table5 from "./Table5";
import TableNo8 from "../Tables/TableNo8";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TableNo14 from "../Tables/TableNo14";
import TableNo9 from "../Tables/TableNo9";
import TableNo17 from "../Tables/TableNo17";
import TableNo18 from "../Tables/TableNo18";
import TableNo19 from "../Tables/TableNo19";
import TableNo20 from "../Tables/TableNo20";
import TableNo23 from "../Tables/TableNo23";
import TableNo10 from "../Tables/TableNo10";
import TableNo21 from "../Tables/TableNo21";
import TableNo22 from "../Tables/TableNo22";
import TableNo24 from "../Tables/TableNo24";
import { TableNo25 } from "../Tables/TableNo25";
import { TableNo26 } from "../Tables/TableNo26";
import TableNo27 from "../Tables/TableNo27";
import TableNo28 from "../Tables/TableNo28";
import TableNo29 from "../Tables/TableNo29";
import TableNo30 from "../Tables/TableNo30";
import TableNo31 from "../Tables/TableNo31";
import TableNo32 from "../Tables/TableNo32";
import TableNo33 from "../Tables/TableNo33";
import TableNo34 from "../Tables/TableNo34";
import TableNo35 from "../Tables/TableNo35";
import TableNo36 from "../Tables/TableNo36";
import TableNo37 from "../Tables/TableNo37";
import TableNo11 from "../Tables/TableNo11";
import TableNo13 from "../Tables/TableNo13";
import TableNo16 from "../Tables/TableNo16";
import TableNo12 from "../Tables/TableNo12";
import TableNo15 from "../Tables/TableNo15";
import { useLocation } from "react-router-dom";

export const FaqSection = ({ searchTerm }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Employment-Trend");

  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [expandedSubQuestion, setExpandedSubQuestion] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    const question = searchParams.get("question");

    if (tab) setActiveTab(tab);
    if (question) setExpandedQuestion(decodeURIComponent(question));
  }, [location.search]);

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    setExpandedQuestion(null); // Reset expanded question when switching tabs
  };

  return (
    <div className="w-[65vw] h-auto p-1 pt-4 mt-[3rem] border border-gray-300 rounded-lg shadow-lg  justify-center items-center mx-auto">
      <Tabs
        id="controlled-tab-example"
        className="mb-3"
        activeKey={activeTab}
        onSelect={handleTabSelect}
      >
        {/* employment trend */}

        <Tab
          eventKey="Employment-Trend"
          title="Employment Trend"
          id="Employment-Trend"
        >
          <Accordion >
            <Accordion.Item
              eventKey="Share percentage Analysis of Women versus Men in Manufacturing Employment"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Share percentage Analysis of Women versus Men in Manufacturing Employment"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. Share percentage Analysis of Women versus Men in
                  Manufacturing Employment
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <ShareChart1 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How does the overall employment trend in manufacturing reflect diversity changes between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does the overall employment trend in manufacturing reflect diversity changes between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. How does the overall employment trend in manufacturing
                  reflect diversity changes between 2018 and 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo14 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What are the total employment trends in Utah's manufacturing sector from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What are the total employment trends in Utah's manufacturing sector from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What are the total employment trends in Utah's
                  manufacturing sector from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo8 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How has the female employment share in the manufacturing industry changed in 2023 compared to 2018?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How has the female employment share in the manufacturing industry changed in 2023 compared to 2018?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. How has the female employment share in the manufacturing
                  industry changed in 2023 compared to 2018?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo9 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What percentage of the total workforce in 2023 is male in Utah's manufacturing sector?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What percentage of the total workforce in 2023 is male in Utah's manufacturing sector?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. What percentage of the total workforce in 2023 is male in
                  Utah's manufacturing sector?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo17 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What are the differences in employment counts between males and females in Utah manufacturing from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What are the differences in employment counts between males and females in Utah manufacturing from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  6. What are the differences in employment counts between males
                  and females in Utah manufacturing from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo18 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How has male employment in Utah's manufacturing sector evolved over the past year?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How has male employment in Utah's manufacturing sector evolved over the past year?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  7. How has male employment in Utah's manufacturing sector
                  evolved over the past year?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo19 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What trends are observed in the share of female workers in the Utah manufacturing sector?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What trends are observed in the share of female workers in the Utah manufacturing sector?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  8. What trends are observed in the share of female workers in
                  the Utah manufacturing sector?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo20 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the total count of male employees in the Utah manufacturing workforce in 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the total count of male employees in the Utah manufacturing workforce in 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  9. What is the total count of male employees in the Utah
                  manufacturing workforce in 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo23 />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Age Analysis */}

        <Tab eventKey="Age Analysis" title="Age Analysis" id="Age-Analysis">
          {/* <Accordion defaultActiveKey={['0']} alwaysOpen className="w-full"> */}
          <Accordion >
            <Accordion.Item
              eventKey="What is the percentage share of males and females in each age group during 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the percentage share of males and females in each age group during 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. What is the percentage share of males and females in each
                  age group during 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo10 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the total number of workers across all age groups?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the total number of workers across all age groups?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What is the total number of workers across all age groups?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo21 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey=" What percentage of the total workforce is female across all age groups?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  " What percentage of the total workforce is female across all age groups?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What percentage of the total workforce is female across all
                  age groups?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo22 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the Age Profile for Women in Manufacturing versus Men?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the Age Profile for Women in Manufacturing versus Men?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. What is the Age Profile for Women in Manufacturing versus
                  Men?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <ShareChart5 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which age group has the highest number of male workers?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which age group has the highest number of male workers?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. Which age group has the highest number of male workers?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo24 />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="How does male employment differ between the 14-18 and 22-24 age groups in the year 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does male employment differ between the 14-18 and 22-24 age groups in the year 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  6. How does male employment differ between the 14-18 and 22-24
                  age groups in the year 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo25 />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey=" Which age group has the highest percentage of female workers in 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  " Which age group has the highest percentage of female workers in 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  7. Which age group has the highest percentage of female
                  workers in 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {/* <TableNo26/> */}
                {/* <ShareChart5/> */}
                <TableNo26 />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="Which age group shows the largest gender disparity in terms of employment?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which age group shows the largest gender disparity in terms of employment?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  8. Which age group shows the largest gender disparity in terms
                  of employment?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {/* <TableNo27/> */}
                <TableNo27 />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="What is the overall percentage share of male workers in the manufacturing sector across all age groups during 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the overall percentage share of male workers in the manufacturing sector across all age groups during 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  9. What is the overall percentage share of male workers in the
                  manufacturing sector across all age groups during 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo28 />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="What age group represents the smallest share of total employment in the year 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What age group represents the smallest share of total employment in the year 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  10. What age group represents the smallest share of total
                  employment in the year 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo29 />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="What is the female-to-male ratio for each age group in manufacturing employment during 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the female-to-male ratio for each age group in manufacturing employment during 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  11. What is the female-to-male ratio for each age group in
                  manufacturing employment during 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo30 />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Race Analysis */}

        <Tab eventKey="Race Analysis" title="Race Analysis" id="Race-Analysis">
          <Accordion >
            <Accordion.Item
              eventKey="How does the distribution of employment share compare across different race groups in 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does the distribution of employment share compare across different race groups in 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. How does the distribution of employment share compare
                  across different race groups in 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo11 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the ratio of employment counts for 2018 vs 2023 for each race group?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the ratio of employment counts for 2018 vs 2023 for each race group?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What is the ratio of employment counts for 2018 vs 2023 for
                  each race group?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo16 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the percentage change in employment share for each race group from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the percentage change in employment share for each race group from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What is the percentage change in employment share for each
                  race group from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo13 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How do the employment changes across race groups compare for the years 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How do the employment changes across race groups compare for the years 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. How do the employment changes across race groups compare
                  for the years 2018 and 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo33 />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Education Analysis */}

        <Tab
          eventKey="Education Analysis"
          title="Education Analysis"
          id="Education-Analysis"
        >
          <Accordion>
            <Accordion.Item
              eventKey="Education Profile of Women in Manufacturing Employment"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Education Profile of Women in Manufacturing Employment"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. Education Profile of Women in Manufacturing Employment
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <ShareChart2 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Education Profile of Men in Manufacturing Employment"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Education Profile of Men in Manufacturing Employment"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. Education Profile of Men in Manufacturing Employment
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <ShareChart3 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How has the percentage share of women in manufacturing changed from 2018 to 2023 across different education levels?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How has the percentage share of women in manufacturing changed from 2018 to 2023 across different education levels?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. How has the percentage share of women in manufacturing
                  changed from 2018 to 2023 across different education levels?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo12 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Education Profile of Women versus Men Percentage in Manufacturing Employment"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Education Profile of Women versus Men Percentage in Manufacturing Employment"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. Education Profile of Women versus Men Percentage in
                  Manufacturing Employment
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <ShareChart4 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which education level saw the largest increase in female employment in the manufacturing sector between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which education level saw the largest increase in female employment in the manufacturing sector between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. Which education level saw the largest increase in female
                  employment in the manufacturing sector between 2018 and 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo31 />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="Which gender has shown a higher growth rate in employment in the manufacturing sector for individuals with a high school education?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which gender has shown a higher growth rate in employment in the manufacturing sector for individuals with a high school education?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  6. Which gender has shown a higher growth rate in employment
                  in the manufacturing sector for individuals with a high school
                  education?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo32 />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Ethinicity Analysis */}

        <Tab
          eventKey="Ethinicity Analysis"
          title="Ethinicity Analysis"
          id="Ethinicity-Analysis"
        >
          <Accordion >
            <Accordion.Item
              eventKey="Which ethnic group had the largest decrease in employment share percentage between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which ethnic group had the largest decrease in employment share percentage between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. Which ethnic group had the largest decrease in employment
                  share percentage between 2018 and 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo15 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which ethnic group had the lowest growth in employment numbers from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which ethnic group had the lowest growth in employment numbers from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. Which ethnic group had the lowest growth in employment
                  numbers from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo34 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How does the change in employment numbers for Hispanic or Latino workers compare to the overall change for all ethnicities between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does the change in employment numbers for Hispanic or Latino workers compare to the overall change for all ethnicities between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. How does the change in employment numbers for Hispanic or
                  Latino workers compare to the overall change for all
                  ethnicities between 2018 and 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo35 />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Industry Analysis */}

        <Tab
          eventKey="Industry Analysis"
          title="Industry Analysis"
          id="Industry-Analysis"
        >
          <Accordion >
            <Accordion.Item
              eventKey="What is the trend in employment counts for the Aerospace Product and Parts Manufacturing sector from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the trend in employment counts for the Aerospace Product and Parts Manufacturing sector from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. What is the trend in employment counts for the Aerospace
                  Product and Parts Manufacturing sector from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo36 />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which manufacturing industry sector had the highest number of employees in 2021?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which manufacturing industry sector had the highest number of employees in 2021?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. Which manufacturing industry sector had the highest number
                  of employees in 2021?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <TableNo37 />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Overview */}

        <Tab eventKey="Overview" title="Overview/Summary" id="Overview-Summary">
          <Accordion >
            <Accordion.Item
              eventKey="Utah Manufacturing Workforce Overview"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion("Utah Manufacturing Workforce Overview")
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. Utah Manufacturing Workforce Overview
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {/* sub questions */}
                <Accordion activeKey={expandedSubQuestion}>
                  <Accordion.Item
                    eventKey="Trends in Number of Workers"
                    className="pb-3"
                    onClick={() =>
                      setExpandedSubQuestion("Trends in Number of Workers")
                    }
                  >
                    <Accordion.Header className="custom-accordion-header">
                      <span className="accordion-title">
                        a. Trends in Number of Workers
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table1 />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey=" Age Analysis"
                    className="pb-3"
                    onClick={() => setExpandedSubQuestion(" Age Analysis")}
                  >
                    <Accordion.Header className="custom-accordion-header">
                      <span className="accordion-title">b. Age Analysis</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table2 />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="Education Analysis"
                    className="pb-3"
                    onClick={() => setExpandedSubQuestion("Education Analysis")}
                  >
                    <Accordion.Header className="custom-accordion-header">
                      <span className="accordion-title">
                        c. Education Analysis
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table3 />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="Race & Ethnicity Analysis"
                    className="pb-3"
                    onClick={() =>
                      setExpandedSubQuestion("Race & Ethnicity Analysis")
                    }
                  >
                    <Accordion.Header className="custom-accordion-header">
                      <span className="accordion-title">
                        d. Race & Ethnicity Analysis
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table4 />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="By Manufacturing Category"
                    className="pb-3"
                    onClick={() =>
                      setExpandedSubQuestion("By Manufacturing Category")
                    }
                  >
                    <Accordion.Header className="custom-accordion-header">
                      <span className="accordion-title">
                        e. By Manufacturing Category
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table5 />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Executive Summary"
              className="pb-3"
              onClick={() => setExpandedQuestion("Executive Summary")}
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">2. Executive Summary</span>
              </Accordion.Header>
              <Accordion.Body>
                <div className="faq-item">
                  <div className="faq-question"></div>
                  <div className="faq-answer">
                    <p>
                      The executive summary provides an overview of the trends
                      in manufacturing employment for both women and men from
                      2018 to 2022. Key highlights include:
                    </p>
                    <ul>
                      <li>
                        <strong>Growth in Female Employment:</strong> The
                        percentage of women in manufacturing employment has
                        steadily increased from 30% in 2018 to 37% in 2022.
                      </li>
                      <li>
                        <strong>Decline in Male Share:</strong> Over the same
                        period, the male employment share decreased from 70% to
                        63%, reflecting a diversification of the workforce.
                      </li>
                      <li>
                        <strong>Educational Attainment:</strong> Women are more
                        likely to have higher education degrees, with a growing
                        share holding Bachelor's and Graduate degrees compared
                        to men.
                      </li>
                      <li>
                        <strong>Age Distribution:</strong> Women in younger age
                        groups (14-18 and 19-21) saw a notable increase in
                        employment, while the male workforce remained relatively
                        stable across age ranges.
                      </li>
                    </ul>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
      </Tabs>
    </div>
  );
};
