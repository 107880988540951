// import AboutUs from "./components/pages/AboutUs";
// import Faq from "./components/pages/Manufacturing";
// import Navbar1 from "./components/pages/Navbar";
// import HomePage from "./components/pages/HomePage";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import ContactUs from "./components/pages/ContactUs";
// import { useRef } from "react";
// import Verticals from "./components/pages/Verticals";
// import Aerospace from "./components/pages/Aerospace";
// import Agriculture from "./components/pages/Agriculture";
// import BusinessFinance from "./components/pages/Business-Finance";
// import Engineering from "./components/pages/Engineering";
// import HumanServices from "./components/pages/Human-services";
// import Transportation from "./components/pages/Transportation";
// import Architecture from "./components/pages/Architecture-construction";
// import ComputerScience from "./components/pages/Computer-science";
// import HealthScience from "./components/pages/Health-science";
// import LawPublicSafety from "./components/pages/Law-public-safety";
// import Arts from "./components/pages/Arts";
// import Education from "./components/pages/Education";
// import Hospitality from "./components/pages/Hospitality";
// import Version from "./components/pages/Version";
// import AskBetsyAI from "./components/pages/AskBetsyAI";
// import "bootstrap/dist/css/bootstrap.min.css";
// import UtahAtGlance from "./components/pages/UtahAtGlance";
// import Footer from "./components/pages/Footer";
// import { useLocation } from "react-router-dom";
// import NewNavbar from "./components/pages/NewNavbar";

// function App() {
//   const swiperRef = useRef(null);
//   const location = useLocation();
//   const isHomePage = location.pathname === "/";
//   return (
//     <div>
//       <Router>
//         <Navbar1 swiperRef={swiperRef} />
//         {isHomePage ? <Navbar1 /> : <NewNavbar />}
//         <Routes>
//         <Route path="/data" element={<UtahAtGlance />} />
//           <Route path="/verticals" element={<Verticals />} />
//           <Route path="/about" element={<AboutUs />} />
//           <Route path="/verticals/manufacturing" element={<Faq />} />
//           <Route path="/contact-us" element={<ContactUs />} />
//           <Route path="/" element={<HomePage swiperRef={swiperRef} />} />
//           <Route path="/verticals/aerospace-and-defense" element={<Aerospace />} />
//           <Route path="/verticals/food-industry" element={<Agriculture />} />
//           <Route
//             path="/verticals/business-finance-marketing"
//             element={<BusinessFinance />}
//           />
//           <Route
//             path="/verticals/engineering-and-technology"
//             element={<Engineering />}
//           />
//           <Route path="/verticals/human-services" element={<HumanServices />} />
//           <Route
//             path="/verticals/transportation-distribution-logistics"
//             element={<Transportation />}
//           />
//           <Route
//             path="/verticals/architecture-and-construction"
//             element={<Architecture />}
//           />
//           <Route
//             path="/verticals/computer-science-and-it"
//             element={<ComputerScience />}
//           />
//           <Route path="/verticals/health-science" element={<HealthScience />} />
//           <Route
//             path="/verticals/law-public-safety-corrections-security"
//             element={<LawPublicSafety />}
//           />
//           <Route
//             path="/verticals/arts-audio-visual-technology-and-communication"
//             element={<Arts />}
//           />
//           <Route path="/verticals/education-and-training" element={<Education />} />
//           <Route
//             path="/verticals/hospitality-and-tourism"
//             element={<Hospitality />}
//           />
//           <Route path="/version" element={<Version />} />
//           <Route path="/betsy" element={<AskBetsyAI />} />
//         </Routes>
//         {/* <Route path="/" element={<Footer/>} /> */}
//         <Footer/>
//       </Router>
//     </div>
//   );
// }

// export default App;

import AboutUs from "./components/pages/AboutUs";
import Faq from "./components/pages/Manufacturing";

import HomePage from "./components/pages/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactUs from "./components/pages/ContactUs";
import { useRef } from "react";
import Verticals from "./components/pages/Verticals";
import Aerospace from "./components/pages/Aerospace";
import Agriculture from "./components/pages/Agriculture";
import BusinessFinance from "./components/pages/Business-Finance";
import Engineering from "./components/pages/Engineering";
import HumanServices from "./components/pages/Human-services";
import Transportation from "./components/pages/Transportation";
import Architecture from "./components/pages/Architecture-construction";
import ComputerScience from "./components/pages/Computer-science";
import HealthScience from "./components/pages/Health-science";
import LawPublicSafety from "./components/pages/Law-public-safety";
import Arts from "./components/pages/Arts";
import Education from "./components/pages/Education";
import Hospitality from "./components/pages/Hospitality";
import Version from "./components/pages/Version";
import AskBetsyAI from "./components/pages/AskBetsyAI";
import "bootstrap/dist/css/bootstrap.min.css";
import UtahAtGlance from "./components/pages/UtahAtGlance";
import Footer from "./components/pages/Footer";
import NewNavbar from "./components/pages/NewNavbar";
import { useLocation } from "react-router-dom";
import UtahAtGlance1 from "./components/pages/UathAtGlance1";
//<<<<<<< HEAD
import DataSet from "./components/pages/DataSet";
//=======
import ArcGISMap from "./components/pages/ArcGISMap";
import FourtySevenGMap from "./components/pages/FourtySevenGMap";
//import FlipBook from "./components/pages/FlipBook";
//>>>>>>> c440cd63d27d969606187f558aa55e06954a952a

//const samplePdf = "/img/samplePdf.pdf"

// New component to handle routes and location-dependent logic
function AppContent({ swiperRef }) {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div className="min-h-screen flex flex-col">
      {/* <Navbar1 swiperRef={swiperRef} /> */}
      {isHomePage ? null : <NewNavbar />}
      <main className="flex-1">
      <Routes>
        <Route path="/data" element={<UtahAtGlance />} />
        <Route path="/data1" element={<UtahAtGlance1 />} />
        <Route path="/dataset" element={<DataSet />} />
        {/* <Route path="/flipbook" element={< FlipBook pdfFile={samplePdf}/>} /> */}
        <Route path="/verticals" element={<Verticals />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/verticals/manufacturing" element={<Faq />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/" element={<HomePage swiperRef={swiperRef} />} />
        <Route
          path="/verticals/aerospace-and-defense"
          element={<Aerospace />}
        />
        <Route path="/verticals/food-industry" element={<Agriculture />} />
        <Route
          path="/verticals/business-finance-marketing"
          element={<BusinessFinance />}
        />
        <Route
          path="/verticals/engineering-and-technology"
          element={<Engineering />}
        />
        <Route path="/verticals/human-services" element={<HumanServices />} />
        <Route
          path="/verticals/transportation-distribution-logistics"
          element={<Transportation />}
        />
        <Route
          path="/verticals/architecture-and-construction"
          element={<Architecture />}
        />
        <Route
          path="/verticals/computer-science-and-it"
          element={<ComputerScience />}
        />
        <Route path="/verticals/health-science" element={<HealthScience />} />
        <Route
          path="/verticals/law-public-safety-corrections-security"
          element={<LawPublicSafety />}
        />
        <Route
          path="/verticals/arts-audio-visual-technology-and-communication"
          element={<Arts />}
        />
        <Route
          path="/verticals/education-and-training"
          element={<Education />}
        />
        <Route
          path="/verticals/hospitality-and-tourism"
          element={<Hospitality />}
        />
        <Route path="/version" element={<Version />} />
        <Route path="/betsy" element={<AskBetsyAI />} />
        <Route path="/bdx-arcgis-example-utah" element={<ArcGISMap />} />
        <Route path="/47G" element={<FourtySevenGMap />} />
      </Routes>
      </main>
      <Footer />
    </div>
  );
}

function App() {
  const swiperRef = useRef(null);

  return (
    <Router>
      {" "}
      {/* Move Router here to wrap the whole app */}
      <AppContent swiperRef={swiperRef} />
    </Router>
  );
}

export default App;
