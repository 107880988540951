


// import React from 'react';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

//  export const TableNo25 = () => {
//   const ageData = [
//     { ageGroup: '14-18', maleCount: 1323 },
//     { ageGroup: '19-21', maleCount: 4130 },
//     { ageGroup: '22-24', maleCount: 6122 },
//     { ageGroup: '25-34', maleCount: 23846 },
//     { ageGroup: '35-44', maleCount: 24512 },
//     { ageGroup: '45-54', maleCount: 21554 },
//     { ageGroup: '55-64', maleCount: 17110 },
//     { ageGroup: '65-99', maleCount: 6232 },
//   ];

//   const chartData = {
//     labels: ageData.map(item => item.ageGroup),
//     datasets: [
//       {
//         label: 'Male Count',
//         data: ageData.map(item => item.maleCount),
//         backgroundColor: 'rgba(75, 192, 192, 0.6)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       y: {
//         beginAtZero: true,
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h2>Age Group Analysis</h2>
      
//       {/* Responsive Table */}
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Age Group</th>
//             <th>Male Count</th>
//           </tr>
//         </thead>
//         <tbody>
//           {ageData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.ageGroup}</td>
//               <td>{item.maleCount}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
      
//       {/* Bar Chart */}
//       <div
//           style={{
//             overflowX: "auto", // Enables horizontal scroll
//             display: "flex",
//             justifyContent: "center",
//             width: "100%", // Takes full width
//           }}
//         >
//           <div
//             style={{
//               minWidth: "600px", // Minimum width to enable scroll
//               width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
//               height: "400px",
//             }}
//           >
//         <Bar data={chartData} options={options} />
//       </div>
//     </div>
//     </div>
//   );
// };













import React, { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Images for PDF branding
const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";
const pdfIcon = "/img/pdf1.png";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const TableNo25 = () => {
    const pdfRef = useRef();

    const ageData = [
        { ageGroup: '14-18', maleCount: 1323 },
        { ageGroup: '19-21', maleCount: 4130 },
        { ageGroup: '22-24', maleCount: 6122 },
        { ageGroup: '25-34', maleCount: 23846 },
        { ageGroup: '35-44', maleCount: 24512 },
        { ageGroup: '45-54', maleCount: 21554 },
        { ageGroup: '55-64', maleCount: 17110 },
        { ageGroup: '65-99', maleCount: 6232 },
    ];

    const chartData = {
        labels: ageData.map(item => item.ageGroup),
        datasets: [
            {
                label: 'Male Count',
                data: ageData.map(item => item.maleCount),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    const downloadPDF = () => {
        const input = pdfRef.current;
        const originalWidth = input.style.width;
        input.style.width = "1100px"; // Adjust width for better scaling in PDF

        html2canvas(input, { scale: 3 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');

            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Add header branding
            pdf.addImage(utahData, 'PNG', 60, 4, 30, 30);
            pdf.setFontSize(26);
            pdf.text('UTahData.org', 95, 22);
            pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

            // Add chart content
            const headerHeight = 38;
            pdf.addImage(imgData, 'PNG', 0, headerHeight, imgWidth, imgHeight);

            // Footer branding and metadata
            pdf.setLineWidth(0.5);
            pdf.line(10, 260, 200, 260);
            pdf.addImage(utahData, 'PNG', 33, 265, 20, 20);
            pdf.setFontSize(10);
            pdf.setTextColor(0, 122, 255);
            pdf.text('Built by', 55, 275);
            pdf.addImage(bodex, 'PNG', 68, 269, 44, 10);
            pdf.text('using', 112, 275);
            pdf.addImage(kpi, 'PNG', 125, 265, 20, 20);
            pdf.text('20241121.v1.1', 148, 275);
            pdf.line(10, 35, 200, 35);

            const currentURL = window.location.href;
            const currentDate = new Date().toLocaleDateString();
            pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
            pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

            // Save PDF
            pdf.save('male_count_age_group.pdf');
            input.style.width = originalWidth;
        });
    };

    return (
        <div className="container my-4">
            <div ref={pdfRef}>
                <h2>Age Group Analysis</h2>

                {/* Responsive Table */}
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Age Group</th>
                            <th>Male Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ageData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.ageGroup}</td>
                                <td>{item.maleCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {/* Bar Chart */}
                <div
                    style={{
                        overflowX: "auto",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            minWidth: "600px",
                            width: "1000px",
                            height: "400px",
                        }}
                    >
                        <Bar data={chartData} options={options} />
                    </div>
                </div>
            </div>

            {/* PDF Download Button */}
            {/* <div className="text-center mt-4">
            <button
                    onClick={downloadPDF}
                    className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
                >
                    <span>Download as PDF</span>
                    <img className="w-[2rem]" src={pdfIcon} alt="PDF Icon" />
                </button>
            </div> */}
            <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
        </button>
      </div>
        </div>
    );
};
