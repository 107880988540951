


import React, { useRef } from "react";
import Table from "react-bootstrap/Table";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AeroTable3 = ({ apiData }) => {
  const pdfRef = useRef();

  if (apiData.length === 0) {
    return <p>No data available for the employment trend.</p>;
  }

  // Extract years and employment counts for all sexes
  const years = Object.keys(apiData[0]).filter((key) => /^\d{4}$/.test(key));
  const trendData = apiData[0]; // Assume data is structured with a single entry for all sexes

  const chartData = {
    labels: years,
    datasets: [
      {
        label: "All Sexes Employment",
        data: years.map((year) => trendData[year]),
        backgroundColor: "rgba(75, 192, 192, 0.7)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1200px";

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header and footer to the PDF
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(10, 35, 200, 35); // Horizontal line after header

      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight); // Add chart image

      pdf.line(10, 260, 200, 260); // Horizontal line before footer

      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);
      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
      pdf.text("20241121.v1.1", 148, footerStartY + 10);

      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString();
      pdf.setFontSize(12);
      pdf.text(`Downloaded on: ${formattedDate}`, 150, 295); // Bottom-right corner date

      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("employment-trend-dashboard.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="max-w-full mx-auto p-4">
      <div ref={pdfRef} className="content-to-export">
        <div style={{ padding: "20px" }}>
          <h2>Employment Trend (Yearly)</h2>
          {/* Table Section */}
          <div className="overflow-x-auto">
            <Table striped bordered hover responsive className="mb-4">
              <thead>
                <tr>
                  <th>Year</th>
                  <th>All Sexes Employment</th>
                </tr>
              </thead>
              <tbody>
                {years.map((year, index) => (
                  <tr key={index}>
                    <td>{year}</td>
                    <td>{trendData[year]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

  
<div
  style={{
    overflowX: "auto", // Enables horizontal scroll
    display: "flex",
    justifyContent: "center",
    width: "100%", // Takes full width
  }}
>
  <div
    style={{
      minWidth: "600px", // Minimum width to enable scroll
      width: "1000px",   // Chart width, ensuring horizontal scroll on smaller screens
      height: "400px",
    }}
  >
    <Bar data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
  </div>
</div>

        </div>
      </div>

      {/* Centered Button */}
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <span>
            <img className="w-[2rem]" src={pdf}  />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AeroTable3;
