// import React from 'react';
// import Table from 'react-bootstrap/Table';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const AeroTable14 = ({ data }) => {
//   // Extract unique age groups for dropdown
//   const uniqueAgeGroups = [...new Set(data.map(item => item.age_group_label))];
//   const [selectedAgeGroup, setSelectedAgeGroup] = React.useState(uniqueAgeGroups[0]);

//   // Filter data for the selected age group
//   const filteredData = data.filter(item => item.age_group_label === selectedAgeGroup);

//   // Prepare data for the line chart
//   const chartData = {
//     labels: filteredData.map(item => item.year),
//     datasets: [
//       {
//         label: 'Female Growth (%)',
//         data: filteredData.map(item => parseFloat(item.female_growth_percent)),
//         borderColor: 'rgba(255, 99, 132, 1)',
//         backgroundColor: 'rgba(255, 99, 132, 0.2)',
//         borderWidth: 2,
//         fill: true,
//         tension: 0.4,
//       },
//       {
//         label: 'Male Growth (%)',
//         data: filteredData.map(item => parseFloat(item.male_growth_percent)),
//         borderColor: 'rgba(54, 162, 235, 1)',
//         backgroundColor: 'rgba(54, 162, 235, 0.2)',
//         borderWidth: 2,
//         fill: true,
//         tension: 0.4,
//       },
//     ],
//   };

//   const chartOptions = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//       tooltip: {
//         callbacks: {
//           label: context => `${context.dataset.label}: ${context.raw}%`,
//         },
//       },
//     },
//     scales: {
//       y: {
//         beginAtZero: false,
//         title: {
//           display: true,
//           text: 'Growth Percentage (%)',
//         },
//       },
//       x: {
//         title: {
//           display: true,
//           text: 'Year',
//         },
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h3 className="mt-3">Employment Growth Percentage by Gender (2019-2023)</h3>

//       {/* Dropdown to select Age Group */}
//       <div className="mb-4">
//         <label htmlFor="ageGroupSelect" className="form-label">
//           Select Age Group:
//         </label>
//         <select
//           id="ageGroupSelect"
//           className="form-select"
//           value={selectedAgeGroup}
//           onChange={e => setSelectedAgeGroup(e.target.value)}
//         >
//           {uniqueAgeGroups.map(ageGroup => (
//             <option key={ageGroup} value={ageGroup}>
//               {ageGroup}
//             </option>
//           ))}
//         </select>
//       </div>

//       {/* Table Section */}
//       <Table striped bordered hover responsive className="mb-4">
//         <thead>
//           <tr>
//             <th>Year</th>
//             <th>Age Group</th>
//             <th>Female Growth (%)</th>
//             <th>Male Growth (%)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.year}</td>
//               <td>{item.age_group_label}</td>
//               <td>{item.female_growth_percent}%</td>
//               <td>{item.male_growth_percent}%</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Chart Section */}
//       {/* <div style={{ width: '100%', height: '400px' }}> */}

//       {/* <div style={{ overflowX: 'auto', marginBottom: '20px', display: 'flex', justifyContent: 'center'}}>
//       <div style={{ width: '800px', height: '500px' }}>
//         <Line data={chartData} options={chartOptions} />
//       </div>
//     </div> */}

//     <div
//   style={{
//     overflowX: "auto", // Enables horizontal scroll
//     display: "flex",
//     justifyContent: "center",
//     width: "100%", // Takes full width
//   }}
// >
//   <div
//     style={{
//       minWidth: "600px", // Minimum width to enable scroll
//       width: "1000px",   // Chart width, ensuring horizontal scroll on smaller screens
//       height: "400px",
//     }}
//   >
//     <Line data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
//   </div>
// </div>
//     </div>
//   );
// };

// export default AeroTable14;

import React, { useRef } from "react";
import Table from "react-bootstrap/Table";
import { Line } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// Import images for PDF header and footer

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

const AeroTable14 = ({ data }) => {
  const pdfRef = useRef(); // Reference for PDF capture

  const uniqueAgeGroups = [
    ...new Set(data.map((item) => item.age_group_label)),
  ];
  const [selectedAgeGroup, setSelectedAgeGroup] = React.useState(
    uniqueAgeGroups[0]
  );
  const filteredData = data.filter(
    (item) => item.age_group_label === selectedAgeGroup
  );

  const chartData = {
    labels: filteredData.map((item) => item.year),
    datasets: [
      {
        label: "Female Growth (%)",
        data: filteredData.map((item) =>
          parseFloat(item.female_growth_percent)
        ),
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
      {
        label: "Male Growth (%)",
        data: filteredData.map((item) => parseFloat(item.male_growth_percent)),
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    input.style.width = "1100px"; // Expand width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.line(10, 35, 200, 35);

      // Main Content
      pdf.addImage(imgData, "PNG", 5, 38, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260);
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
      pdf.text("20241121.v1.1", 148, 275);
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("employment-growth.pdf");
      input.style.width = ""; // Reset width
    });
  };

  return (
    <div className="container">
      <div ref={pdfRef} className="content-to-export">
        <h3 className="mt-3">
          Employment Growth Percentage by Gender (2019-2023)
        </h3>
        <div className="mb-4">
          <label htmlFor="ageGroupSelect" className="form-label">
            Select Age Group:
          </label>
          <select
            id="ageGroupSelect"
            className="form-select"
            value={selectedAgeGroup}
            onChange={(e) => setSelectedAgeGroup(e.target.value)}
          >
            {uniqueAgeGroups.map((ageGroup) => (
              <option key={ageGroup} value={ageGroup}>
                {ageGroup}
              </option>
            ))}
          </select>
        </div>

        {/* Table Section */}
        <Table striped bordered hover responsive className="mb-4">
          <thead>
            <tr>
              <th>Year</th>
              <th>Age Group</th>
              <th>Female Growth (%)</th>
              <th>Male Growth (%)</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td>{item.year}</td>
                <td>{item.age_group_label}</td>
                <td>{item.female_growth_percent}%</td>
                <td>{item.male_growth_percent}%</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Chart Section */}
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ minWidth: "600px", width: "1000px", height: "400px" }}>
            <Line
              data={chartData}
              options={{ responsive: true, maintainAspectRatio: false }}
            />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center mt-4">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
          <span>
            <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AeroTable14;
