// // import React from 'react';
// // import { Line } from 'react-chartjs-2';
// // import 'chart.js/auto';

// // const AeroTable11 = ({ data }) => {
// //   if (!data || data.length === 0) {
// //     return <p>No data available</p>;
// //   }

// //   const years = ['2018', '2019', '2020', '2021', '2022', '2023'];

// //   // Extract age groups
// //   const ageGroups = data.map(group => group.age_group_label);

// //   // Prepare chart data for Male, Female, and Ratios
// //   const maleData = years.map(year => ({
// //     label: `Male-${year}`,
// //     data: data.map(group => parseInt(group[`Male-${year}`], 10)),
// //     borderColor: 'rgba(54, 162, 235, 0.8)',
// //     backgroundColor: 'rgba(54, 162, 235, 0.2)',
// //     fill: false,
// //     tension: 0.2,
// //   }));

// //   const femaleData = years.map(year => ({
// //     label: `Female-${year}`,
// //     data: data.map(group => parseInt(group[`Female-${year}`], 10)),
// //     borderColor: 'rgba(255, 99, 132, 0.8)',
// //     backgroundColor: 'rgba(255, 99, 132, 0.2)',
// //     fill: false,
// //     tension: 0.2,
// //   }));

// //   const ratioData = years.map(year => ({
// //     label: `Male to Female Ratio-${year}`,
// //     data: data.map(group => parseFloat(group[`Male to Female ratio-${year}`])),
// //     borderColor: 'rgba(75, 192, 192, 0.8)',
// //     backgroundColor: 'rgba(75, 192, 192, 0.2)',
// //     fill: false,
// //     tension: 0.2,
// //   }));

// //   // Merge all data for combined chart
// //   const chartData = {
// //     labels: ageGroups,
// //     datasets: [...maleData, ...femaleData, ...ratioData],
// //   };

// //   const chartOptions = {
// //     responsive: true,
// //     maintainAspectRatio: false,
// //     plugins: {
// //       legend: {
// //         position: 'top',
// //       },
// //     },
// //     scales: {
// //       y: {
// //         beginAtZero: true,
// //       },
// //     },
// //   };

// //   return (
// //     <div className="container">
// //       {/* Table Section */}
// //       <h2 style={{ textAlign: 'center', marginTop: '20px' }}>Gender Ratio By Year and Age Group</h2>
// //       <div style={{ overflowX: 'auto', marginBottom: '20px' }}>
// //         <table border="1" style={{ width: '100%', textAlign: 'center' }}>
// //           <thead>
// //             <tr>
// //               <th>Age Group</th>
// //               {years.map(year => (
// //                 <>
// //                   <th key={`female-${year}`}>Female-{year}</th>
// //                   <th key={`male-${year}`}>Male-{year}</th>
// //                   <th key={`ratio-${year}`}>Ratio-{year}</th>
// //                 </>
// //               ))}
// //             </tr>
// //           </thead>
// //           <tbody>
// //             {data.map((group, index) => (
// //               <tr key={index}>
// //                 <td>{group.age_group_label}</td>
// //                 {years.map(year => (
// //                   <>
// //                     <td key={`female-${year}-${index}`}>{group[`Female-${year}`]}</td>
// //                     <td key={`male-${year}-${index}`}>{group[`Male-${year}`]}</td>
// //                     <td key={`ratio-${year}-${index}`}>{group[`Male to Female ratio-${year}`]}</td>
// //                   </>
// //                 ))}
// //               </tr>
// //             ))}
// //           </tbody>
// //         </table>
// //       </div>

// //       {/* Chart Section with Horizontal Scroll */}
// //       <div style={{ overflowX: 'auto', marginBottom: '20px' }}>
// //         <div style={{ width: '1200px', height: '500px' }}>
// //           <Line data={chartData} options={chartOptions} />
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default AeroTable11;

// import React, { useRef } from 'react';
// import { Line } from 'react-chartjs-2';
// import 'chart.js/auto';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

// const AeroTable11 = ({ data }) => {
//   const pdfRef = useRef();

//   if (!data || data.length === 0) {
//     return <p>No data available</p>;
//   }

//   const years = ['2018', '2019', '2020', '2021', '2022', '2023'];

//   // Extract age groups
//   const ageGroups = data.map(group => group.age_group_label);

//   // Prepare chart data for Male, Female, and Ratios
//   const maleData = years.map(year => ({
//     label: `Male-${year}`,
//     data: data.map(group => parseInt(group[`Male-${year}`], 10)),
//     borderColor: 'rgba(54, 162, 235, 0.8)',
//     backgroundColor: 'rgba(54, 162, 235, 0.2)',
//     fill: false,
//     tension: 0.2,
//   }));

//   const femaleData = years.map(year => ({
//     label: `Female-${year}`,
//     data: data.map(group => parseInt(group[`Female-${year}`], 10)),
//     borderColor: 'rgba(255, 99, 132, 0.8)',
//     backgroundColor: 'rgba(255, 99, 132, 0.2)',
//     fill: false,
//     tension: 0.2,
//   }));

//   const ratioData = years.map(year => ({
//     label: `Male to Female Ratio-${year}`,
//     data: data.map(group => parseFloat(group[`Male to Female ratio-${year}`])),
//     borderColor: 'rgba(75, 192, 192, 0.8)',
//     backgroundColor: 'rgba(75, 192, 192, 0.2)',
//     fill: false,
//     tension: 0.2,
//   }));

//   // Merge all data for combined chart
//   const chartData = {
//     labels: ageGroups,
//     datasets: [...maleData, ...femaleData, ...ratioData],
//   };

//   const chartOptions = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//     },
//     scales: {
//       y: {
//         beginAtZero: true,
//       },
//     },
//   };

//   const downloadPDF = () => {
//     const input = pdfRef.current;
//     input.style.width = '1100px';  // Expand width for better PDF capture

//     html2canvas(input, { scale: 3 }).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF('p', 'mm', 'a4');

//       const imgWidth = 210;
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;

//       // Add the content to PDF
//       pdf.addImage(imgData, 'PNG', 5, 5, imgWidth, imgHeight);

//       // Footer section (adjust as per requirement)
//       const currentDate = new Date().toLocaleDateString();
//       pdf.text(`Downloaded on: ${currentDate}`, 150, 290);
//       const currentURL = window.location.href;
//       pdf.text(`Downloaded from: ${currentURL}`, 10, 290);

//       pdf.save('gender-ratio-report.pdf');
//       input.style.width = '';  // Reset width
//     });
//   };

//   return (
//     <div className="container">
//       <div ref={pdfRef} className="content-to-export">
//         {/* Table Section */}
//         <h2 style={{ textAlign: 'center', marginTop: '20px' }}>Gender Ratio By Year and Age Group</h2>
//         <div style={{ overflowX: 'auto', marginBottom: '20px' }}>
//           <table border="1" style={{ width: '100%', textAlign: 'center' }}>
//             <thead>
//               <tr>
//                 <th>Age Group</th>
//                 {years.map(year => (
//                   <>
//                     <th key={`female-${year}`}>Female-{year}</th>
//                     <th key={`male-${year}`}>Male-{year}</th>
//                     <th key={`ratio-${year}`}>Ratio-{year}</th>
//                   </>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {data.map((group, index) => (
//                 <tr key={index}>
//                   <td>{group.age_group_label}</td>
//                   {years.map(year => (
//                     <>
//                       <td key={`female-${year}-${index}`}>{group[`Female-${year}`]}</td>
//                       <td key={`male-${year}-${index}`}>{group[`Male-${year}`]}</td>
//                       <td key={`ratio-${year}-${index}`}>{group[`Male to Female ratio-${year}`]}</td>
//                     </>
//                   ))}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         {/* Chart Section with Horizontal Scroll */}
//         <div style={{ overflowX: 'auto', marginBottom: '20px' }}>
//           <div style={{ width: '1200px', height: '500px' }}>
//             <Line data={chartData} options={chartOptions} />
//           </div>
//         </div>
//       </div>

//       {/* Download PDF Button */}
//       <div className="flex justify-center mt-4">
//         <button
//           onClick={downloadPDF}
//           className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
//         >
//           <span>Download as PDF</span>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default AeroTable11;

import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";
const AeroTable11 = ({ data }) => {
  const pdfRef = useRef();

  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  const years = ["2018", "2019", "2020", "2021", "2022", "2023"];

  // Extract age groups
  const ageGroups = data.map((group) => group.age_group_label);

  // Prepare chart data for Male, Female, and Ratios
  const maleData = years.map((year) => ({
    label: `Male-${year}`,
    data: data.map((group) => parseInt(group[`Male-${year}`], 10)),
    borderColor: "rgba(54, 162, 235, 0.8)",
    backgroundColor: "rgba(54, 162, 235, 0.2)",
    fill: false,
    tension: 0.2,
  }));

  const femaleData = years.map((year) => ({
    label: `Female-${year}`,
    data: data.map((group) => parseInt(group[`Female-${year}`], 10)),
    borderColor: "rgba(255, 99, 132, 0.8)",
    backgroundColor: "rgba(255, 99, 132, 0.2)",
    fill: false,
    tension: 0.2,
  }));

  const ratioData = years.map((year) => ({
    label: `Male to Female Ratio-${year}`,
    data: data.map((group) =>
      parseFloat(group[`Male to Female ratio-${year}`])
    ),
    borderColor: "rgba(75, 192, 192, 0.8)",
    backgroundColor: "rgba(75, 192, 192, 0.2)",
    fill: false,
    tension: 0.2,
  }));

  // Merge all data for combined chart
  const chartData = {
    labels: ageGroups,
    datasets: [...maleData, ...femaleData, ...ratioData],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    input.style.width = "1100px"; // Expand width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });
      pdf.setFontSize(12);
      pdf.line(10, 35, 200, 35);

      // Main Content
      pdf.addImage(imgData, "PNG", 5, 38, imgWidth - 4, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260);
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.text("Built by", 55, footerStartY + 10);
      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
      pdf.text("20241121.v1.1", 148, footerStartY + 10);
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("gender-ratio-by-age-group.pdf");
      input.style.width = ""; // Reset width
    });
  };

  return (
    <div className="container">
      <div ref={pdfRef} className="content-to-export">
        {/* Table Section */}
        <div className="table-responsive">
          <table border="1" style={{ width: "100%", textAlign: "center" }}>
            <thead>
              <tr>
                <th>Age Group</th>
                {years.map((year) => (
                  <>
                    <th key={`female-${year}`}>Female-{year}</th>
                    <th key={`male-${year}`}>Male-{year}</th>
                    <th key={`ratio-${year}`}>Ratio-{year}</th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((group, index) => (
                <tr key={index}>
                  <td>{group.age_group_label}</td>
                  {years.map((year) => (
                    <>
                      <td key={`female-${year}-${index}`}>
                        {group[`Female-${year}`]}
                      </td>
                      <td key={`male-${year}-${index}`}>
                        {group[`Male-${year}`]}
                      </td>
                      <td key={`ratio-${year}-${index}`}>
                        {group[`Male to Female ratio-${year}`]}
                      </td>
                    </>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Chart Section with Horizontal Scroll */}
        <div style={{ overflowX: "auto", marginBottom: "20px" }}>
          <div style={{ width: "1100px", height: "500px" }}>
            <Line data={chartData} options={chartOptions} />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center mt-4">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
          <span>
            <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AeroTable11;
