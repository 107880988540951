import React, { useRef, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import "../styles/AboutUs.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Link } from "react-router-dom";
import "../styles/Aerospace.css";
import ModalFunction from "./Modal";
import DisplayMap from "./DisplayMap";
const census = "/img/census.png";

const logo = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";

export default function ArcGISMap() {
  const [modalShow, setModalShow] = React.useState(false);
  const iconData = [
    { icon: "fa-fighter-jet", title: "Aerospace and Defense" },
    { icon: "fa-seedling", title: "Agriculture, Food, and Natural Resources" },
    { icon: "fa-briefcase", title: "Business, Finance, and Marketing" },
    { icon: "fa-cogs", title: "Engineering and Technology" },
    { icon: "fa-hands-helping", title: "Human Services" },
    { icon: "fa-truck", title: "Transportation, Distribution, and Logistics" },
    { icon: "fa-building", title: "Architecture and Construction" },
    { icon: "fa-laptop-code", title: "Computer Science and IT" },
    { icon: "fa-heartbeat", title: "Health Science" },
    {
      icon: "fa-balance-scale",
      title: "Law, Public Safety, Corrections, and Security",
    },
    {
      icon: "fa-paint-brush",
      title: "Arts, Audio/Visual Technology, and Communication",
    },
    { icon: "fa-graduation-cap", title: "Education and Training" },
    { icon: "fa-concierge-bell", title: "Hospitality and Tourism" },
    { icon: "fa-industry", title: "Manufacturing" },
  ];

  const navbarRef = useRef(null);

  // Close the navbar on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse =
          navbarRef.current.querySelector(".navbar-collapse");
        if (navbarCollapse) {
          navbarCollapse.classList.remove("show"); // Close the navbar
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="main-wrapper flex flex-col min-h-screen">
      <Navbar
        ref={navbarRef} // Add ref here
        collapseOnSelect
        expand="lg"
        className="fixed top-0 left-0 w-full z-10 flex justify-center items-center"
        style={{
          height: "13vh",
          backgroundColor: "#1e59be",
          zIndex: 5,
          transition: "background-color 0.3s ease",
          position: "fixed",
        }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <div className="flex items-center">
              <img
                className="h-[8vh] md:h-[10vh]"
                src={logo}
                alt="UTahData Logo"
              />
              <h1 className="text-white ml-2 text-lg md:text-4xl">
                UTahData.org
                <span className="beta-label text-xs bg-yellow-500 text-white px-1 py-0.5 rounded-lg">
                  Beta
                </span>
              </h1>
            </div>
          </Navbar.Brand>

          {/* Toggle icon for responsive menu */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            {/* Boxed styling for mobile */}
            <Nav className="d-lg-none bg-white border rounded-lg p-4 shadow-lg flex flex-col items-center">
              <Nav.Link
                as={Link}
                to="/"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/betsy"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Ask Betsy AI
              </Nav.Link>

              {/* <Nav.Link
                as={Link}
                to="/faq"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/about"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
            </Nav>

            {/* Inline styling for larger screens */}
            <Nav className="d-none d-lg-flex ml-auto">
              <Nav.Link
                as={Link}
                to="/"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/betsy"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Ask Betsy AI
              </Nav.Link>
              <ModalFunction
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
              <ModalFunction
                show={modalShow}
                onHide={() => setModalShow(false)}
              />

              {/* <Nav.Link
                as={Link}
                to="/faq"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main content */}
      <div className="map-container bg-[#d2eaff] flex-grow">
        {/* <h2 className="about-us-title">UT Geothermal "HOTSPOTS"</h2> */}
        <DisplayMap />
      </div>

      {/* Footer */}
      <footer className="bg-[#0d2969] py-6 md:py-8 flex items-center justify-center">
        <div className="flex flex-col md:flex-row items-center text-center md:text-left space-y-4 md:space-y-0 md:space-x-4">
          {/* Logo Link */}
          <a href="/" className="flex items-center">
            <img className="h-12 md:h-16 mx-2" src={logo} alt="UTahData Logo" />
          </a>
          {/* Build by Text */}
          <span className="text-blue-300 text-sm md:text-base">Built by</span>
          {/* Bodex Link */}
          <a href="https://bodex.io/" className="flex items-center">
            <img
              src="https://bodex.io/wp-content/uploads/2020/11/BODEX-LLC-Logo_00fa00371_3580.png"
              alt="Bodex LLC Logo"
              className="h-8 md:h-10 mx-2"
            />
          </a>
          {/* Using Text */}
          <span className="text-blue-300 text-sm md:text-base">using</span>
          {/* KPI Dashboard Link */}
          <a
            href="https://bodex.io/products/kpi-dash/"
            className="flex items-center"
          >
            <img
              src={kpi}
              alt="KPI Dashboard Logo"
              className="h-12 md:h-16 lg:h-20 mx-2"
            />
          </a>
          {/* Version Link */}
          <Link
            to="/version"
            className="text-blue-300 text-sm md:text-base no-underline"
          >
            20241121.v1.1
          </Link>
        </div>
      </footer>
    </div>
  );
}
