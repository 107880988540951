// // import React from 'react';
// // import Table from 'react-bootstrap/Table';
// // import { Bar } from 'react-chartjs-2';
// // import {
// //   Chart as ChartJS,
// //   CategoryScale,
// //   LinearScale,
// //   BarElement,
// //   Tooltip,
// //   Legend,
// // } from 'chart.js';

// // ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

// // const AeroTable10 = ({ data }) => {
// //   // Extract years and data for male and female counts
// //   const years = Object.keys(data[0]).filter((key) => key.includes('2018') || key.includes('2019') || key.includes('2020') || key.includes('2021') || key.includes('2022') || key.includes('2023'));
// //   const femaleCounts = years.map((year) => parseFloat(data[0][`Female-${year.split('-')[1]}`]));
// //   const maleCounts = years.map((year) => parseFloat(data[0][`Male-${year.split('-')[1]}`]));

// //   // Prepare data for the Bar chart
// //   const chartData = {
// //     labels: years.map(year => year.split('-')[1]), // Use only the year part for labels
// //     datasets: [
// //       {
// //         label: 'Female Workers',
// //         data: femaleCounts,
// //         backgroundColor: 'rgba(255, 99, 132, 0.5)', // Light red
// //         borderColor: 'rgba(255, 99, 132, 1)',
// //         borderWidth: 1,
// //       },
// //       {
// //         label: 'Male Workers',
// //         data: maleCounts,
// //         backgroundColor: 'rgba(54, 162, 235, 0.5)', // Light blue
// //         borderColor: 'rgba(54, 162, 235, 1)',
// //         borderWidth: 1,
// //       },
// //     ],
// //   };

// //   const chartOptions = {
// //     responsive: true,
// //     plugins: {
// //       legend: {
// //         position: 'top',
// //       },
// //     },
// //     scales: {
// //       y: {
// //         beginAtZero: true,
// //       },
// //     },
// //   };

// //   return (
// //     <div className="container">
// //       {/* Table Section */}
// //       <div className="table-responsive">
// //         <Table striped bordered hover responsive>
// //           <thead>
// //             <tr>
// //               <th>Metric</th>
// //               {years.map((year, index) => (
// //                 <th key={index}>{year}</th>
// //               ))}
// //             </tr>
// //           </thead>
// //           <tbody>
// //             <tr>
// //               <td>Female Workers</td>
// //               {femaleCounts.map((count, index) => (
// //                 <td key={index}>{count.toFixed(2)}</td>
// //               ))}
// //             </tr>
// //             <tr>
// //               <td>Male Workers</td>
// //               {maleCounts.map((count, index) => (
// //                 <td key={index}>{count.toFixed(2)}</td>
// //               ))}
// //             </tr>
// //           </tbody>
// //         </Table>
// //       </div>

// //       {/* Chart Section */}
// //       {/* <div className="chart-container" style={{ overflowX: 'auto', width: '100%', maxWidth: '100%' }}>
// //         <div style={{ width: '100%', height: '400px', flex: 'none' }}> */}

// //         <div style={{ overflowX: 'auto', marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
// //         <div style={{ width: '800px', height: '500px' }}>
// //           <Bar data={chartData} options={chartOptions} />
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default AeroTable10;

// import React from 'react';
// import Table from 'react-bootstrap/Table';
// import { Bar } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

// const AeroTable10 = ({ data }) => {
//   // Extract years and data for male and female counts
//   const years = [
//     "2018", "2019", "2020", "2021", "2022", "2023"
//   ];

//   const femaleCounts = years.map((year) => parseFloat(data[0][`Female-${year}`]));
//   const maleCounts = years.map((year) => parseFloat(data[0][`Male-${year}`]));

//   // Prepare data for the Bar chart
//   const chartData = {
//     labels: years, // Use years for labels
//     datasets: [
//       {
//         label: 'Female Workers',
//         data: femaleCounts,
//         backgroundColor: 'rgba(255, 99, 132, 0.5)', // Light red
//         borderColor: 'rgba(255, 99, 132, 1)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Male Workers',
//         data: maleCounts,
//         backgroundColor: 'rgba(54, 162, 235, 0.5)', // Light blue
//         borderColor: 'rgba(54, 162, 235, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   const chartOptions = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//     },
//     scales: {
//       y: {
//         beginAtZero: true,
//       },
//     },
//   };

//   return (
//     <div className="container">
//       {/* Table Section */}
//       <div className="table-responsive">
//         <Table striped bordered hover responsive>
//           <thead>
//             <tr>
//               <th>Metric</th>
//               {years.map((year, index) => (
//                 <th key={index}>{year}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Female Workers</td>
//               {femaleCounts.map((count, index) => (
//                 <td key={index}>{count.toFixed(2)}</td>
//               ))}
//             </tr>
//             <tr>
//               <td>Male Workers</td>
//               {maleCounts.map((count, index) => (
//                 <td key={index}>{count.toFixed(2)}</td>
//               ))}
//             </tr>
//           </tbody>
//         </Table>
//       </div>

//       {/* Chart Section */}
//       {/* <div style={{ overflowX: 'auto', marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
//         <div style={{ width: '800px', height: '500px' }}>
//           <Bar data={chartData} options={chartOptions} />
//         </div>
//       </div> */}

//       <div
//   style={{
//     overflowX: "auto", // Enables horizontal scroll
//     display: "flex",
//     justifyContent: "center",
//     width: "100%", // Takes full width
//   }}
// >
//   <div
//     style={{
//       minWidth: "600px", // Minimum width to enable scroll
//       width: "1000px",   // Chart width, ensuring horizontal scroll on smaller screens
//       height: "400px",
//     }}
//   >
//     <Bar data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
//   </div>
// </div>
//     </div>
//   );
// };

// export default AeroTable10;

import React, { useRef } from "react";
import Table from "react-bootstrap/Table";
import { Bar } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// Register the necessary components for Chart.js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

const AeroTable10 = ({ data }) => {
  const pdfRef = useRef();

  // Extract years and data for male and female counts
  const years = ["2018", "2019", "2020", "2021", "2022", "2023"];

  const femaleCounts = years.map((year) =>
    parseFloat(data[0][`Female-${year}`])
  );
  const maleCounts = years.map((year) => parseFloat(data[0][`Male-${year}`]));

  // Prepare data for the Bar chart
  const chartData = {
    labels: years, // Use years for labels
    datasets: [
      {
        label: "Female Workers",
        data: femaleCounts,
        backgroundColor: "rgba(255, 99, 132, 0.5)", // Light red
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "Male Workers",
        data: maleCounts,
        backgroundColor: "rgba(54, 162, 235, 0.5)", // Light blue
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    input.style.width = "1100px"; // Expand width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });
      pdf.setFontSize(12);
      pdf.line(10, 35, 200, 35);

      // Main Content
      pdf.addImage(imgData, "PNG", 5, 38, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260);
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.text("Built by", 55, footerStartY + 10);
      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
      pdf.text("20241121.v1.1", 148, footerStartY + 10);
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("employment-by-gender.pdf");
      input.style.width = ""; // Reset width
    });
  };

  return (
    <div className="container">
      <div ref={pdfRef} className="content-to-export">
        {/* Table Section */}
        <div className="table-responsive">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Metric</th>
                {years.map((year, index) => (
                  <th key={index}>{year}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Female Workers</td>
                {femaleCounts.map((count, index) => (
                  <td key={index}>{count.toFixed(2)}</td>
                ))}
              </tr>
              <tr>
                <td>Male Workers</td>
                {maleCounts.map((count, index) => (
                  <td key={index}>{count.toFixed(2)}</td>
                ))}
              </tr>
            </tbody>
          </Table>
        </div>

        {/* Chart Section */}
        <div
          style={{
            overflowX: "auto", // Enables horizontal scroll
            display: "flex",
            justifyContent: "center",
            width: "100%", // Takes full width
          }}
        >
          <div
            style={{
              minWidth: "600px", // Minimum width to enable scroll
              width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
              height: "400px",
            }}
          >
            <Bar
              data={chartData}
              options={{ responsive: true, maintainAspectRatio: false }}
            />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center mt-4">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
          <span>
            <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AeroTable10;
