import React, { useRef, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import "../styles/Aerospace.css";
import ModalFunction from "./Modal";
 import "../styles/Verticals.css"

import "../styles/AboutUs.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Link } from "react-router-dom";
import "../styles/Verticals.css";
const aerospace = "/img/defence.webp";
const agriculture = "/img/agriculture-food-natural-resources.jpg";
const business = "/img/business-finance-marketing.webp";
const engineering = "/img/engineering-technology.jpg";
const human = "/img/human-services.jpeg";
const transport = "/img/transportation-distribution-logistics.jpg";
const architecture = "/img/Architectural-Engineering-construction.jpg";
const computer = "/img/computer-science-it.webp";
const health = "/img/Heath-Science.jpg";
const law = "/img/law-public-safety-security.jpg";
const arts = "/img/arts-visual-communication.jpeg";
const education = "/img/education-training.webp";
const hospitality = "/img/hospitality-tourism.jpg";
const logo = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";

 const manufacturing =  "/img/manufacturing1.jpg";


 export default function Verticals() {
  const [modalShow, setModalShow] = React.useState(false);

  const iconData = [
    {
      icon: "fa-fighter-jet",
      title: "Aerospace and Defense",
      backgroundImage: `url(${aerospace})`,
      link: "/verticals/aerospace-and-defense",
    },
    {
      icon: "fa-industry",
      title: "Manufacturing",
      backgroundImage: `url(${manufacturing})`,
      link: "/verticals/manufacturing",
    },
    {
      icon: "fa-seedling",
      title: "Food Industry",
      backgroundImage: `url(${agriculture})`,
      link: "/verticals/food-industry",
    },
    {
      icon: "fa-briefcase",
      title: "Business, Finance, and Marketing",
      backgroundImage: `url(${business})`,
      link: "/verticals/business-finance-marketing",
    },
    {
      icon: "fa-cogs",
      title: "Engineering and Technology",
      backgroundImage: `url(${engineering})`,
      link: "/verticals/engineering-and-technology",
    },
    {
      icon: "fa-hands-helping",
      title: "Human Services",
      backgroundImage: `url(${human})`,
      link: "/verticals/human-services",
    },
    {
      icon: "fa-truck",
      title: "Transportation, Distribution, and Logistics",
      backgroundImage: `url(${transport})`,
      link: "/verticals/transportation-distribution-logistics",
    },
    {
      icon: "fa-building",
      title: "Architecture and Construction",
      backgroundImage: `url(${architecture})`,
      link: "/verticals/architecture-and-construction",
    },
    {
      icon: "fa-laptop-code",
      title: "Computer Science and IT",
      backgroundImage: `url(${computer})`,
      link: "/verticals/computer-science-and-it",
    },
    {
      icon: "fa-heartbeat",
      title: "Health Science",
      backgroundImage: `url(${health})`,
      link: "/verticals/health-science",
    },
    {
      icon: "fa-balance-scale",
      title: "Law, Public Safety, Corrections, and Security",
      backgroundImage: `url(${law})`,
      link: "/verticals/law-public-safety-corrections-security",
    },
    {
      icon: "fa-paint-brush",
      title: "Arts, Audio/Visual Technology, and Communication",
      backgroundImage: `url(${arts})`,
      link: "/verticals/arts-audio-visual-technology-and-communication",
    },
    {
      icon: "fa-graduation-cap",
      title: "Education and Training",
      backgroundImage: `url(${education})`,
      link: "/verticals/education-and-training",
    },
    {
      icon: "fa-concierge-bell",
      title: "Hospitality and Tourism",
      backgroundImage: `url(${hospitality})`,
      link: "/verticals/hospitality-and-tourism",
    },
  ];

  const navbarRef = useRef(null);

  // Close the navbar on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse =
          navbarRef.current.querySelector(".navbar-collapse");
        if (navbarCollapse) {
          navbarCollapse.classList.remove("show"); // Close the navbar
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Navbar
        ref={navbarRef} // Add ref here
        collapseOnSelect
        expand="lg"
        className="fixed top-0 left-0 w-full z-10 flex justify-center items-center"
        style={{
          height: "13vh",
          backgroundColor: "#1e59be",
          zIndex: 5,
          transition: "background-color 0.3s ease",
          position: "fixed",
        }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <div className="flex items-center">
              <img
                className="h-[8vh] md:h-[10vh]"
                src={logo}
                alt="UTahData Logo"
              />
              <h1 className="text-white ml-2 text-lg md:text-4xl">
                UTahData.org
                <span className="beta-label text-xs bg-yellow-500 text-white px-1 py-0.5 rounded-lg">
                  Beta
                </span>
              </h1>
            </div>
          </Navbar.Brand>

          {/* Toggle icon for responsive menu */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            {/* Boxed styling for mobile */}
            <Nav className="d-lg-none bg-white border rounded-lg p-4 shadow-lg flex flex-col items-center">
              <Nav.Link
                as={Link}
                to="/"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/betsy"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Ask Betsy AI
              </Nav.Link>

              {/* <Nav.Link
                as={Link}
                to="/faq"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/about"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
            </Nav>

            {/* Inline styling for larger screens */}
            <Nav className="d-none d-lg-flex ml-auto">
              <Nav.Link
                as={Link}
                to="/"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/betsy"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Ask Betsy AI
              </Nav.Link>
              <ModalFunction
                show={modalShow}
                onHide={() => setModalShow(false)}
              />

              {/* <Nav.Link
                as={Link}
                to="/faq"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main content */}
      <div className="about-us-container bg-[#d2eaff]">
        <h2 className="about-us-title">
          {/* <i className="fas fa-info-circle"></i>  */}
          Verticals
        </h2>

        <div className="icon-container">
          {iconData.map((icon, index) => (
            <Link to={icon.link} key={index} style={{ textDecoration: "none" }}>
              <div
                className="icon-box"
                key={index}
                style={{
                  backgroundImage: icon.backgroundImage,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  minWidth: "80px"
                }}
              >
                <i className={`fas ${icon.icon} `}></i>
                <h3 className="text-white">{icon.title}</h3>
                <img
                  src={icon.backgroundImage}
                  alt={`Icon representing ${icon.title}`}
                  className="hidden-img"
                />
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* <footer className="bg-[#0d2969] h-[15vh] mt-[0rem] flex items-center justify-center">
        <div className="flex items-center">
        


        <a href='/'>
          <img className="h-[8vh] md:h-[10vh] mx-2" src={logo} alt="UTahData Logo" />
          </a>
            <span className='text-blue-300'>Build by</span>
            <a href="https://bodex.io/">
              <img src="https://bodex.io/wp-content/uploads/2020/11/BODEX-LLC-Logo_00fa00371_3580.png" alt="Bodex LLC Logo" className="h-6 mx-2" />
            </a>
            <span className='text-blue-300'>using</span>
            <a href="https://bodex.io/products/kpi-dash/">
              <img src={kpi} alt="KPI Dashboard Logo" className="h-12 md:h-16 lg:h-24 mx-2" />
            </a>
            <Link to="/version" className="no-underline">
  <span className="text-blue-300">20241121.v1.1</span>
</Link>

        </div>
      </footer> */}

    
    </>
  );
}
