// // import React from 'react';
// // import 'bootstrap/dist/css/bootstrap.min.css';
// // import { Table } from 'react-bootstrap';
// // import { Bar } from 'react-chartjs-2';
// // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // const TableNo33 = () => {
// //   // Data for the table and chart
// //   const employmentTrendData = [
// //     { raceLabel: 'American Indian or Alaska Native Alone', employmentTrend: 377 },
// //     { raceLabel: 'Asian Alone', employmentTrend: 1090 },
// //     { raceLabel: 'Black or African American Alone', employmentTrend: 1480 },
// //     { raceLabel: 'Native Hawaiian or Other Pacific Islander Alone', employmentTrend: 163 },
// //     { raceLabel: 'Two or More Race Groups', employmentTrend: 945 },
// //     { raceLabel: 'White Alone', employmentTrend: 16063 },
// //   ];

// //   // Chart data
// //   const chartData = {
// //     labels: employmentTrendData.map(item => item.raceLabel),
// //     datasets: [
// //       {
// //         label: 'Employment Trend (2023-2018)',
// //         data: employmentTrendData.map(item => item.employmentTrend),
// //         backgroundColor: 'rgba(75, 192, 192, 0.6)',
// //         borderColor: 'rgba(75, 192, 192, 1)',
// //         borderWidth: 1,
// //       },
// //     ],
// //   };

// //   // Chart options
// //   const options = {
// //     responsive: true,
// //     maintainAspectRatio: false,
// //     scales: {
// //       y: {
// //         beginAtZero: true,
// //         ticks: {
// //           callback: (value) => `${value}`, // Display numbers on the Y-axis
// //         },
// //       },
// //     },
// //     plugins: {
// //       tooltip: {
// //         callbacks: {
// //           label: (context) => `${context.dataset.label}: ${context.raw}`, // Show value in tooltip
// //         },
// //       },
// //     },
// //   };

// //   return (
// //     <div className="container">
// //       <h2>Employment Trend by Race (2023-2018)</h2>

// //       {/* Responsive Table */}
// //       <Table striped bordered hover responsive>
// //         <thead>
// //           <tr>
// //             <th>Race Label</th>
// //             <th>Employment Trend (2023-2018)</th>
// //           </tr>
// //         </thead>
// //         <tbody>
// //           {employmentTrendData.map((item, index) => (
// //             <tr key={index}>
// //               <td>{item.raceLabel}</td>
// //               <td>{item.employmentTrend}</td>
// //             </tr>
// //           ))}
// //         </tbody>
// //       </Table>

// //       {/* Bar Chart */}
// //       <div style={{ height: '400px', width: '100%' }}>
// //         <Bar data={chartData} options={options} />
// //       </div>
// //     </div>
// //   );
// // };

// // export default TableNo33;




// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const TableNo33 = () => {
//   // Data for the table and chart
//   const employmentTrendData = [
//     { raceLabel: 'American Indian or Alaska Native Alone', employmentTrend: 377 },
//     { raceLabel: 'Asian Alone', employmentTrend: 1090 },
//     { raceLabel: 'Black or African American Alone', employmentTrend: 1480 },
//     { raceLabel: 'Native Hawaiian or Other Pacific Islander Alone', employmentTrend: 163 },
//     { raceLabel: 'Two or More Race Groups', employmentTrend: 945 },
//     { raceLabel: 'White Alone', employmentTrend: 16063 },
//   ];

//   // Chart data
//   const chartData = {
//     labels: employmentTrendData.map(item => item.raceLabel),
//     datasets: [
//       {
//         label: 'Employment Trend (2023-2018)',
//         data: employmentTrendData.map(item => item.employmentTrend),
//         backgroundColor: 'rgba(75, 192, 192, 0.6)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Chart options
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       y: {
//         beginAtZero: true,
//         ticks: {
//           callback: (value) => `${value}`, // Display numbers on the Y-axis
//           color: '#4B5563',
//           font: {
//             size: 12,
//           },
//         },
//         title: {
//           display: true,
//           text: 'Employment Count',
//           color: '#4B5563',
//           font: {
//             size: 14,
//           },
//         },
//       },
//       x: {
//         ticks: {
//           color: '#4B5563',
//           font: {
//             size: 12,
//           },
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         position: 'top',
//         labels: {
//           font: {
//             size: 12,
//           },
//         },
//       },
//       tooltip: {
//         callbacks: {
//           label: (context) => `${context.dataset.label}: ${context.raw}`, // Show value in tooltip
//         },
//         bodyFont: {
//           size: 12,
//         },
//       },
//     },
//   };

//   return (
//     <div className="container my-5">
//       <h2 className="text-center mb-4">Employment Trend by Race (2023-2018)</h2>

//       {/* Responsive Table */}
//       <Table striped bordered hover responsive className="mb-4">
//         <thead>
//           <tr>
//             <th>Race Label</th>
//             <th>Employment Trend (2023-2018)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {employmentTrendData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.raceLabel}</td>
//               <td>{item.employmentTrend}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Bar Chart with Horizontal Scroll */}
//       <div className="overflow-auto">
//         <div style={{ minWidth: '600px', height: '400px' }}>
//           <Bar data={chartData} options={options} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TableNo33;


















import React, { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";
const pdfIcon = "/img/pdf1.png";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableNo33 = () => {
  const pdfRef = useRef(); // Ref for capturing PDF content

  // Data for the table and chart
  const employmentTrendData = [
    { raceLabel: 'American Indian or Alaska Native Alone', employmentTrend: 377 },
    { raceLabel: 'Asian Alone', employmentTrend: 1090 },
    { raceLabel: 'Black or African American Alone', employmentTrend: 1480 },
    { raceLabel: 'Native Hawaiian or Other Pacific Islander Alone', employmentTrend: 163 },
    { raceLabel: 'Two or More Race Groups', employmentTrend: 945 },
    { raceLabel: 'White Alone', employmentTrend: 16063 },
  ];

  // Chart data
  const chartData = {
    labels: employmentTrendData.map(item => item.raceLabel),
    datasets: [
      {
        label: 'Employment Trend (2023-2018)',
        data: employmentTrendData.map(item => item.employmentTrend),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: { callback: (value) => `${value}`, color: '#4B5563' },
        title: { display: true, text: 'Employment Count', color: '#4B5563' },
      },
      x: { ticks: { color: '#4B5563' } },
    },
    plugins: {
      legend: { position: 'top' },
    },
  };

  // PDF Generation
  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1250px";

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Header
      pdf.addImage(utahData, 'PNG', 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text('UTahData.org', 95, 22);
      pdf.setFontSize(12);
      pdf.line(10, 35, 200, 35);

      // Add content
      pdf.addImage(imgData, "PNG", 5, 38, imgWidth - 30, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260);
      pdf.addImage(utahData, 'PNG', 33, 265, 20, 20);
      pdf.text('Built by', 55, 275);
      pdf.addImage(bodex, 'PNG', 68, 269, 44, 10);
      pdf.text('using', 112, 275);
      pdf.addImage(kpi, 'PNG', 125, 265, 20, 20);
      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

      pdf.save("employment-trend-by-race.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Employment Trend by Race (2023-2018)</h2>

      {/* PDF Content */}
      <div ref={pdfRef}>
        {/* Responsive Table */}
        <Table striped bordered hover responsive className="mb-4">
          <thead>
            <tr>
              <th>Race Label</th>
              <th>Employment Trend (2023-2018)</th>
            </tr>
          </thead>
          <tbody>
            {employmentTrendData.map((item, index) => (
              <tr key={index}>
                <td>{item.raceLabel}</td>
                <td>{item.employmentTrend}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Bar Chart with Horizontal Scroll */}
        <div className="overflow-auto">
          <div style={{ minWidth: '600px', height: '400px' }}>
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>

      {/* Download Button */}
      <div className="flex justify-center mt-4">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
          <img className="w-[2rem]" src={pdfIcon} alt="PDF Icon" />
        </button>
      </div>
    </div>
  );
};

export default TableNo33;
