




// import React, { useEffect, useRef } from 'react';
// import { Chart } from 'chart.js';

// const ShareChart4 = () => {
//     const canvasRef = useRef(null);

//     useEffect(() => {
//         const ctx = canvasRef.current.getContext('2d');
//         const educationComparisonChart = new Chart(ctx, {
//             type: 'bar',
//             data: {
//                 labels: ['High School', 'Associate Degree', "Bachelor's Degree", 'Graduate Degree'],
//                 datasets: [
//                     {
//                         label: 'Women',
//                         data: [40, 25, 25, 10],
//                         backgroundColor: 'rgba(255, 99, 132, 0.6)',
//                         borderColor: 'rgba(255, 99, 132, 1)',
//                         borderWidth: 1
//                     },
//                     {
//                         label: 'Men',
//                         data: [50, 20, 20, 10],
//                         backgroundColor: 'rgba(54, 162, 235, 0.6)',
//                         borderColor: 'rgba(54, 162, 235, 1)',
//                         borderWidth: 1
//                     }
//                 ]
//             },
//             options: {
//                 responsive: true,
//                 maintainAspectRatio: false,  // Allows chart to scale properly
//                 scales: {
//                     y: {
//                         beginAtZero: true,
//                         title: {
//                             display: true,
//                             text: 'Percentage (%)'
//                         }
//                     }
//                 }
//             }
//         });

//         return () => {
//             // Clean up chart instance
//             educationComparisonChart.destroy();
//         };
//     }, []);

//     return (
//         <div
//           style={{
//             overflowX: "auto", // Enables horizontal scroll
//             display: "flex",
//             justifyContent: "center",
//             width: "100%", // Takes full width
//           }}
//         >
//           <div
//             style={{
//               minWidth: "600px", // Minimum width to enable scroll
//               width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
//               height: "400px",
//             }}
//           >
//                 <canvas ref={canvasRef} id="educationComparisonChart" className="w-full h-full" />
//             </div>
//         </div>
//     );
// };

// export default ShareChart4;














import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";

const ShareChart4 = () => {
  const canvasRef = useRef(null);
  const pdfRef = useRef(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    const educationComparisonChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['High School', 'Associate Degree', "Bachelor's Degree", 'Graduate Degree'],
        datasets: [
          {
            label: 'Women',
            data: [40, 25, 25, 10],
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
          {
            label: 'Men',
            data: [50, 20, 20, 10],
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Percentage (%)',
            },
          },
        },
      },
    });

    return () => {
      educationComparisonChart.destroy();
    };
  }, []);

  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px"; // Temporarily increase width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header and footer
      pdf.addImage(utahData, 'PNG', 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text('UTahData.org', 95, 22);
      pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 200, 35);

      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

      pdf.line(10, 260, 200, 260);
      pdf.addImage(utahData, 'PNG', 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text('Built by', 55, 275);
      pdf.addImage(bodex, 'PNG', 68, 269, 44, 10);
      pdf.text('using', 112, 275);
      pdf.addImage(kpi, 'PNG', 125, 265, 20, 20);
      pdf.text('20241121.v1.1', 148, 275);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

      pdf.save("education-comparison.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="container">
      <h2>Education Comparison by Gender</h2>
      <div ref={pdfRef}>
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              minWidth: "600px",
              width: "1000px",
              height: "400px",
            }}
          >
            <canvas ref={canvasRef} id="educationComparisonChart" className="w-full h-full" />
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
        </button>
      </div>
    </div>
  );
};

export default ShareChart4;
