// import React from 'react';
// import Table from 'react-bootstrap/Table';
// import { Bar } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// // Register the necessary components for Chart.js
// ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

// const AeroTable9 = ({ data }) => {
//   // Prepare the data for the table and chart
//   const ageGroups = data.map((item) => item["Age Group Label"]);
//   const averageMaleWorkers = data.map((item) => parseFloat(item["Average Count of Male Workers"]));

//   // Chart.js data configuration for Bar Chart
//   const chartData = {
//     labels: ageGroups,
//     datasets: [
//       {
//         label: 'Average Male Workers',
//         data: averageMaleWorkers,
//         backgroundColor: '#4BC0C0', // Light green color for the bars
//         borderColor: '#36A2EB',
//         borderWidth: 1,
//       },
//     ],
//   };

//   const chartOptions = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//     },
//     scales: {
//       y: {
//         beginAtZero: true,
//         ticks: {
//           callback: (value) => value, // Display as count, no percentage
//         },
//       },
//     },
//   };

//   return (
//     <div className="container">
//       {/* Table Section */}
//       <div className="table-responsive">
//         <Table striped bordered hover responsive>
//           <thead>
//             <tr>
//               <th>Age Group</th>
//               <th>Average Male Workers</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data.length > 0 ? (
//               data.map((item, index) => (
//                 <tr key={index}>
//                   <td>{item["Age Group Label"]}</td>
//                   <td>{parseFloat(item["Average Count of Male Workers"]).toFixed(2)}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="2" className="text-center">
//                   No data available
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </Table>
//       </div>

//       {/* Chart Section */}
//       {/* <div className="chart-container" style={{ overflowX: 'auto', width: '100%', maxWidth: '100%' }}>
//         <div style={{ width: '100%', height: '400px', flex: 'none' }}> */}
//         <div style={{ overflowX: 'auto', marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
//         <div style={{ width: '800px', height: '500px' }}>
//           <Bar data={chartData} options={chartOptions} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AeroTable9;

import React, { useRef } from "react";
import Table from "react-bootstrap/Table";
import { Bar } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// Register the necessary components for Chart.js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

const AeroTable9 = ({ data }) => {
  const pdfRef = useRef();

  // Prepare the data for the table and chart
  const ageGroups = data.map((item) => item["Age Group Label"]);
  const averageMaleWorkers = data.map((item) =>
    parseFloat(item["Average Count of Male Workers"])
  );

  // Chart.js data configuration for Bar Chart
  const chartData = {
    labels: ageGroups,
    datasets: [
      {
        label: "Average Male Workers",
        data: averageMaleWorkers,
        backgroundColor: "#4BC0C0",
        borderColor: "#36A2EB",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
    },
    scales: {
      y: { beginAtZero: true },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    input.style.width = "1100px"; // Expand width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });
      pdf.setFontSize(12);
      pdf.line(10, 35, 200, 35);

      // Main Content
      pdf.addImage(imgData, "PNG", 5, 38, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260);
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.text("Built by", 55, footerStartY + 10);
      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
      pdf.text("20241121.v1.1", 148, footerStartY + 10);
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("average-male-workers.pdf");
      input.style.width = ""; // Reset width
    });
  };

  return (
    <div className="container">
      <div ref={pdfRef} className="content-to-export">
        {/* Table Section */}
        <div className="table-responsive">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Age Group</th>
                <th>Average Male Workers</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item["Age Group Label"]}</td>
                    <td>
                      {parseFloat(
                        item["Average Count of Male Workers"]
                      ).toFixed(2)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {/* Chart Section */}
        {/* <div style={{ overflowX: 'auto', marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '800px', height: '500px' }}>
            <Bar data={chartData} options={chartOptions} />
          </div>
        </div> */}

        <div
          style={{
            overflowX: "auto", // Enables horizontal scroll
            display: "flex",
            justifyContent: "center",
            width: "100%", // Takes full width
          }}
        >
          <div
            style={{
              minWidth: "600px", // Minimum width to enable scroll
              width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
              height: "400px",
            }}
          >
            <Bar
              data={chartData}
              options={{ responsive: true, maintainAspectRatio: false }}
            />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center mt-4">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
          <span>
            <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AeroTable9;
