// import React from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// // Register chart.js components
// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// export default function TableNo9() {
//     // Data for the table and chart
//     const data = [
//         { category: 'Female', share2018: 29.57, share2023: 31.99 },
//         { category: 'Male', share2018: 70.43, share2023: 68.01 },
//     ];

//     // Chart data
//     const chartData = {
//         labels: data.map(item => item.category),
//         datasets: [
//             {
//                 label: 'Share % (2018)',
//                 data: data.map(item => item.share2018),
//                 backgroundColor: 'rgba(255, 99, 132, 0.5)',
//             },
//             {
//                 label: 'Share % (2023)',
//                 data: data.map(item => item.share2023),
//                 backgroundColor: 'rgba(54, 162, 235, 0.5)',
//             },
//         ],
//     };

//     // Chart options
//     const options = {
//         responsive: true,
//         maintainAspectRatio: false,
//         plugins: {
//             legend: {
//                 position: 'top',
//             },
//             title: {
//                 display: true,
//                 text: 'Share % by Gender (2018 vs 2023)',
//             },
//         },
//         scales: {
//             y: {
//                 beginAtZero: true,
//                 max: 100,
//                 ticks: {
//                     callback: value => `${value}%`,
//                 },
//             },
//         },
//     };

//     return (
//         <div className="w-full mx-auto my-6 p-4">
//             <h2 className="text-center text-2xl font-bold mb-4">Share % by Gender (2018 vs 2023)</h2>

//             {/* Responsive Table */}
//             <div className="overflow-x-auto mb-8">
//                 <table className="min-w-full border border-gray-300 text-center">
//                     <thead className="bg-gray-200">
//                         <tr>
//                             <th className="px-4 py-2">Category</th>
//                             <th className="px-4 py-2">Share % (2018)</th>
//                             <th className="px-4 py-2">Share % (2023)</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {data.map((row, index) => (
//                             <tr key={index} className="border-b">
//                                 <td className="px-4 py-2">{row.category}</td>
//                                 <td className="px-4 py-2">{row.share2018}%</td>
//                                 <td className="px-4 py-2">{row.share2023}%</td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>

//             {/* Responsive Bar Chart */}
//             <div
//           style={{
//             overflowX: "auto", // Enables horizontal scroll
//             display: "flex",
//             justifyContent: "center",
//             width: "100%", // Takes full width
//           }}
//         >
//           <div
//             style={{
//               minWidth: "600px", // Minimum width to enable scroll
//               width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
//               height: "400px",
//             }}
//           >
//                 <Bar data={chartData} options={options} />
//             </div>
//         </div>
//         </div>
//     );
// }














import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// Example logo and icons
const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";
const pdfIcon = "/img/pdf1.png";

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function TableNo9() {
  const pdfRef = useRef();

  // Data for the table and chart
  const data = [
    { category: "Female", share2018: 29.57, share2023: 31.99 },
    { category: "Male", share2018: 70.43, share2023: 68.01 },
  ];

  // Chart data
  const chartData = {
    labels: data.map((item) => item.category),
    datasets: [
      {
        label: "Share % (2018)",
        data: data.map((item) => item.share2018),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Share % (2023)",
        data: data.map((item) => item.share2023),
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Share % by Gender (2018 vs 2023)",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px";

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      // Add content
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      // Add footer
      pdf.setLineWidth(0.5);
      pdf.line(10, 260, 200, 260);
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
      pdf.text("20241121.v1.1", 148, 275);
      pdf.line(10, 35, 200, 35);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

      pdf.save("gender_share_trend.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="w-full mx-auto my-6 p-4">
      <div ref={pdfRef}>
        <h2 className="text-center text-2xl font-bold mb-4">
          Share % by Gender (2018 vs 2023)
        </h2>

        {/* Responsive Table */}
        <div className="overflow-x-auto mb-8">
          <table className="min-w-full border border-gray-300 text-center">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2">Category</th>
                <th className="px-4 py-2">Share % (2018)</th>
                <th className="px-4 py-2">Share % (2023)</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2">{row.category}</td>
                  <td className="px-4 py-2">{row.share2018}%</td>
                  <td className="px-4 py-2">{row.share2023}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Responsive Bar Chart */}
        <div
          style={{
            overflowX: "auto", // Enables horizontal scroll
            display: "flex",
            justifyContent: "center",
            width: "100%", // Takes full width
          }}
        >
          <div
            style={{
              minWidth: "600px", // Minimum width to enable scroll
              width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
              height: "400px",
            }}
          >
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>

      {/* Download Button */}
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <img className="w-[2rem]" src={pdfIcon} alt="PDF Icon" />
        </button>
      </div>
    </div>
  );
}
