import React, { useRef } from "react";
import Table from "react-bootstrap/Table";
import { Bar } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

const AeroTable8 = ({ data }) => {
  const pdfRef = useRef();

  if (!data || data.length === 0) {
    return <p>No data available for Employment Trends.</p>;
  }

  const years = ["2018", "2019", "2020", "2021", "2022", "2023"];

  const chartData = {
    labels: data.map((item) => item["Age Group Label"]),
    datasets: years.flatMap((year) => [
      {
        label: `Female-${year}`,
        data: data.map((item) => parseFloat(item[`Female-${year}`])),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        stack: year,
      },
      {
        label: `Male-${year}`,
        data: data.map((item) => parseFloat(item[`Male-${year}`])),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        stack: year,
      },
    ]),
  };

  // Extract the "Average" row data
  const averageData = data.find(
    (item) => item["Age Group Label"].toLowerCase() === "average"
  );

  // Prepare average chart data
  const averageChartData = {
    labels: years,
    datasets: [
      {
        label: "Average Female",
        data: years.map((year) => parseFloat(averageData[`Female-${year}`])),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "Average Male",
        data: years.map((year) => parseFloat(averageData[`Male-${year}`])),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    input.style.width = "1100px"; // Expand width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });
      pdf.setFontSize(12);
      pdf.line(10, 35, 200, 35);

      // Main Content
      pdf.addImage(imgData, "PNG", 5, 38, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260);
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.text("Built by", 55, footerStartY + 10);
      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
      pdf.text("20241121.v1.1", 148, footerStartY + 10);
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("employment-by-age-and-gender.pdf");
      input.style.width = ""; // Reset width
    });
  };

  return (
    <div className="w-full p-4">
      <div ref={pdfRef} className="content-to-export">
        {/* Table Section */}
        <div className="overflow-x-auto">
          <Table striped bordered hover responsive className="mb-4">
            <thead>
              <tr>
                <th>Age Group</th>
                {years.flatMap((year) => [
                  <th key={`female-${year}`}>Female-{year}</th>,
                  <th key={`male-${year}`}>Male-{year}</th>,
                ])}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>{row["Age Group Label"]}</td>
                  {years.flatMap((year) => [
                    <td key={`female-${year}-${index}`}>
                      {row[`Female-${year}`]}
                    </td>,
                    <td key={`male-${year}-${index}`}>
                      {row[`Male-${year}`]}
                    </td>,
                  ])}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div
          style={{
            overflowX: "auto", // Enables horizontal scroll
            display: "flex",
            justifyContent: "center",
            width: "100%", // Takes full width
          }}
        >
          <div
            style={{
              minWidth: "600px", // Minimum width to enable scroll
              width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
              height: "400px",
            }}
          >
            <Bar
              data={averageChartData}
              options={{ responsive: true, maintainAspectRatio: false }}
            />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center mt-4">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
          <span>
            <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AeroTable8;
